import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    loader: {
        color: COLORS.FADED_PURPLE,
        padding: 12,
    },

    icon: {
        width: 24,
        height: 24,
    },
});

const CircularLoader = ({ className, ...otherProps }) => {
    const classes = styles();
    return (
        <div className={`${classes.loader} ${className}`} {...otherProps}>
            <CircularProgress color="inherit" style={{ width: 24, height: 24 }} />
        </div>
    );
};

export default CircularLoader;
