import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// core components
import GridContainer from "../components-lagacy/Grid/GridContainer.jsx";
import GridItem from "../components-lagacy/Grid/GridItem.jsx";
import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "../components-lagacy/Card/Card.jsx";
import CardBody from "../components-lagacy/Card/CardBody.jsx";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "../components-lagacy/CustomButtons/Button.jsx";
//import { ReCaptcha } from "react-recaptcha-google";
import Paper from "@material-ui/core/Paper";
import CustomFileInput from "../components-lagacy/CustomInput/CustomFileInput";

import MenuItem from "@material-ui/core/MenuItem";
import { Redirect, withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { uploadFile } from "../helpers/files-utils";

import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    InputAdornment,
} from "@material-ui/core";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";

import { COLORS } from "../constants/constants-lagacy";
import { firestore as db, authWorker as auth } from "../services/firebase";
import PATHS from "../constants/paths";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { getNewMerchNumber } from "../helpers/firestore-utils";
import { COUNTRY_LIST } from "../constants/countries";

import { PLATFORM } from "../constants/global.js";

class AddMerchandiser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            En_name_first: "",
            En_name_middle: "",
            En_name_last: "",
            Ar_name_first: "",
            Ar_name_middle: "",
            Ar_name_last: "",
            first_name: "",
            city: "",
            email: "",
            password: "",
            showPassword: false,
            phone: "",
            birth_date: new Date(),
            gender: "male",
            status: 0,
            oldStatus: 0,
            health_card_expiration_date: new Date(),
            health_card_no: "",
            bank_number: "",
            bank_name: "",
            active: true,
            class_level: 1,
            nationality: COUNTRY_LIST[0].value,

            there_is_disability: false,
            disability: "",

            transportation: false,

            experience: false,
            experience_company: "",
            experience_years: "",
            national_number: "",
            nationality_issued_date: new Date(),

            cities_list: [],
            cities: [],
            tokenFound: "",
            token: "",

            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,

            supplier_branches_list: [],
            selectedSupplierBranchID: "",

            jobs: [
                {
                    value: "mp_employee",
                    label: "Merchandiser",
                },
                // {
                //     value: "warehouse_manager",
                //     label: "Warehouse Manager",
                // },
                // {
                //     value: "warehouse_keeper",
                //     label: "Warehouse Keeper",
                // },
                // {
                //     value: "warehouse_accountant",
                //     label: "Warehouse Accountant",
                // },
            ],
            selectedJob: "mp_employee",
            selectedBranchType: "regular",

            account_state: 9,
            states: [
                { value: 9, label: "Active" },
                { value: 11, label: "Inactive" },
            ],

            healthFile: null,
            healthURL: null,
            healthFileName: "Not yet uploaded.",
        };
    }

    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = (exitTo = null) => {
        if (exitTo) {
            this.setState({ dialogMsg: "", dialogOpen: false, ...exitTo });
        } else {
            this.setState({ dialogMsg: "", dialogOpen: false });
        }
    };

    init = async () => {
        this.setState({ loading: true });

        try {
            const cities = (await db.collection("City").get()).docs.map((doc) => doc.data());
            const cities_list = cities.map((city) => ({ value: city.city_id, label: city.En_name }));
            const supplier_branches = (await db.collection("MP_Branch").where("mp_id", "==", this.props.id).get()).docs;
            const supplier_branches_list = supplier_branches.map((branch) => ({
                value: branch.id,
                label: branch.data().En_name,
                type: branch.data().type,
            }));

            if (cities_list.length <= 0) {
                this.showDialogMsg("Error", "Cities not found", () => {
                    this.hideDialogMsg();
                    this.props.history.push({
                        pathname: PATHS.BRANCHES,
                        state: {
                            id: this.props.id,
                            uid: this.props.uid,
                        },
                    });
                });
                return;
            }

            if (supplier_branches_list.length <= 0) {
                this.showDialogMsg("Error", "You need to add a branch first", () => {
                    this.hideDialogMsg();
                    this.props.history.push({
                        pathname: PATHS.BRANCHES,
                        state: {
                            id: this.props.id,
                            uid: this.props.uid,
                        },
                    });
                });
                return;
            }

            const selectedSupplierBranchID = supplier_branches_list[0].value;

            this.setState({
                cities,
                cities_list,
                city: cities_list[2].value,

                supplier_branches_list: supplier_branches_list,
                selectedSupplierBranchID: supplier_branches_list[0].value,
                selectedBranchType: supplier_branches_list[0].type,
            });
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false });
    };

    initForTesting = () => {
        this.setState({
            En_name_first: "Merchandiser",
            En_name_middle: "",
            En_name_last: "",
            Ar_name_first: "ميرشاندايزر",
            Ar_name_middle: "",
            Ar_name_last: "",
            first_name: "",
            email: "test@test.com",
            password: "asdQWE123",
            showPassword: true,
            phone: "02584561379",
            birth_date: new Date("1992/07/16"),
            gender: "male",
            health_card_expiration_date: new Date("2030/12/31"),
            health_card_no: "0011223344",
            bank_number: null,
            bank_name: null,
            active: true,
            class_level: 1,
            nationality: COUNTRY_LIST[0].value,

            there_is_disability: false,
            disability: "",

            transportation: false,

            experience: false,
            experience_company: null,
            experience_years: null,
            national_number: null,
            nationality_issued_date: new Date(),

            selectedSupplierBranchID: "",
        });
    };

    componentDidMount() {
        this.init();
        // if (this.props.id === "109") this.initForTesting();
    }

    //==================================================================================

    validateForm() {
        let validate =
            this.state.Ar_name_first &&
            this.state.Ar_name_last &&
            this.state.En_name_first &&
            this.state.En_name_last &&
            this.state.phone.length >= 6 &&
            new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/).test(this.state.email) &&
            new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,32}/).test(this.state.password) &&
            this.state.nationality &&
            this.state.birth_date &&
            this.state.gender &&
            this.state.selectedSupplierBranchID &&
            this.state.account_state &&
            this.state.city;

        if (this.state.selectedJob === "mp_employee") {
            validate = validate && this.state.health_card_no && this.state.health_card_expiration_date;
            // this.state.bank_name &&
            // this.state.bank_number &&
            // this.state.national_number &&
            // this.state.nationality_issued_date;

            // let disability_validate = this.state.there_is_disability ? this.state.disability : true;
            // let experience_validate = this.state.experience ? this.state.experience_company && this.state.experience_years >= 0 : true;
        }

        return validate;
    }

    healthSelected = (event) => {
        if (event.target.files.length <= 0) return false;

        const healthFile = event.target.files[0];
        if (healthFile.type !== "image/png" && healthFile.type !== "image/jpeg") {
            // this.showDialogMsg("Invalid File Format", "Please upload the authorization letter in PDF format only.", this.hideDialogMsg);
            return false;
        }
        this.setState({
            healthFile: healthFile,
            healthFileName: healthFile.name,
        });
    };

    handleChange = (event, id) => {
        if (id === "Ar_name_first" || id === "Ar_name_middle" || id === "Ar_name_last") {
            this.setState({ [id]: event.target.value.replace(/[^\u0600-\u06FF-9._!@#$%^&*)(-=+/ ]/, "") });
        } else if (id === "En_name_first" || id === "En_name_middle" || id === "En_name_last") {
            this.setState({ [id]: event.target.value.replace(/[^a-z-A-Z-9._!@#$%^&*)(-=+/ ]/, "") });
        } else if (
            id === "national_number" ||
            id === "phone" ||
            id === "health_card_no" ||
            id === "bank_number" ||
            id === "experience_years"
        ) {
            this.setState({ [id]: event.target.value.replace(/[^0-9\b]+$/, "") });
        } else if (id === "selectedSupplierBranchID") {
            const selectedBranchType = this.state.supplier_branches_list.find(
                (branch) => branch.value === event.target.value
            ).type;
            const selectedJob =
                selectedBranchType === "supermarket" ? this.state.selectedJob : this.state.jobs[0].value;
            this.setState({ [id]: event.target.value, selectedBranchType, selectedJob });
        } else {
            this.setState({ [id]: event.target.value });
        }
    };

    writeMerchandiserData = async (e) => {
        if (!this.validateForm()) return false;
        this.setState({ loading: true });
        try {
            //get last merch number
            let merchNumber = await getNewMerchNumber();

            let { selectedJob, health_card_expiration_date, health_card_no, healthFile, healthFileName, healthURL } =
                this.state;

            if (selectedJob !== "mp_employee") {
                health_card_expiration_date = null;
                health_card_no = null;
                healthFile = null;
                healthFileName = null;
                healthURL = null;
            }

            let merchandiser = {
                platform: PLATFORM,
                account_information: {
                    type: selectedJob,
                    owner_id: this.props.id,
                    branch_id: this.state.selectedSupplierBranchID,
                    mp_branch_id: this.state.selectedSupplierBranchID,
                    supplier_branch_id: "",
                    active: true,
                    class_level: 1,
                    completed_profile: true,
                    email: this.state.email,
                    health_card: {
                        health_card_expiration_date: health_card_expiration_date,
                        health_card_number: `${health_card_no}`,
                        health_card_photo: null,
                    },
                    my_account: "0.0",
                    payment_method: {},
                    points: 0,
                    state: this.state.account_state,
                },
                address: {
                    City: this.state.city,
                    Country: 100,
                },
                attachments: {
                    profile_picture: null,
                },
                bank_information: {
                    iban_bank: this.state.bank_name,
                    iban_number: this.state.bank_number,
                    iban_photo: "",
                },
                completed_profile: true,
                coverage: [],
                experience: {
                    experience: this.state.experience,
                    experience_company: this.state.experience ? this.state.experience_company : null,
                    experience_years: this.state.experience ? Number(this.state.experience_years) : 0,
                },
                general_information: {
                    disability: this.state.there_is_disability ? this.state.disability : null,
                    health_card: true,
                    there_is_disability: this.state.there_is_disability,
                    transportation: this.state.transportation,
                },
                merchandiser_number: merchNumber,
                nationality: {
                    national_number: this.state.national_number,
                    nationality: this.state.nationality,
                    nationality_issued_date: this.state.nationality_issued_date,
                },
                personal_information: {
                    birth_date: this.state.birth_date,
                    gender: this.state.gender,
                    name: {
                        arabic: {
                            first: this.state.Ar_name_first.trim() || "",
                            last: this.state.Ar_name_last.trim() || "",
                            middle: this.state.Ar_name_middle.trim() || "",
                        },
                        english: {
                            first: this.state.En_name_first.trim() || "",
                            last: this.state.En_name_last.trim() || "",
                            middle: this.state.En_name_middle.trim() || "",
                        },
                    },
                    phone_number: Number(this.state.phone),
                },
                register_date: new Date(),
                uid: "",
            };

            await auth.createUserWithEmailAndPassword(this.state.email, this.state.password);
            const uid = auth.currentUser.uid;

            //upload health card file or delete if it is empty
            merchandiser.account_information.health_card.health_card_photo = healthURL;
            if (healthFile && selectedJob === "mp_employee") {
                let fileRef = await uploadFile(`Health Card's/${uid}`, healthFileName, healthFile);
                merchandiser.account_information.health_card.health_card_photo = await fileRef.ref.getDownloadURL();
            }

            const merchDoc = await db.collection("Merchandiser").doc(uid).get();
            if (merchDoc.exists) return false;

            merchandiser.uid = uid;
            await db.collection("Merchandiser").doc(uid).set(merchandiser);

            this.showDialogMsg(
                "Employee Added",
                "A new employee account has been created successfully.",
                this.hideDialogMsg
            );
        } catch (error) {
            console.log(error);
            if (error.code === "auth/email-already-in-use") {
                this.showDialogMsg("Cannot Add Employee Account", error.message, this.hideDialogMsg);
            }
        }
        this.setState({ loading: false });
    };

    goToMerchandiserListPage = (number) => {
        //1 for submit and 2 for cancel
        if (number === 1) {
            this.setState({ pathBack: PATHS.MERCHANDISERS });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Discard All Changes!",
                text: "Are you sure you want to discard the changes?",
            }).then((result) => {
                if (!result.value) {
                    this.setState({ pathBack: PATHS.MERCHANDISERS });
                }
            });
        }
    };

    //==================================================================================
    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathBack,
                        state: { id: this.props.id },
                    }}
                />
            );
        } else {
            const gender_list = [
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
            ];
            // const yes_no_list = [
            //     { value: true, label: "Yes" },
            //     { value: false, label: "No" },
            // ];
            // const merch_class_level_list = [
            //     {
            //         value: 1,
            //         label: "Beginner",
            //     },
            //     {
            //         value: 2,
            //         label: "Intermediate",
            //     },
            //     {
            //         value: 3,
            //         label: "Advanced",
            //     },
            //     {
            //         value: 4,
            //         label: "Supervision",
            //     },
            //     {
            //         value: 5,
            //         label: "In-house",
            //     },
            //     {
            //         value: 6,
            //         label: "Administration",
            //     },
            // ];
            // const status_list = [
            //     {
            //         value: 10,
            //         label: "Registered",
            //     },
            //     {
            //         value: 1,
            //         label: "Valid",
            //     },
            //     {
            //         value: 0,
            //         label: "Not Valid",
            //     },
            //     {
            //         value: 5,
            //         label: "Completed",
            //     },
            //     {
            //         value: 4,
            //         label: "Incompleted",
            //     },
            //     {
            //         value: 8,
            //         label: "Not Pass",
            //     },
            //     {
            //         value: 9,
            //         label: "Pass",
            //     },
            // ];

            // const active_list = [
            //     {
            //         value: true,
            //         label: "Yes",
            //     },
            //     {
            //         value: false,
            //         label: "No",
            //     },
            // ];

            return (
                <div className={classes.pageHeader}>
                    <div>
                        <div
                            style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}
                            className={classes.container}
                        >
                            <div pl={5} style={{ width: "100%", position: "relative" }}>
                                <div
                                    className={`${this.props.classes.backdrop} ${this.props.classes.absolutePosition} ${
                                        this.state.loading ? null : this.props.classes.loadingHidden
                                    }`}
                                ></div>
                                <div
                                    className={`${this.props.classes.loaderContainer} ${
                                        this.props.classes.absolutePosition
                                    } ${this.state.loading ? null : this.props.classes.loadingHidden}`}
                                >
                                    <CircularProgress />
                                </div>
                                <Grid>
                                    <Card color="primary">
                                        <CardBody>
                                            <GridContainer>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className={classes.title} style={{ width: "3%" }}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                // backgroundColor: "#91041C",
                                                                color: "#EEEEEE",
                                                                height: "30px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "2px solid #575757",
                                                                // borderRightColor: "#91041C",
                                                                // borderBottomColor: "#91041C",
                                                                backgroundColor: COLORS.primary,
                                                                borderRightColor: COLORS.primary,
                                                                borderBottomColor: COLORS.primary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={classes.title}
                                                        style={{ width: "90%", marginLeft: "2px" }}
                                                    >
                                                        <h4
                                                            style={{
                                                                textAlign: "left",
                                                                fontWeight: "700",
                                                                backgroundColor: COLORS.background,
                                                                color: COLORS.primary,
                                                                // color: "#91041C",
                                                                height: "30px",
                                                                paddingLeft: "20px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "1px solid #000000",
                                                            }}
                                                        >
                                                            Add Employee
                                                        </h4>
                                                    </div>
                                                </div>

                                                <div className={classes.title} style={{ width: "100rem" }}>
                                                    <h4
                                                        style={{
                                                            fontWeight: "500",
                                                            textAlign: "left",
                                                            paddingBottom: "3px",
                                                            marginBottom: "0px",
                                                        }}
                                                    >
                                                        General Info.
                                                    </h4>
                                                    <Divider xs={12} />
                                                </div>

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            // style={{ color: "#000000", fontWeight: "600", backgroundColor: "#ffffff" }}
                                                            value={this.state.En_name}
                                                            // variant="filled"
                                                            fullWidth
                                                            id="En_name"
                                                            label="English Name"
                                                            placeholder="English Name"
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "En_name")}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                    </GridItem> */}

                                                <GridItem xs={12} sm={12} md={3}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.En_name_first}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="En_name_first"
                                                        label="EN. first Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "En_name_first")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>
                                                {/* <GridItem xs={12} sm={12} md={2}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.En_name_middle}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="En_name_middle"
                                                        label="EN. middle Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "En_name_middle")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem> */}
                                                <GridItem xs={12} sm={12} md={3}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.En_name_last}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="En_name_last"
                                                        label="EN. Name Last"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "En_name_last")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={3}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.Ar_name_first}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="Ar_name_first"
                                                        label="AR. First Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "Ar_name_first")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>
                                                {/* <GridItem xs={12} sm={12} md={2}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.Ar_name_middle}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="Ar_name_middle"
                                                        label="AR. Middle Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "Ar_name_middle")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem> */}
                                                <GridItem xs={12} sm={12} md={3}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.Ar_name_last}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="Ar_name_last"
                                                        label="AR. Last Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "Ar_name_last")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.email}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="email"
                                                        label="Email"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "email")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        value={this.state.password.replace(
                                                            /[^A-Za-z0-9._%+-@#*&^$!=]/,
                                                            ""
                                                        )}
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        helperText={
                                                            "Must be at least 6 characters and contains lowercase letter, uppercase letter and a digit"
                                                        }
                                                        id="password"
                                                        label="Password"
                                                        type={this.state.showPassword ? "text" : "password"}
                                                        autoComplete="current-password"
                                                        margin="dense"
                                                        onChange={(event) => this.handleChange(event, "password")}
                                                        //value={this.state.password}

                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment
                                                                    position="end"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            showPassword: !this.state.showPassword,
                                                                        })
                                                                    }
                                                                >
                                                                    {this.state.showPassword ? (
                                                                        <Visibility />
                                                                    ) : (
                                                                        <VisibilityOff />
                                                                    )}
                                                                    {/* <IconButton aria-label="toggle password visibility" onClick={this.handleClickShowPassword}>
                                                                        </IconButton> */}
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.phone}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="phone"
                                                        label="Phone"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "phone")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        select
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.nationality}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="nationality"
                                                        label="Nationality"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "nationality")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {COUNTRY_LIST.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <MuiPickersUtilsProvider
                                                        style={{ selectColor: "#91041C" }}
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            multiline
                                                            inputVariant="filled"
                                                            format="dd/MM/yyyy"
                                                            margin="dense"
                                                            id="birth_date"
                                                            label="Birth Date"
                                                            // selectColor="#91041C"
                                                            // InputProps={{  }}
                                                            style={{
                                                                selectColor: "#91041C",
                                                                color: "#000000",
                                                                fontWeight: "600",
                                                            }}
                                                            // disabled
                                                            // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                            value={this.state.birth_date}
                                                            onChange={(date) =>
                                                                date ? this.setState({ birth_date: date }) : null
                                                            }
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.textFieldLabel,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        select
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.gender}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="gender"
                                                        label="Gender"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "gender")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {gender_list.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        select
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.city}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="city"
                                                        label="City"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "city")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.cities_list.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        select
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.selectedSupplierBranchID}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="supplier_branch"
                                                        label="Branch"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.handleChange(event, "selectedSupplierBranchID")
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.supplier_branches_list.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>
                                                {this.state.selectedBranchType === "supermarket" && (
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.selectedJob}
                                                            // variant="filled"
                                                            fullWidth
                                                            id="selectedJob"
                                                            label="Job"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) =>
                                                                this.handleChange(event, "selectedJob")
                                                            }
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {this.state.jobs.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>
                                                )}
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        select
                                                        style={{ color: "#000000", fontWeight: "600" }}
                                                        value={this.state.account_state}
                                                        // variant="filled"
                                                        fullWidth
                                                        id="account_state"
                                                        label="Account Status"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "account_state")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.states.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                {this.state.selectedJob === "mp_employee" && (
                                                    <div className={classes.title} style={{ width: "100rem" }}>
                                                        <h4
                                                            style={{
                                                                fontWeight: "500",
                                                                textAlign: "left",
                                                                paddingBottom: "3px",
                                                                marginBottom: "0px",
                                                            }}
                                                        >
                                                            Additional Info.
                                                        </h4>
                                                        <Divider />
                                                    </div>
                                                )}

                                                {this.state.selectedJob === "mp_employee" && (
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.health_card_no}
                                                            // variant="filled"
                                                            fullWidth
                                                            id="health_card_no"
                                                            label="Health Card No."
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) =>
                                                                this.handleChange(event, "health_card_no")
                                                            }
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />

                                                        {this.state.health_card_image ? (
                                                            <a
                                                                href={this.state.health_card_image}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <PhotoAlbumIcon
                                                                    style={{
                                                                        position: "absolute",
                                                                        right: 15,
                                                                        bottom: 10,
                                                                        cursor: "pointer",
                                                                    }}
                                                                />
                                                            </a>
                                                        ) : null}
                                                    </GridItem>
                                                )}

                                                {this.state.selectedJob === "mp_employee" && (
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <MuiPickersUtilsProvider
                                                            style={{ selectColor: "#91041C" }}
                                                            utils={DateFnsUtils}
                                                        >
                                                            <KeyboardDatePicker
                                                                fullWidth
                                                                multiline
                                                                inputVariant="filled"
                                                                format="dd/MM/yyyy"
                                                                margin="dense"
                                                                id="health_card_expiration_date"
                                                                label="Health Card Expiration Date"
                                                                // selectColor="#91041C"
                                                                style={{
                                                                    selectColor: "#91041C",
                                                                    color: "#000000",
                                                                    fontWeight: "600",
                                                                }}
                                                                value={this.state.health_card_expiration_date}
                                                                onChange={(date) =>
                                                                    date
                                                                        ? this.setState({
                                                                              health_card_expiration_date: date,
                                                                          })
                                                                        : null
                                                                }
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.textFieldRoot,
                                                                        disabled: classes.disabled,
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.textFieldLabel,
                                                                        disabled: classes.disabled,
                                                                    },
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </GridItem>
                                                )}

                                                {this.state.selectedJob === "mp_employee" && (
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <CustomFileInput
                                                            accept=".jpg, .jpeg, .png"
                                                            rootProps={{
                                                                disabled: true,
                                                                label: "Upload Health Card",
                                                                id: "health-card-file",
                                                                value: this.state.healthFileName,
                                                            }}
                                                            fileURL={this.state.healthURL}
                                                            fileObject={this.state.healthFile}
                                                            onFileSelect={this.healthSelected}
                                                            onClear={() =>
                                                                this.setState({
                                                                    healthFileName: "Not yet uploaded.",
                                                                    healthFile: null,
                                                                    healthURL: null,
                                                                })
                                                            }
                                                        />
                                                    </GridItem>
                                                )}

                                                {/* 
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.bank_name}
                                                            // variant="filled"
                                                            fullWidth
                                                            id="bank_name"
                                                            label="Bank Name"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "bank_name")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                        {this.state.bank_image ? (
                                                            <a href={this.state.bank_image} target="_blank">
                                                                <PhotoAlbumIcon
                                                                    style={{ position: "absolute", right: 15, bottom: 10, cursor: "pointer" }}
                                                                />
                                                            </a>
                                                        ) : null}
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.bank_number}
                                                            // variant="filled"
                                                            fullWidth
                                                            id="bank_number"
                                                            label="Bank Number"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "bank_number")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.national_number}
                                                            // variant="filled"
                                                            fullWidth
                                                            id="national_number"
                                                            label="ID Number"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "national_number")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <MuiPickersUtilsProvider style={{ selectColor: "#91041C" }} utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                fullWidth
                                                                multiline
                                                                inputVariant="filled"
                                                                format="dd/MM/yyyy"
                                                                margin="dense"
                                                                id="id_issued_date"
                                                                label="ID Issued Date"
                                                                selectColor="#91041C"
                                                                style={{ selectColor: "#91041C", color: "#000000", fontWeight: "600" }}
                                                                value={this.state.nationality_issued_date}
                                                                onChange={(date) => (date ? this.setState({ nationality_issued_date: date }) : null)}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.textFieldRoot,
                                                                        disabled: classes.disabled,
                                                                    },
                                                                }}
                                                                InputLabelProps={{
                                                                    classes: {
                                                                        root: classes.textFieldLabel,
                                                                        disabled: classes.disabled,
                                                                    },
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.there_is_disability}
                                                            fullWidth
                                                            id="there_is_disability"
                                                            label="Is there disability?"
                                                            placeholder=""
                                                            multiline
                                                            onChange={(event) => this.handleChange(event, "there_is_disability")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {yes_no_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.there_is_disability ? this.state.disability : ""}
                                                            disabled={!this.state.there_is_disability}
                                                            fullWidth
                                                            id="disability"
                                                            label="Disability"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "disability")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.transportation}
                                                            fullWidth
                                                            id="transportation"
                                                            label="Transportation"
                                                            placeholder=""
                                                            multiline
                                                            onChange={(event) => this.handleChange(event, "transportation")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {yes_no_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.experience}
                                                            fullWidth
                                                            id="experience"
                                                            label="Experience"
                                                            placeholder=""
                                                            multiline
                                                            onChange={(event) => this.handleChange(event, "experience")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {yes_no_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.experience ? this.state.experience_company : ""}
                                                            disabled={!this.state.experience}
                                                            fullWidth
                                                            id="experience_company"
                                                            label="Experience Company"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "experience_company")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            style={{ color: "#000000", fontWeight: "600" }}
                                                            value={this.state.experience ? this.state.experience_years : ""}
                                                            disabled={!this.state.experience}
                                                            fullWidth
                                                            id="experience_years"
                                                            label="Experience Years"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "experience_years")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        />
                                                    </GridItem> */}

                                                {/* <div className={classes.title} style={{ width: "100rem" }}>
                                                        <h4
                                                            style={{
                                                                fontWeight: "500",
                                                                textAlign: "left",
                                                                paddingBottom: "3px",
                                                                marginBottom: "0px",
                                                            }}
                                                        >
                                                            Account Info.
                                                        </h4>
                                                        <Divider xs={12} />
                                                    </div> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            value={this.state.class_level}
                                                            variant="filled"
                                                            fullWidth
                                                            id="class_level"
                                                            label="Class Level"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "class_level")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {merch_class_level_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            value={this.state.active}
                                                            variant="filled"
                                                            fullWidth
                                                            id="active"
                                                            label="Active"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "active")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {active_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            value={this.state.status}
                                                            variant="filled"
                                                            fullWidth
                                                            id="status"
                                                            label="status"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "status")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            maxRows={1}
                                                        >
                                                            {status_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem> */}

                                                <Grid item xs={12}>
                                                    <p></p>
                                                    <Paper className={classes.paper}> </Paper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <p></p>
                                                    <Paper className={classes.paper}> </Paper>
                                                </Grid>
                                                <GridItem xs={12} sm={12} md={10}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        style={{
                                                            float: "right",
                                                            textTransform: "none",
                                                            backgroundColor: "#91041C",
                                                            color: "#FFFFFF",
                                                            marginTop: 14,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                        className={classes.navLink}
                                                        onClick={() => this.goToMerchandiserListPage(2)}
                                                    >
                                                        {" "}
                                                        Cancel{" "}
                                                    </Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        disabled={!this.validateForm()}
                                                        onClick={this.writeMerchandiserData}
                                                        style={{
                                                            float: "right",
                                                            textTransform: "none",
                                                            backgroundColor: "#4BB543",
                                                            color: "#FFFFFF",
                                                            marginTop: 14,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                    >
                                                        {" "}
                                                        Add{" "}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: "#91041c" }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}
AddMerchandiser.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(withRouter(AddMerchandiser));
