import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    UpdateExclusivityContract: {
        padding: "15px",
    },

    headerInputCard: {
        padding: "10px",
        marginBottom: "10px",
    },

    setButton: {
        width: "100%",
        height: "100%",
        maxHeight: "56px",
    },

    merchandisersCard: {
        padding: "10px",
        marginBottom: "10px",
    },
    submitButton: {
        width: "100%",
        height: "100%",
        maxHeight: "56px",
        backgroundColor: COLORS.EMINENCE,
        textTransform: "none",
    },

    merchandiserCard: {
        padding: "10px",
        marginBottom: "10px",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",

        "&:hover": {
            backgroundColor: COLORS.EMINENCE,
            color: COLORS.WHITE,
        },
    },
});

export default styles;
