import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { getAllOutlets } from "../../../services/firestore/Outlet";
import { getAllCities } from "../../../services/firestore/City";
import { getProduct } from "../../../services/firestore/Product";
import { getMerchandiserName, getMPMerchandisers } from "../../../services/firestore/Merchandiser";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import { formatDate, formatTime, getRelativeDateFromToday, getToday } from "../../../helpers/date-utils";
import {
    COLORS,
    getStatusLabel,
    getStatusStyle,
    MERCH_CONTRACT_STATUS,
    TASKS_STATUS,
} from "../../../constants/constants-lagacy";
import { getOutletBranches, getOutletBranchesByCompany } from "../../../services/firestore/Outlet_Branch";
import { Button, Card, darken, TextField } from "@material-ui/core";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDatePicker from "../../../components-lagacy/CustomInput/CustomDatePicker";
import { Grid } from "@material-ui/core";
import ContentHeader from "../../../components-lagacy/Header/ContentHeader";
import TaskGallery from "../../../components-lagacy/Dialog/TaskGallery";
import Swal from "sweetalert2";
import { getScheduleByTask } from "../../../services/firestore/Project";
import { getSupplier } from "../../../services/firestore/Supplier";

import { firestore as db } from "../../../services/firebase";
import { queryMerchandiserExclusivityContracts } from "../../../services/firestore/Merchandiser_Exclusivity_Contract";
import { TASK_TYPES } from "../../../constants/global";

const useStyles = (theme) => ({
    root: {
        marginBottom: 90,
        width: "100%",
        padding: "10px 20px",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
    },
    contentCard: {
        padding: "10px",
        width: "100%",
    },
    loader: {
        marginTop: "35px",
    },
    dateInput: {
        margin: 0,
        height: "100%",
    },
    button: {
        width: "100%",
        height: "100%",
        backgroundColor: COLORS.submit,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
            color: "#fff",
        },
    },
    disabledBtn: {
        backgroundColor: "#c9c9c9",
        color: "#00000042",
    },

    statusButtons: {
        margin: "30px 0",
    },
    statusBtn: {
        textTransform: "none",
        borderBottom: "3px solid #00000000",
        borderTop: "3px solid #00000000",
    },
});

const headRow = [
    { id: "id", label: "No." },
    { id: "date", label: "Date" },
    { id: "project", label: "Project" },
    { id: "supplier", label: "Supplier" },
    { id: "outletBranch", label: "Outlet Branch" },
    { id: "city", label: "City" },
    { id: "status", label: "Status" },
    { id: "merchandiser", label: "Merchandiser" },
];

const outsourceHeadRow = [
    { id: "id", label: "No." },
    { id: "date", label: "Date" },
    { id: "time", label: "Time" },
    { id: "outletBranch", label: "Outlet Branch" },
    { id: "status", label: "Status" },
    { id: "city", label: "City" },
];

const createFilterEl = (id, label, value, onChange, classes, component = "autoComplete", props = { options: [] }) => {
    switch (component) {
        case "autoComplete":
            return (
                <Autocomplete
                    id={id}
                    value={value}
                    onChange={onChange}
                    className={classes}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
                    {...props}
                />
            );
        case "date":
            const rootProps = { id, label, value, onChange, className: classes, inputVariant: "outlined" };
            return <CustomDatePicker rootProps={rootProps} />;
        default:
            return null;
    }
};

const createButton = (id, label, onClick, style, classes) => {
    return (
        <Button id={id} onClick={onClick} style={style} className={classes}>
            {label}
        </Button>
    );
};

let generateTableToolbarButton = (title, action, icon, enabled, iconStyle = {}, style = {}) => {
    return { title, action, icon, enabled, iconStyle, style };
};

class VisitsManagementContent extends React.Component {
    state = {
        loading: true,
        tableLoading: false,
        linkedOutletBranches: [],
        //filters
        startDate: getToday(),
        endDate: getRelativeDateFromToday(6),

        outletsList: [],
        selectedOutlet: null,

        outletBranchesList: [],
        selectedOutletBranch: null,

        supplierBranchesList: [],
        selectedSupplierBranch: null,

        merchandisersList: [],
        selectedMerchandiser: null,

        citiesList: [],
        selectedCity: null,

        suppliersList: [],
        selectedSupplier: null,

        projectsList: [],
        selectedProject: null,

        selectedStatusIndex: 0,

        tasks: [],
        rows: [],

        exclusivityContracts: [],

        gallerySelectedTaskIndex: 0,
        gellerySelectedProductIndex: 0,
        galleryTasks: [],
        openTaskGallery: false,
        galleryToggleShelf: true,
        gelleryLeftImageLoaded: false,
        gelleryRightImageLoaded: false,
    };

    classes = this.props.classes;
    type = this.props.type;

    createFilterComponents = () => {
        //id, label, value, onChange, classes, component = "autoComplete", props = {options: []}
        let filters = [
            createFilterEl(
                "start-date-filter",
                "Start Date",
                this.state.startDate,
                (e) => this.filterChanged("startDate", e),
                this.classes.dateInput,
                "date"
            ),
            createFilterEl(
                "end-date-filter",
                "End Date",
                this.state.endDate,
                (e) => this.filterChanged("endDate", e),
                this.classes.dateInput,
                "date"
            ),

            createFilterEl(
                "outlets-filter",
                "Outlet",
                this.state.selectedOutlet,
                (e, val) => this.filterChanged("selectedOutlet", val),
                this.classes.filterInput,
                "autoComplete",
                { options: this.state.outletsList }
            ),
            createFilterEl(
                "outlet-branches-filter",
                "Outlet Branch",
                this.state.selectedOutletBranch,
                (e, val) => this.filterChanged("selectedOutletBranch", val),
                this.classes.filterInput,
                "autoComplete",
                { options: this.state.outletBranchesList }
            ),
            createFilterEl(
                "city-filter",
                "City",
                this.state.selectedCity,
                (e, val) => this.filterChanged("selectedCity", val),
                this.classes.filterInput,
                "autoComplete",
                { options: this.state.citiesList }
            ),
        ];

        if (this.type !== "outsource") {
            filters.push(
                createFilterEl(
                    "merch-filter",
                    "Merchandiser",
                    this.state.selectedMerchandiser,
                    (e, val) => this.filterChanged("selectedMerchandiser", val),
                    this.classes.filterInput,
                    "autoComplete",
                    { options: this.state.merchandisersList }
                ),
                createFilterEl(
                    "supplier-filter",
                    "supplier",
                    this.state.selectedSupplier,
                    (e, val) => this.filterChanged("selectedSupplier", val),
                    this.classes.filterInput,
                    "autoComplete",
                    { options: this.state.suppliersList }
                ),
                createFilterEl(
                    "project-filter",
                    "Project",
                    this.state.selectedProject,
                    (e, val) => this.filterChanged("selectedProject", val),
                    this.classes.filterInput,
                    "autoComplete",
                    { options: this.state.projectsList }
                )
            );
        }

        return filters;
    };

    createStatusButtons = () => {
        const selectedStyle = { backgroundColor: "#EEEEEE", color: "#91041C", borderBottom: "3px solid #91041C" };
        const currentIndex = this.state.selectedStatusIndex;
        const type = this.type;

        let buttons = [
            createButton(
                "all-status-btn",
                "All",
                () => this.statusSelected(0),
                currentIndex === 0 ? selectedStyle : { backgroundColor: "#91041C", color: "#EEEEEE" },
                this.classes.statusBtn
            ),
            createButton(
                "open-status-btn",
                getStatusLabel(TASKS_STATUS.OPEN, type),
                () => this.statusSelected(1),
                currentIndex === 1 ? selectedStyle : getStatusStyle(TASKS_STATUS.OPEN),
                this.classes.statusBtn
            ),
            createButton(
                "booked-status-btn",
                getStatusLabel(TASKS_STATUS.BOOKED, type),
                () => this.statusSelected(2),
                currentIndex === 2 ? selectedStyle : getStatusStyle(TASKS_STATUS.BOOKED),
                this.classes.statusBtn
            ),
            createButton(
                "process-status-btn",
                getStatusLabel(TASKS_STATUS.UNDER_PROCESS, type),
                () => this.statusSelected(3),
                currentIndex === 3 ? selectedStyle : getStatusStyle(TASKS_STATUS.UNDER_PROCESS),
                this.classes.statusBtn
            ),
        ];

        if (type === "insource") {
            buttons = [
                ...buttons,
                createButton(
                    "auditing-status-btn",
                    getStatusLabel(TASKS_STATUS.FINISHED, type),
                    () => this.statusSelected(4),
                    currentIndex === 4 ? selectedStyle : getStatusStyle(TASKS_STATUS.FINISHED),
                    this.classes.statusBtn
                ),
                createButton(
                    "completed-status-btn",
                    getStatusLabel(TASKS_STATUS.NO_SHOW, type),
                    () => this.statusSelected(5),
                    currentIndex === 5 ? selectedStyle : getStatusStyle(TASKS_STATUS.NO_SHOW),
                    this.classes.statusBtn
                ),
                createButton(
                    "canceled-status-btn",
                    getStatusLabel(TASKS_STATUS.CANCELED, type),
                    () => this.statusSelected(6),
                    currentIndex === 6 ? selectedStyle : getStatusStyle(TASKS_STATUS.CANCELED),
                    this.classes.statusBtn
                ),
            ];
        } else {
            buttons = [
                ...buttons,
                createButton(
                    "auditing-status-btn",
                    getStatusLabel(TASKS_STATUS.AUDITING, type),
                    () => this.statusSelected(4),
                    currentIndex === 4 ? selectedStyle : getStatusStyle(TASKS_STATUS.AUDITING),
                    this.classes.statusBtn
                ),
                createButton(
                    "completed-status-btn",
                    getStatusLabel(TASKS_STATUS.COMPLETED, type),
                    () => this.statusSelected(5),
                    currentIndex === 5 ? selectedStyle : getStatusStyle(TASKS_STATUS.COMPLETED),
                    this.classes.statusBtn
                ),
            ];
        }

        return buttons;
    };

    init = async () => {
        try {
            const mpID = this.props.mpID;
            const type = this.type;
            //download filters lists
            let requests = [
                getAllOutlets(),
                getAllCities(),
                queryMerchandiserExclusivityContracts([
                    { key: "mp_id", operator: "==", value: mpID },
                    { key: "end_state", operator: ">=", value: getToday() },
                    { key: "status", operator: "==", value: MERCH_CONTRACT_STATUS.ACTIVE },
                ]),
                // getLinkedOutletBranchesBySupplierID(supplierID),
            ];

            if (type === "insource") requests.push(getMPMerchandisers(mpID));

            let [outletsList, citiesList, exclusivityContracts, merchandisersList] = await Promise.all(requests);

            outletsList = outletsList.map((outlet) => ({ value: outlet.id, label: outlet.data().En_short_name }));
            citiesList = citiesList.map((city) => ({ value: city.id, label: city.data().En_name }));
            exclusivityContracts = exclusivityContracts.map((contract) => contract.data());

            if (type === "insource") {
                merchandisersList = merchandisersList.map((merch) => ({
                    value: merch.id,
                    label: getMerchandiserName(merch.data()),
                }));
            } else {
                merchandisersList = [];
            }

            this.setState(
                { outletsList, citiesList, merchandisersList, exclusivityContracts, loading: false },
                async () => {
                    //download tasks
                    await this.downloadTasks();
                }
            );
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    downloadTasks = async () => {
        this.setState({ tableLoading: true });
        let type = this.type;
        const { startDate, endDate, selectedOutlet, selectedOutletBranch, selectedCity, selectedMerchandiser } =
            this.state;
        endDate.setHours(23, 59, 59, 59);
        try {
            let ref = db
                .collection("Task")
                .where("mp_id", "==", this.props.mpID)
                .where("date_time_from", ">=", startDate)
                .where("date_time_from", "<=", endDate)
                .where("type", "==", TASK_TYPES.MP_INSOURCE);
            // if (type === "insource") ref = ref.where("type", "==", "mp-insource");
            // else if (type === "outsource") ref = ref.where("type", "in", ["regular", "handover"]);

            if (selectedOutlet) ref = ref.where("outlet_id", "==", +selectedOutlet.value);
            if (selectedOutletBranch) ref = ref.where("outlet_branch_id", "==", +selectedOutletBranch.value);
            if (selectedCity) ref = ref.where("city_id", "==", +selectedCity.value);
            if (selectedMerchandiser) ref = ref.where("uid", "==", selectedMerchandiser.value);

            let tasks = (await ref.get()).docs.map((doc) => doc.data());

            let murtabRef = db
                .collection("Task")
                .where("uid_company", "==", `${this.props.mpID}`)
                .where("date_time_from", ">=", startDate)
                .where("date_time_from", "<=", endDate)
                .where("type", "in", [TASK_TYPES.REGULAR, TASK_TYPES.MP_OUTSOURCE, TASK_TYPES.HANDOVER]);

            if (selectedOutlet) murtabRef = murtabRef.where("outlet_id", "==", +selectedOutlet.value);
            if (selectedOutletBranch)
                murtabRef = murtabRef.where("outlet_branch_id", "==", +selectedOutletBranch.value);
            if (selectedCity) murtabRef = murtabRef.where("city_id", "==", +selectedCity.value);
            if (selectedMerchandiser) murtabRef = murtabRef.where("uid", "==", selectedMerchandiser.value);

            let murtabTasks = (await murtabRef.get()).docs.map((doc) => doc.data());
            console.log(murtabTasks);

            tasks = await Promise.all(
                tasks.map(async (task) => {
                    if (!task.only_header) return task;

                    const schedule = await getScheduleByTask(task);
                    if (!schedule || !schedule.exists) {
                        throw new Error(`Could not find schedule for the task ID: ${task.id}`);
                    }
                    const modifiedProps = task.modified_properties ?? [];
                    task.uid = modifiedProps.indexOf("uid") !== -1 ? task.uid : schedule.data().merchandiser_id;

                    // find if the assigned merchandiser is in the exclusivity contract
                    const exclContracts = this.state.exclusivityContracts.filter((contract) => {
                        return contract.merchandisers.find((m) => m.id === task.uid);
                    });
                    // the closest contract by start date
                    const exclContract = exclContracts.reduce((prev, curr) => {
                        if (!prev || prev.start_date > curr.start_date) {
                            return curr;
                        }
                        return prev;
                    }, null);

                    //if this task is during the contract period
                    if (
                        task.date_time_from.toDate() >= exclContract?.start_date.toDate() &&
                        task.date_time_from.toDate() <= exclContract?.end_date.toDate()
                    ) {
                        task.uid = null;
                    }

                    task.state =
                        modifiedProps.indexOf("state") !== -1
                            ? task.state
                            : task.uid
                            ? TASKS_STATUS.BOOKED
                            : TASKS_STATUS.OPEN;
                    return task;
                })
            );

            // if (type === "outsource") {
            //     tasks = tasks.filter((doc) => doc.data().type === "regular" || doc.data().type === "handover");
            // }

            let outletBranchIDs = [];

            tasks = [...tasks, ...murtabTasks];
            tasks = await Promise.all(
                tasks.map(async (task) => {
                    let status = getStatusLabel(task.state, type);
                    let city = this.state.citiesList.find((city) => city.value === `${task.city_id}`).label;
                    let merchandiser = this.state.merchandisersList.find((merch) => merch.value === task.uid);
                    // let project = (await getProject(task.project_id)).data();
                    let project = task.project_id;
                    let supplier = (await getSupplier(`${task.supplier_id}`)).data();

                    merchandiser = merchandiser ? merchandiser.label : "-";
                    if (!outletBranchIDs.includes(task.outlet_branch_id) && selectedOutletBranch === null)
                        outletBranchIDs.push(`${task.outlet_branch_id}`);

                    let time = `${formatTime(task.date_time_from.toDate())} - ${formatTime(
                        task.date_time_to.toDate()
                    )}`;
                    return {
                        id: `${task.task_id}`,
                        date: formatDate(task.date_time_from.toDate()),
                        outletBranch: selectedOutletBranch ? selectedOutletBranch.label : "",
                        city: city,
                        time: time,
                        merchandiser: merchandiser,
                        status: status,
                        project: project,
                        supplier: supplier.En_short_name,
                        data: { ...task },
                        cellStyles: {
                            status: { ...getStatusStyle(task.state), padding: "0px", textAlign: "center" },
                        },
                    };
                })
            );

            if (!selectedOutletBranch) {
                let outletBranches = (await getOutletBranches(outletBranchIDs)).map((ob) => ob.data());
                tasks.forEach((task) => {
                    if (task.outletBranch) return;
                    let obID = `${task.data.outlet_branch_id}`;
                    task.outletBranch = outletBranches.find((ob) => ob.branch_id === obID).En_short_name;
                });
            }

            this.setState({ tasks: tasks, tableLoading: false }, () =>
                this.statusSelected(this.state.selectedStatusIndex)
            );
        } catch (error) {
            this.setState({ tableLoading: false });
            throw error;
        }
    };

    downlaodOutletBranchList = async (outlet, supplierBranch) => {
        if (!outlet) {
            this.setState({ outletBranchesList: [], selectedOutletBranch: null });
            return;
        }

        this.setState({ loading: true });
        try {
            let outletID = outlet ? outlet.value : null;
            // let supplierBranchID = supplierBranch ? supplierBranch.value : null;

            // let outletBranchIDs = this.getOutletBranchIDs(supplierBranchID);
            // let outletBranchesList = await getOutletBranches(outletBranchIDs);
            let outletBranchesList = await getOutletBranchesByCompany(outletID);
            // if (outletID) {
            //     outletBranchesList = outletBranchesList.filter((doc) => doc.data().outlet_id === outletID);
            // }

            outletBranchesList = outletBranchesList.map((ob) => ({ value: ob.id, label: ob.data().En_short_name }));

            this.setState({ loading: false, outletBranchesList: outletBranchesList, selectedOutletBranch: null });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false, outletBranchesList: [], selectedOutletBranch: null });
        }
    };

    getOutletBranchIDs = (supplierBranchID) => {
        let linkedOutletBranches = this.state.linkedOutletBranches;
        if (supplierBranchID) {
            let linked = linkedOutletBranches.find((lob) => lob.supplierBranchID === supplierBranchID);
            if (!linked) return [];
            return linked.outletBranches.map((ob) => ob.id);
        }
        let outletBranchesIDs = [];
        for (const linked of linkedOutletBranches) {
            outletBranchesIDs = [...new Set(outletBranchesIDs.concat(linked.outletBranches.map((ob) => ob.id)))];
        }

        return outletBranchesIDs;
    };

    filterChanged = (id, value) => {
        if (id === "selectedOutlet") this.downlaodOutletBranchList(value, this.state.selectedSupplierBranch);
        if (id === "selectedSupplierBranch") this.downlaodOutletBranchList(this.state.selectedOutlet, value);
        this.setState({ [id]: value });
    };

    statusSelected = (index) => {
        let tasks = [...this.state.tasks];
        const statusIndices = {
            1: [TASKS_STATUS.OPEN],
            2: [TASKS_STATUS.BOOKED, TASKS_STATUS.RESERVED],
            3: [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.DELAY],
            4: [TASKS_STATUS.FINISHED],
            5: [TASKS_STATUS.NO_SHOW],
            6: [TASKS_STATUS.CANCELED],
        };

        if (index !== 0) {
            tasks = tasks.filter((task) => {
                let status = task.data.state;
                let indices = statusIndices[index];
                return indices.includes(status);
            });
        }

        this.setState({ selectedStatusIndex: index, rows: tasks });
    };

    onToggleShelf = (toggle) => {
        this.setState({ galleryToggleShelf: toggle });
    };

    toggleLeftLoader = (loaded) => {
        this.setState({ gelleryLeftImageLoaded: loaded });
    };
    toggleRightLoader = (loaded) => {
        this.setState({ gelleryRightImageLoaded: loaded });
    };

    onTaskIndexChange = (index) => {
        this.setState({ gallerySelectedTaskIndex: index });
    };

    onProductIndexChange = (index) => {
        this.setState({ gellerySelectedProductIndex: index });
    };

    dropNewReferenceImg = (taskIndex, productIndex, newReferenceImg, newReferenceTaskID) => {
        let galleryTasks = [...this.state.galleryTasks];
        let productImages = { ...galleryTasks[taskIndex].products[productIndex].images };
        // let shelfToggled = this.state.galleryToggleShelf;

        // if (shelfToggled) {
        //     productImages.shelfReference = newReferenceImg;
        //     productImages.shelfReferenceTaskID = newReferenceTaskID;
        // } else {
        //     productImages.extraDisplayReference = newReferenceImg;
        //     productImages.extraDisplayReferenceTaskID = newReferenceTaskID;
        // }
        productImages.referenceWork = newReferenceImg;
        galleryTasks[taskIndex].products[productIndex].images = productImages;
        this.setState({
            galleryTasks: galleryTasks,
        });
    };

    rollBackReferenceImg = () => {
        let galleryTasks = [...this.state.galleryTasks];
        let taskIndex = this.state.gallerySelectedTaskIndex;
        let productIndex = this.state.gellerySelectedProductIndex;
        let productImages = { ...galleryTasks[taskIndex].products[productIndex].images };
        // let shelfToggled = this.state.galleryToggleShelf;

        // if (shelfToggled) {
        //     productImages.shelfReference = productImages.oldShelfReference;
        //     productImages.shelfReferenceTaskID = productImages.oldShelfReferenceTaskID;
        // } else {
        //     productImages.extraDisplayReference = productImages.oldExtraDisplayReference;
        //     productImages.extraDisplayReferenceTaskID = productImages.oldExtraDisplayReferenceTaskID;
        // }

        productImages.referenceWork = productImages.oldReferenceWork;

        galleryTasks[taskIndex].products[productIndex].images = productImages;
        this.setState({
            galleryTasks: galleryTasks,
        });
    };

    updateReferenceImg = async () => {
        const result = await Swal.fire({
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "",
            cancelButtonColor: "",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            title: "Confirm Replacement",
            text: `Are you sure you want to update the reference image?`,
        });

        if (!result.value) {
            return;
        }

        let galleryTasks = [...this.state.galleryTasks];
        let taskIndex = this.state.gallerySelectedTaskIndex;
        let productIndex = this.state.gellerySelectedProductIndex;
        let galleryProduct = galleryTasks[taskIndex].products[productIndex];
        let productImages = { ...galleryTasks[taskIndex].products[productIndex].images };
        // let shelfToggled = this.state.galleryToggleShelf;
        let productDoc = galleryTasks[taskIndex].products[productIndex].referenceProductDoc;

        if (!productDoc) {
            await Swal.fire({
                type: "error",
                title: "Could not set reference image.",
                text: `It appears that the product is not registered or deleted from the project.`,
            });

            return false;
        }
        let data = productDoc.data();
        if (galleryProduct.referenceType === "shelf") {
            data.reference_image = productImages.referenceWork;
        } else {
            let index = data.extra_displays.findIndex((ex) => ex.reference_id === galleryProduct.referenceID);
            data.extra_displays[index].reference_image = productImages.referenceWork;
        }

        this.setState({ loading: true });
        try {
            // let updatedReference = {};
            // if (shelfToggled) {
            //     updatedReference = {
            //         shelf_picture_reference: productImages.shelfReference,
            //         shelf_task_reference: productImages.shelfReferenceTaskID,
            //     };
            //     productImages.oldShelfReference = productImages.shelfReference;
            //     productImages.oldShelfReferenceTaskID = productImages.shelfReferenceTaskID;
            // } else {
            //     updatedReference = {
            //         extra_display_picture_reference: productImages.extraDisplayReference,
            //         extra_display_task_reference: productImages.extraDisplayReferenceTaskID,
            //     };
            //     productImages.oldExtraDisplayReference = productImages.extraDisplayReference;
            //     productImages.oldExtraDisplayReferenceTaskID = productImages.extraDisplayReferenceTaskID;
            // }

            // await productDoc.ref.update(updatedReference);
            await productDoc.ref.update(data);
            productImages.oldReferenceWork = productImages.referenceWork;
            // this.showDialogMsg(
            //     "new Reference Has been Replaced",
            //     "You can now compare the images in other tasks with newly replaced reference",
            //     this.hideDialogMsg
            // );
        } catch (error) {
            console.log(error);
        }

        galleryTasks[taskIndex].products[productIndex].images = productImages;
        this.setState({
            galleryTasks: galleryTasks,
            loading: false,
        });
    };

    handleOpenImages = async (IDs) => {
        this.setState({ loading: true });

        try {
            IDs = IDs.map((id) => `${id}`);
            let galleryTasks = await Promise.all(IDs.map(this.prepareTaskGallery));

            this.setState({
                gallerySelectedTaskIndex: 0,
                gellerySelectedProductIndex: 0,
                galleryTasks: galleryTasks,
                openTaskGallery: true,
                gelleryLeftImageLoaded: false,
                gelleryRightImageLoaded: false,
            });
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    prepareTaskGallery = async (id) => {
        // let taskProducts = [];
        let galleryTask = null;
        try {
            // get task doc
            let task = this.state.tasks.find((task) => task.id === id);

            const { project_id, supplier_branch_id, outlet_branch_id, schedule_id } = task.data;

            // get reference images for products
            let referenceProducts = (
                await db
                    .collection("Project")
                    .doc(project_id)
                    .collection("Branch_Outlet_Product")
                    .doc(`${supplier_branch_id}`)
                    .collection("Outlet_Product")
                    .doc(`${outlet_branch_id}`)
                    .collection("Schedule")
                    .doc(`${schedule_id}`)
                    .collection("Product")
                    .get()
            ).docs;

            //get task products
            let taskProducts = (await db.collection("Task").doc(id).collection("Task_Product").get()).docs;

            // get task details
            let taskDetails = (await db.collection("Task_Details").where("task_id", "==", Number(id)).get()).docs;

            // get products
            let productsInfo = await Promise.all(referenceProducts.map(async (tp) => getProduct(tp.id)));
            let taskImages = [];
            for (const td of taskDetails) {
                const referenceProduct = referenceProducts.find((rp) => rp.id === td.data().product_id);
                const taskProduct = taskProducts.find((tp) => tp.id === td.data().product_id);
                const productInfo = productsInfo.find((pi) => pi.id === td.data().product_id);
                let referenceID = td.data().reference_id;
                let referenceType = "shelf";

                if (!referenceID) return false;

                let referenceImage = null;
                if (td.data().extra_display) {
                    //determine which reference, from project or a backup from task
                    let referenceSource = referenceProduct
                        ? referenceProduct.data().extra_displays.find((ex) => ex.reference_id === referenceID)
                        : null;
                    if (!referenceSource)
                        referenceSource = taskProduct
                            .data()
                            .extra_displays.find((ex) => ex.reference_id === referenceID);

                    referenceImage = referenceSource.reference_image;
                    referenceType = "extra-display";
                } else {
                    referenceImage = referenceProduct
                        ? referenceProduct.data().reference_image
                        : taskProduct.data().reference_image;
                }

                taskImages.push({
                    referenceID: referenceID,
                    referenceType: referenceType,
                    ProductName: productInfo ? productInfo.data().En_name : "Name Not Found.",
                    outletBranchName: null,
                    images: {
                        // shelfReference: referenceProduct ? referenceProduct.data().shelf_picture_reference : null,
                        // shelfReferenceTaskID: referenceProduct ? referenceProduct.data().shelf_task_reference : null,
                        // oldShelfReference: referenceProduct ? referenceProduct.data().shelf_picture_reference : null,
                        // oldShelfReferenceTaskID: referenceProduct ? referenceProduct.data().shelf_task_reference : null,
                        // extraDisplayReference: referenceProduct ? referenceProduct.data().extra_display_picture_reference : null,
                        // extraDisplayReferenceTaskID: referenceProduct ? referenceProduct.data().extra_display_task_reference : null,
                        // oldExtraDisplayReference: referenceProduct ? referenceProduct.data().extra_display_picture_reference : null,
                        // oldExtraDisplayReferenceTaskID: referenceProduct ? referenceProduct.data().extra_display_task_reference : null,
                        // shelf: td.data().picture_after || null,
                        // extra_display: td.data().extra_display_picture_after || null,
                        actualWork: td.data().picture_after || null,
                        referenceWork: referenceImage,
                        oldReferenceWork: referenceImage,
                    },
                    referenceProductDoc: referenceProduct,
                });
            }

            galleryTask = {
                ...task,
                products: taskImages,
            };
        } catch (error) {
            console.log(error);
        }

        return galleryTask;
    };

    componentDidMount() {
        this.init();
    }
    render() {
        const classes = this.classes;
        const filters = this.createFilterComponents();
        const statusButtons = this.createStatusButtons();
        const tableToolbarButtons = [
            generateTableToolbarButton("Open Gallery", this.handleOpenImages, "image", (ids) => ids.length > 0),
            generateTableToolbarButton(
                "Go to visit details",
                this.props.gotToVisitDetails,
                "",
                (ids) => ids.length === 1
            ),
        ];

        const tableHeader = this.type === "outsource" ? outsourceHeadRow : headRow;
        return (
            <div className={classes.root}>
                <FullPageLoader loading={this.state.loading} className={classes.loader} />
                <Card className={classes.contentCard}>
                    <ContentHeader title={`Visits Management`} />
                    {/* filters */}
                    <Grid container spacing={1}>
                        {filters.map((filter, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={`task-filter-${index}`}>
                                {filter}
                            </Grid>
                        ))}

                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Button
                                className={classes.button}
                                classes={{ disabled: classes.disabledBtn }}
                                onClick={this.downloadTasks}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                    {/* status buttons */}
                    <Grid container spacing={1} className={classes.statusButtons}>
                        {statusButtons.map((button, index) => (
                            <Grid item key={`status-btn-${index}`}>
                                {button}
                            </Grid>
                        ))}
                    </Grid>
                    <EnhancedTable
                        title={"Tasks"}
                        columns={tableHeader}
                        rows={this.state.rows}
                        actions={tableToolbarButtons}
                        updateSelected={() => {}}
                        loading={this.state.tableLoading}
                    />
                </Card>

                <TaskGallery
                    open={this.state.openTaskGallery}
                    loading={this.state.loading}
                    toggleShelf={this.state.galleryToggleShelf}
                    onToggleShelf={this.onToggleShelf}
                    handleClose={() => this.setState({ openTaskGallery: false })}
                    tasks={this.state.galleryTasks}
                    selectedTask={this.state.gallerySelectedTaskIndex}
                    selectedProduct={this.state.gellerySelectedProductIndex}
                    onTaskIndexChange={this.onTaskIndexChange}
                    onProductIndexChange={this.onProductIndexChange}
                    leftImageLoaded={this.state.gelleryLeftImageLoaded}
                    rightImageLoaded={this.state.gelleryRightImageLoaded}
                    toggleLeftLoader={this.toggleLeftLoader}
                    toggleRightLoader={this.toggleRightLoader}
                    dropNewReferenceImg={this.dropNewReferenceImg}
                    rollBackReferenceImg={this.rollBackReferenceImg}
                    updateReferenceImg={this.updateReferenceImg}
                />
            </div>
        );
    }
}

VisitsManagementContent.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(VisitsManagementContent);
