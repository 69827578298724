const PATHS = {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",

    TERMS_AND_CONDITIONS: "https://derwaza.tech/privacy-policy/",
    PRIVACY_POLICY: "https://derwaza.tech/terms-and-conditions/",

    DASHBOARD: "/dashboard",
    HELP: "/help",

    ACCOUNT_INFO: "/account-info",
    UPDATE_ACCOUNT_INFO: "/update-account-info",

    BRANCHES: "/branches",
    ADD_BRANCH: "/add-branch",
    UPDATE_BRANCH: "/update-branch",

    MERCHANDISERS: "/employees",
    ADD_MERCHANDISER: "/add-employee",
    UPDATE_MERCHANDISER: "/update-employee",

    EXCLUSIVITY_CONTRACTS: "/exclusivity-contracts",
    ADD_EXCLUSIVITY_CONTRACT: "/add-exclusivity-contract",
    UPDATE_EXCLUSIVITY_CONTRACT: "/update-exclusivity-contract",

    SUPERVISORS: "/supervisors",
    ADD_SUPERVISOR: "/add-supervisor",
    UPDATE_SUPERVISOR: "/update-supervisor",

    //* SERVICES
    INSOURCE_PROJECTS: "/insource-projects",
    ADD_INSOURCE_PROJECT: "/add-insource-project",
    MANAGE_INSOURCE_PROJECT: "/manage-insource-project",

    OUTSOURCE_PROJECTS: "/outsource-projects",
    ADD_OUTSOURCE_PROJECT: "/add-outsource-project",
    MANAGE_OUTSOURCE_PROJECT: "/manage-outsource-project",

    //* OPERATIONS
    VISITS_MANAGEMENT: "/visits-management",
    VISIT_DETAILS: "/visit-details",
    VISIT_REPORT: "/visit-report",

    OUTSOURCE_VISITS_MANAGEMENT: "/outsource-visits-management",
    OUTSOURCE_VISIT_DETAILS: "/outsource-visit-details",
    OUTSOURCE_VISIT_REPORT: "/outsource-visit-report",

    ROUTE_PLAN_MANAGEMENT: "/route-plan-management",
    ROUTE_VISITS_MANAGEMENT: "/route-visits-management",

    VISITS_POOL: "/visits-pool",

    GALLERY: "/gallery",

    //* REPORTS
    DAILY_MERCHANDISING_REPORT: "/daily-merchandising-report",
    MERCHANDISER_ATTENDANCE_REPORT: "/merchandiser-attendance-report",
    EXPIRY_AND_DAMAGE_REPORT: "/expiry-and-damage-report",
    NEAR_EXPIRY_REPORT: "/near-expiry-report",

    //* SUBSCRIPTION
    SUBSCRIPTIONS: "/subscriptions",
};

export default PATHS;
