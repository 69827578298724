import React, { Component } from "react";
import styles from "./DashboardContent.module.css";
import { firestore as db } from "../../services/firebase";
import PATHS from "../../constants/paths";

const setCards = (header, description, path, isCompleted, checkCompletion) => {
    return { header, description, path, isCompleted, checkCompletion };
};

const checkSupplierbranches = async (id) => {
    let branches = await db.collection("MP_Branch").where("mp_id", "==", id).get();
    return !branches.empty;
};

const checkMPMerch = async (id) => {
    let merch = await db
        .collection("Merchandiser")
        .where("account_information.owner_id", "==", id)
        .where("account_information.type", "==", "mp_employee")
        .get();
    return !merch.empty;
};

const checkProjectInit = async (id) => {
    let projects = await db.collection("Project").where("mp_id", "==", id).get();
    return !projects.empty;
};

const checkProjectConfirmation = async (id) => {
    const confirmedProjects = await db
        .collection("Project")
        .where("mp_id", "==", id)
        .where("isConfirmed", "==", true)
        .get();
    return !confirmedProjects.empty;
    //     .docs.reduce(async (acc, doc) => {
    //     return doc.data().confirm === "1" || acc;
    // }, false);
};

class DashboardContent extends Component {
    state = {
        guideCards: [
            setCards(
                "Add Your Branches",
                "Provide information of your branch",
                PATHS.ADD_BRANCH,
                false,
                checkSupplierbranches
            ),
            setCards(
                "Add Your Merchandisers",
                "Start adding your merchandisers to operate your visits.",
                PATHS.ADD_MERCHANDISER,
                false,
                checkMPMerch
            ),
            setCards(
                "Initialize a Project",
                "Put your basic information about your merchandising project",
                PATHS.ADD_INSOURCE_PROJECT,
                false,
                checkProjectInit
            ),
            setCards(
                "Confirm a Project",
                "Follow the step and provide all the information needed before confirmation",
                PATHS.INSOURCE_PROJECTS,
                false,
                checkProjectConfirmation
            ),
        ],
    };

    updateCards = async () => {
        let newCards = await Promise.all(
            this.state.guideCards.map(async (card) => {
                const result = await card.checkCompletion(this.props.supplierId);
                card.isCompleted = result;
                return card;
            })
        );

        this.setState({
            ...this.state,
            guideCards: newCards,
        });
    };

    componentDidMount() {
        this.updateCards();
    }
    render() {
        return (
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <h2 className={styles.GuideHeader}>Your journey to start your project begins here</h2>
                    <div className={styles.CardsContainer}>
                        <div className={styles.Cards}>
                            {this.state.guideCards.map((card, index) => {
                                return (
                                    <div
                                        key={`guide-card-${index}`}
                                        className={`${styles.CardInner} ${card.isCompleted ? styles.Completed : null}`}
                                        onClick={() => {
                                            this.props.goTo(card.path);
                                        }}
                                    >
                                        {/* Number */}
                                        <div className={styles.CardNumber}>{index + 1}</div>
                                        {/* Text */}
                                        <div className={styles.CardText}>
                                            <span className={styles.Header}>{card.header}</span>
                                            <span className={styles.Description}>{card.description}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardContent;
