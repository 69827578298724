import { Card } from "@material-ui/core";
import React from "react";
import { useMemo } from "react";
import COLORS from "../../../constants/colors";
import AnimatedNumbers from "react-animated-numbers";
import LineChart from "../../../core-ui/charts/line-chart";

//related to meterial ui package
import styles from "./styles";

const settings = {
    curve: "basis",
    enableGridX: false,
    enableGridY: false,
    yScale: {
        type: "linear",
        min: "auto",
        max: 100,
        stacked: false,
        reverse: false,
    },
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    pointSize: 1,
    pointBorderWidth: 2,
    pointLabelYOffset: -12,
    useMesh: true,
    enableArea: true,
    enableCrosshair: false,
    tooltip: (d) => null,
    animate: true,
};

const IndicatorChart = ({
    loading = false,
    title = "Incicator",
    color = COLORS.LIGHT_EMINENCE,
    value = 0,
    type = "number",
    classes: customClasses = {},
}) => {
    const classes = styles();

    const lineData = useMemo(
        () => [
            {
                id: "indicator",
                data: [
                    { x: 1, y: loading ? 0 : Math.random() * 70 },
                    { x: 2, y: loading ? 0 : Math.random() * 70 },
                    { x: 3, y: loading ? 0 : Math.random() * 70 },
                    { x: 4, y: loading ? 0 : Math.random() * 70 },
                    { x: 5, y: loading ? 0 : Math.random() * 70 },
                ],
            },
        ],
        [loading]
    );

    let result = value;
    if (type === "rate") {
        result = (
            <div className={classes.rate}>
                <AnimatedNumbers animateToNumber={value.toFixed(2)} />
                <span>%</span>
            </div>
        );
    }

    return (
        <div className={`${classes.indicatorChartContainer} ${customClasses.container ?? ""}`}>
            <Card className={`${classes.card} ${customClasses.card ?? ""}`}>
                <LineChart data={lineData} settings={{ ...settings, colors: [color] }} />
                <div className={`${classes.indicatorTextWrap} ${customClasses.textWrap ?? ""}`}>
                    <h2 className={`${classes.indicatorTitle} ${customClasses.title ?? ""}`}>{title}</h2>
                    <h1 className={`${classes.indicatorValue} ${customClasses.value ?? ""}`}>{result}</h1>
                </div>
            </Card>
        </div>
    );
};

export default IndicatorChart;
