import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import styles from "./styles";
// import { Font } from "./fonts.js";

let rowsPerPage = 26;
let totalPages = 0;

let formatDate = (date) => {
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

let createRow = (product, style) => {
    if (!product)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${product.id}`}>
                <Text style={styles.lastCell}>Error getting the product's data</Text>
            </View>
        );

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${product.id}`}>
            <Text style={styles.cell}>{product.code}</Text>
            <Text style={styles.lastCell}>{product.name}</Text>
        </View>
    );
};

let createPage = (outlet, info, pageNum) => {
    const rows = [...outlet.products];
    let rowsToDisplay = rows.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page
            size="A4"
            orientation="portrait"
            style={styles.page}
            key={`products coverage PDF ${Math.random()}-${pageNum}`}
        >
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    {/* <Text style={styles.supplierText}>Supplier</Text> */}
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Appendix B | Products Coverage</Text>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Project No.: <Text style={styles.bold}>{info.projectId}</Text>
                    </Text>
                    <Text>
                        Project Period: {formatDate(info.startDate)} - {formatDate(info.endDate)}
                    </Text>
                    <Text>
                        Supplier: <Text style={styles.bold}>{`${info.supplierName} | ${info.supplierCompany}`}</Text>
                    </Text>
                </View>
                {/* <View style={styles.right}>
                    <Text>Phone Number: {phone}</Text>
                    <Text>Issued Date: {formatDate(new Date())}</Text>
                    <Text>Issued By: {exportedBy}</Text>
                </View> */}
            </View>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            <View style={styles.rowGroup}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>{outlet.name}</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    <View style={styles.columnGroup}>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Code</Text>
                                        </View>
                                        <View style={styles.lastHeaderCell}>
                                            <Text style={styles.headerCellText}>Name</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            {/* {outlets.map((outlet) => (
                                <View
                                    key={`outlet - ${outlet.id}`}
                                    style={[styles.headerCell, styles.verticalCell, { padding: `${maxHeight + 25}px 0` }]}
                                >
                                    <Text style={styles.verticalText}>{outlet.name}</Text>
                                </View>
                            ))} */}
                        </View>
                    </View>

                    {/* Table Body */}
                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((product, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;

                            return createRow(product, style);
                        })}
                        {/* Total */}
                        {/* <View style={[styles.row, styles.lastRow, { backgroundColor: "gray", color: "#FFFFFF" }]}>
                            <Text style={[styles.cell, styles.totalCell]}>Total Products per Outlet</Text>
                            {outlets.map((outlet) => (
                                <View key={`products per outlet - ${outlet.id}`} style={[styles.cell, styles.outletCell]}>
                                    <Text>{outlet.productIDs.length}</Text>
                                </View>
                            ))}
                        </View> */}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>R.C: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                {/* <Text style={styles.logoFooterText}>Murattab</Text> */}
            </View>
        </Page>
    );
};

/*
projectProductsCoverage = {
    productsCoverage,
    outletsCoverage: [{productIDs, id}]
}

*/

let createDoc = (info) => {
    //destructering:
    const { outlets } = info;

    let pages = [];
    let pagesPerOutlet = 0;

    for (const outlet of outlets) {
        pagesPerOutlet = Math.ceil(outlet.products.length / rowsPerPage);
        for (let pageNum = 0; pageNum < pagesPerOutlet; pageNum++) {
            pages[pageNum + totalPages] = createPage(outlet, info, pageNum);
        }
        totalPages += pagesPerOutlet;
    }

    return pages;
};

// Create Document Component
const ProjectProductsPdf = (props) => {
    let document = createDoc(props.info);

    return <Document>{document}</Document>;
};

export default ProjectProductsPdf;
