import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        position: "relative",
        padding: "20px",
        margin: "0px",
    },
    formCard: {
        padding: "20px",
        margin: "20px 0",
    },
});

export default styles;
