export const WEEKDAYS = {
    Sunday: 101,
    Monday: 102,
    Tuesday: 103,
    Wednesday: 104,
    Thursday: 105,
    Friday: 106,
    Saturday: 107,
};

export const WEEKDAYS_FULL = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
};

export const DATE_TO_WEEKDAY_SHORT = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

// export const COLORS = {
//     primary: "#5e5edc",
//     font: "#fff",
//     background: "#d2d2e4",
//     backgroundFont: "#000",
//     headerBackground: "#d2d2e4",
// };

// export const COLORS = {
//     primary: "#0070c0",
//     font: "#fff",
//     background: "#c6d5e0",
//     backgroundFont: "#0070c0",
//     headerBackground: "#f0f9ff",
//     footerBackground: "#023356",
// };

// export const COLORS = {
//     primary: "#0070c0",
//     font: "#fff",
//     background: "#F5F9FF",
//     backgroundFont: "#0070c0",
//     headerBackground: "#F5F9FF",
//     footerBackground: "#7A91BC",
// };
export const COLORS = {
    primary: "#593c7a",
    // primary: "#0070c0",
    font: "#fff",
    fontDark: "#000",
    // background: "#F5F9FF",
    background: "#ede9f2",
    backgroundFont: "#0070c0",
    headerBackground: "#F5F9FF",
    footerBackground: "#200342",
    // accent: "#bb0826",
    accent: "#bc1e1e",
    secondary: "#999e13",
    submit: "#4BB543",
};

// export const COLORS = {
//     primary: "#91041C",
//     backgroundFont: "#91041C",
//     font: "#fff",
//     background: "#ffefef",
//     headerBackground: "#ffefef",
//     footerBackground: "#48000c",
// };

export const TASKS_STATES = {
    5: "Completed",
    6: "Reserved",
    7: "Open",
    11: "under Process",
    12: "Finished",
    13: "Booked",
    14: "Rejected",
    16: "No Show",
    17: "Delay",
    18: "Auditing",
    19: "Finished Late",
    20: "Finished R",
    21: "Canceled",
};

export const SUPPLIER_TASKS_STATES = {
    5: "Completed",
    7: "Open",
    6: "Booked",
    13: "Booked",
    11: "under Process",
    16: "under Process",
    17: "under Process",
    12: "Auditing",
    14: "Auditing",
    18: "Auditing",
    19: "Auditing",
    20: "Auditing",
    21: "Canceled",
};

export const REPORT_IDS = {
    //operations section
    MECHANDISERS_ATTENDANCE: "EMS001",
    DAILY_MERCHANDISING: "EMS002",
    EXPIRY_AND_DAMAGE: "EMS003",
    MDR: "EMS004",
    EXP_DMG: "EMS2005",

    //managemnt section
    MERCHANDISERS_LIST: "EMS101",

    //co Entry
    BRANCHES_LIST: "EMS201",
};

export const TASKS_STATUS = {
    COMPLETED: "5",
    OPEN: "7",
    RESERVED: "6",
    BOOKED: "13",
    NO_SHOW: "16",
    DELAY: "17",
    UNDER_PROCESS: "11",
    FINISHED: "12",
    REJECTED: "14",
    AUDITING: "18",
    FINISHED_LATE: "19",
    FINISHED_R: "20",
    CANCELED: "21",
};

export let getStatusLabel = (id, type = "self-service") => {
    if (type === "self-service" || type === "insource") {
        switch (id) {
            case TASKS_STATUS.COMPLETED:
                return "Completed";
            case TASKS_STATUS.OPEN:
                return "Not Assigned";
            case TASKS_STATUS.RESERVED:
            case TASKS_STATUS.BOOKED:
                return "Scheduled";
            case TASKS_STATUS.NO_SHOW:
                return "No Show";
            case TASKS_STATUS.DELAY:
            case TASKS_STATUS.UNDER_PROCESS:
                return "Under Process";
            case TASKS_STATUS.FINISHED:
                return "Finished";
            case TASKS_STATUS.REJECTED:
            case TASKS_STATUS.AUDITING:
            case TASKS_STATUS.FINISHED_LATE:
            case TASKS_STATUS.FINISHED_R:
                return "Auditing";
            case TASKS_STATUS.CANCELED:
                return "Canceled";
            default:
                return "Unknown";
        }
    } else if (type === "outsource") {
        switch (id) {
            case TASKS_STATUS.COMPLETED:
                return "Completed";
            case TASKS_STATUS.OPEN:
                return "Open";
            case TASKS_STATUS.RESERVED:
            case TASKS_STATUS.BOOKED:
                return "Booked";
            case TASKS_STATUS.NO_SHOW:
            case TASKS_STATUS.DELAY:
            case TASKS_STATUS.UNDER_PROCESS:
                return "Under Process";
            case TASKS_STATUS.FINISHED:
            case TASKS_STATUS.REJECTED:
            case TASKS_STATUS.AUDITING:
            case TASKS_STATUS.FINISHED_LATE:
            case TASKS_STATUS.FINISHED_R:
                return "Auditing";
            case TASKS_STATUS.CANCELED:
            default:
                return "Unknown";
        }
    }
};

export let getStatusStyle = (id) => {
    switch (id) {
        case TASKS_STATUS.COMPLETED:
            return { backgroundColor: "#000000", color: "#FFFFFF" };
        case TASKS_STATUS.OPEN:
            return { backgroundColor: "#575757", color: "#FFFFFF" };
        case TASKS_STATUS.RESERVED:
        case TASKS_STATUS.BOOKED:
            return { backgroundColor: "#4BB543", color: "#FFFFFF" };
        case TASKS_STATUS.NO_SHOW:
            return { backgroundColor: "#cf0404", color: "#FFFFFF" };
        case TASKS_STATUS.DELAY:
        case TASKS_STATUS.UNDER_PROCESS:
            return { backgroundColor: "#36A2EB", color: "#FFFFFF" };
        case TASKS_STATUS.FINISHED:
            return { backgroundColor: "#A67A44", color: "#FFFFFF" };
        case TASKS_STATUS.REJECTED:
        case TASKS_STATUS.AUDITING:
        case TASKS_STATUS.FINISHED_LATE:
        case TASKS_STATUS.FINISHED_R:
            return { backgroundColor: "#A67A44", color: "#FFFFFF" };
        case TASKS_STATUS.CANCELED:
            return { backgroundColor: "#a0a0a0", color: "#FFFFFF" };
        default:
            return { backgroundColor: "#575757", color: "#000000" };
    }
};
export const SUPPLIER_ACCOUNT_TIERS = {
    NORMAL: 1,
    MERCHANDISING_COMPANY: 2,
};

export const MERCHANDISER_ACCOUNT_STATUS = {
    INVALID: 0,
    VALID: 1,
    INCOMPLETE: 4,
    COMPLETE: 5,
    NOT_PASS: 8,
    PASS: 9,
    REGISTERED: 10,
    INACTIVE: 11,
};

export const PROJECT_TYPES = {
    SUPPLIER_OUTSOURCE: "101",
    SUPPLIER_INSOURCE: "102",
    MP_OUTSOURCE: "103",
    OPEN_MP_INSOURCE: "104",
};

export const MERCH_CONTRACT_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    PENDING: 2,
    REJECTED: 3,
    CANCELED: 4,
    EXPIRED: 5,
};

export const CONTRACT_MERCH_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
};
