import { makeStyles } from "@material-ui/styles";
import { darken, lighten } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

const styles = makeStyles({
    root: {
        position: "relative",
        // marginTop: 20,
        width: "100%",
        maxWidth: 1920,
    },
    container: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
    },

    statusButtons: {
        margin: "30px 0",
    },

    gridItemBtn: {
        margin: "0 5px",
    },

    statusBtn: {
        cursor: "default",
    },

    contentCard: {
        width: "100%",
        // maxWidth: "1290px",
    },
    loader: {
        position: "relative",
        marginTop: "130px",
    },
    merchandiserCardContainer: {},
    cardHeader: {
        textAlign: "center",
        backgroundColor: COLORS.background,
    },

    inactiveCardHeader: {
        textAlign: "center",
        backgroundColor: "#d7d7d7",
    },

    scheduleCardHeader: {
        backgroundColor: lighten(COLORS.accent, 0.8),
    },
    headerText: {
        color: COLORS.primary,
    },
    inactiveHeaderText: {
        color: "#565656",
    },
    scheduleHeaderText: {
        color: COLORS.accent,
    },
    subheaderText: {
        color: COLORS.accent,
    },
    inactiveSubheaderText: {
        color: "#565656",
    },

    cardItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid black",
        marginBottom: "8px",
        padding: "8px",
        backgroundColor: "#fff",
        "& p": {
            margin: 0,
        },
    },
    controlsCard: {
        width: "100%",
        maxWidth: "1200px",
        margin: "10px auto",
        overflow: "visible",
        "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
            "@media (min-width: 960px)": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },

    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    filterInput: {
        width: "100%",
        height: "100%",
    },
    button: {
        width: "100%",
        height: "100%",
        backgroundColor: COLORS.submit,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
            color: "#fff",
        },
    },

    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },

    disabledBtn: {
        backgroundColor: "#c9c9c9",
        color: "#00000042",
    },

    completed: {
        border: "2px solid #000000",
        color: "#000000",
    },
    open: {
        border: "2px solid #575757",
        color: "#000000",
    },

    scheduled: { border: "2px solid #4BB543", color: "#000000" },

    noShow: { border: "2px solid #cf0404", color: "#000000" },

    underProcess: { border: "2px solid #36A2EB", color: "#000000" },

    finished: { border: "2px solid #A67A44", color: "#000000" },

    auditing: { border: "2px solid #A67A44", color: "#000000" },
    canceled: { border: "2px solid #a0a0a0", color: "#000000" },
    default: { border: "2px solid #575757", color: "#000000" },

    routeCardActions: {
        backgroundColor: COLORS.background,
        justifyContent: "center",
    },
});

export default styles;
