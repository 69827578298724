import { getCities } from "./City";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export let getOutletBranch = async (id) => {
    let outletBranch = null;
    try {
        outletBranch = await db.collection("Outlet_Branch").doc(id).get();
    } catch (error) {
        throw error;
    }

    return outletBranch;
};

export let getOutletBranches = async (IDs, options = { includeCities: false, includeOutletClasses: false }) => {
    let outletBranches = [];

    try {
        outletBranches = await Promise.all(IDs.map(getOutletBranch));
        if (options.includeCities) {
            //gather city IDs for requests
            const citiesIDs = getUniqueCitiesIDs(outletBranches);
            //get cities info
            const citiesDocs = await getCities(citiesIDs);
            //include the cities into their appropriate outlet branches
            outletBranches.forEach((ob) => (ob.cityDoc = citiesDocs.find((c) => c.id === `${ob.data().city_id}`)));
        }
    } catch (error) {
        throw error;
    }
    return outletBranches;
};

export let getOutletBranchesByCompany = async (outletID) => {
    let outletBranches = [];

    try {
        outletBranches = (await db.collection("Outlet_Branch").where("outlet_id", "==", outletID).get()).docs;
        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export const getOutletBranchesBySchedules = async (schedules) => {
    try {
        const IDs = [...new Set(schedules.map((s) => s.outlet_branch_id))];

        return await Promise.all(IDs.map((id) => getOutletBranch(id)));
    } catch (error) {
        throw error;
    }
};

export let getUniqueOutletIDFromBranches = (outletBranches) => {
    let outletIDs = [];

    for (const outletBranch of outletBranches) {
        let alreadyIncluded = outletIDs.includes(outletBranch.data().outlet_id);
        if (!alreadyIncluded) outletIDs.push(outletBranch.data().outlet_id);
    }

    return outletIDs;
};

export const getOutletBranchesFromTasks = async (tasks) => {
    try {
        const IDs = [...new Set(tasks.map((t) => `${t.outlet_branch_id}`))];
        const outletBranches = await Promise.all(IDs.map((id) => getOutletBranch(id)));

        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export const queryOutletBranches = async (options) => {
    try {
        return query(COLLECTIONS.OUTLET_BRANCH, options);
    } catch (error) {
        throw error;
    }
};
//=============******* utils *******=============//
let getUniqueCitiesIDs = (outletBranchesDocs) => {
    let citiesIDs = outletBranchesDocs.map((doc) => `${doc.data().city_id}`);
    return [...new Set(citiesIDs)];
};
