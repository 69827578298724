//Todo upgrade to modular style of firebase in all files
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore/lite";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

let config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// const hostname = window.location.hostname;
// const ip = "192.168.1.65";
// if (window.location.hostname === "localhost" || window.location.hostname === ip) {
//     config = {
//         apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//         projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//         databaseURL: `http://${hostname}:9000?bs=${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
//     };
// }

const app = firebase.initializeApp(config);
const authWorkerApp = firebase.initializeApp(config, "auth-worker");

const auth = app.auth();
const firestore = app.firestore();
const storage = app.storage();
const functions = app.functions();

//this is for not signed in users
const authWorker = authWorkerApp.auth();
const firestoreWorker = authWorkerApp.firestore();
const storageWorker = authWorkerApp.storage();

// if (window.location.hostname === "localhost" || window.location.hostname === ip) {
//     auth.useEmulator(`http://${hostname}:9099`);
//     authWorker.useEmulator(`http://${hostname}:9099`);
//     firestore.useEmulator(`${hostname}`, 8080);
//     functions.useEmulator(`${hostname}`, 5001);
//     storage.useEmulator(`${hostname}`, 9199);
// }

export { auth, authWorker, firestore, firestoreWorker, storage, storageWorker, functions, firebase, app as default };
// export const auth = getAuth(app);
// export const firestore = getFirestore(app);
