import { Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { isEmpty } from "lodash";
import React, { useEffect, useReducer } from "react";
import CustomDatePicker from "../../components-lagacy/CustomInput/CustomDatePicker";
import { AutocompleteMultiple, Button, Checkbox } from "../../core-ui/custom";
import { DateRangePicker } from "..";

//related to meterial ui package
import styles from "./styles";

const createFilterEl = (id, label, value, onChange, component = "autoComplete", componentProps = {}) => {
    switch (component) {
        case "text":
            return (
                <TextField
                    variant="outlined"
                    id={id}
                    label={label}
                    value={value}
                    onChange={(e) => onChange(id, e.target.value, e)}
                    style={{ width: "100%" }}
                    {...componentProps}
                />
            );
        case "autoComplete":
            if (componentProps.multiple)
                return (
                    <AutocompleteMultiple
                        id={id}
                        value={value}
                        onChange={(e, option) => onChange(id, option, e)}
                        options={componentProps.options}
                        optionLabelProp="label"
                        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
                        {...componentProps}
                    />
                );

            return (
                <Autocomplete
                    id={id}
                    value={value}
                    onChange={(e, option) => onChange(id, option, e)}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
                    {...componentProps}
                />
            );
        case "date":
            const rootProps = {
                id,
                label,
                value,
                onChange: (e) => onChange(id, e),
                inputVariant: "outlined",
                ...componentProps,
            };
            return <CustomDatePicker rootProps={rootProps} />;

        case "dateRange":
            return <DateRangePicker onApply={(dateRange) => onChange(id, dateRange)} ranges={[value]} />;

        case "checkbox":
            const checkboxProps = {
                label: label,
                // legend: label,
                checked: value,
                onChange: (e) => onChange(id, e),
            };
            return <Checkbox {...checkboxProps} />;
        default:
            return null;
    }
};

const filtersReducer = (oldState, { type, payload }) => {
    switch (type) {
        case "SET_FILTERS":
            return { ...oldState, ...payload };
        case "SET_FILTER_OPTIONS":
            return { ...oldState, [payload.id]: { ...oldState[payload.id], options: payload.options, value: null } };
        case "SET_FILTER_VALUE":
            return { ...oldState, [payload.id]: { ...oldState[payload.id], value: payload.value } };
        case "CLEAR_FILTERS":
            const newState = { ...oldState };
            for (const id in newState) {
                if (newState.hasOwnProperty(id)) {
                    if (newState[id].component === "date" || newState[id].component === "dateRange") continue;
                    newState[id] = { ...newState[id], value: null };
                }
            }
            return newState;
        case "CLEAR_FILTER":
            return { ...oldState, [payload.id]: { ...oldState[payload.id], value: null, options: [] } };
        default:
            return oldState;
    }
};

const Filters = ({ init, onSubmit, submitText = "Search" }) => {
    const classes = styles();
    const [filters, dispatchFilters] = useReducer(filtersReducer, {});

    const filterChangeHandler = (id, value, e) => {
        const filter = filters[id];

        if (filter.onChange) {
            const context = { id: id, filters, dispatchFilters };
            filter.onChange(value, context);
        }

        if (filter.component === "dateRange") {
            //sometimes startDate and endDate refrences to the same object
            //so we need to clone it
            value = {
                ...value,
                startDate: new Date(value.startDate),
                endDate: new Date(value.endDate),
            };
            value.startDate.setHours(0, 0, 0, 0);
            value.endDate.setHours(23, 59, 59, 999);
        }

        dispatchFilters({ type: "SET_FILTER_VALUE", payload: { id: id, value: value } });
    };

    const submitHandler = (values = {}) => {
        if (onSubmit) {
            for (const id in filters) {
                if (filters.hasOwnProperty(id)) {
                    const filter = filters[id];
                    values[id] = filter.value;
                }
            }
            const context = { dispatchFilters };
            onSubmit(values, context);
        }
    };

    const clearFilters = () => {
        for (const id in filters) {
            if (Object.hasOwnProperty.call(filters, id)) {
                const filter = filters[id];
                if (filter.onChange) {
                    const context = { id: id, filters, dispatchFilters };
                    filter.onChange(null, context);
                }
            }
        }
        dispatchFilters({ type: "CLEAR_FILTERS" });
    };

    const setFilters = (settings) => {
        //if filters are not set, set them to default. Otherwise, return
        if (!isEmpty(filters)) return;

        const newFilters = {};
        for (const id in settings) {
            if (Object.hasOwnProperty.call(settings, id)) {
                let {
                    label,
                    initialValue,
                    options,
                    onChange,
                    component,
                    gridProps,
                    componentProps = {},
                } = settings[id];

                if (!component || component === "autoComplete") {
                    componentProps = {
                        classes: {
                            option: classes.option,
                            noOptions: classes.option,
                        },
                        ...componentProps,
                    };
                }

                newFilters[id] = {
                    label: label || "",
                    value: initialValue || (component === "text" ? "" : null),
                    onChange: onChange || null,
                    component: component || "autoComplete",
                    options: options || [],
                    gridProps: gridProps || {},
                    componentProps: componentProps,
                };
            }
        }

        dispatchFilters({ type: "SET_FILTERS", payload: newFilters });
    };

    useEffect(() => {
        init({ filters, dispatchFilters, setFilters, submit: submitHandler });
    }, []);

    return (
        <div className={classes.filtersRoot}>
            <Grid container spacing={2} className={classes.gridContainer}>
                {Object.keys(filters).map((id) => {
                    const { gridProps, label, value, options, component, componentProps } = filters[id];
                    const key = `filter-input-${id}`;
                    return (
                        <Grid item key={key} xs={12} sm={4} md={3} lg={3} {...gridProps}>
                            {createFilterEl(id, label, value, filterChangeHandler, component, {
                                options: options,
                                className: classes.filter,
                                size: "small",
                                ...componentProps,
                            })}
                        </Grid>
                    );
                })}
                {Object.keys(filters).length > 0 && (
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Button size="small" className={classes.clearFiltersButton} onClick={clearFilters}>
                            Clear
                        </Button>
                        <Button size="small" className={classes.submitButton} onClick={submitHandler}>
                            {submitText}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default Filters;
