import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import styles from "../../core-ui/core-styles/pdf/general";
import logo from "../../assets/images/logo.png";
import { formatTime, formatDate } from "../../helpers/date-utils";

let rowsPerPage = 12;
let totalPages = 0;
const tableHeaders = [
    { group: false, cells: "Product No.", style: { flex: 2 } },
    { group: false, cells: "Barcode", style: { flex: 2 } },
    { group: false, cells: "Product", style: { flex: 2 } },
    { group: false, cells: "Available", style: { flex: 2 } },
    {
        group: true,
        cells: [
            [{ text: "Quantity", style: {} }],
            [
                { text: "Shelf", style: { flex: 1 } },
                { text: "E. Display", style: { flex: 2 } },
            ],
        ],
        style: { flex: 2 },
    },
    { group: false, cells: "Warehouse Draw (Carton)", style: { flex: 2 } },
    {
        group: true,
        cells: [
            [{ text: "Total Quantity", style: {} }],
            [
                { text: "Shelf", style: { flex: 1 } },
                { text: "E. Display", style: { flex: 2 } },
            ],
        ],
        style: { flex: 2 },
    },
];

let createCellGroup = (rows, style) => (
    <View key={`table-${Math.random()} group-${Math.random()}`} style={[...style, styles.cellGroup]}>
        {rows.map((cells, rowIndex) => (
            <View
                key={`table-row${rowIndex} group-${Math.random()}`}
                style={rowIndex + 1 === rows.length ? [styles.row, styles.lastRow] : styles.row}
            >
                {cells.map((cellObj, cellIndex) => (
                    <View
                        key={`table-cell${cellIndex} group-${Math.random()}`}
                        style={
                            cellIndex + 1 === cells.length
                                ? [styles.cell, styles.lastCell, cellObj.style]
                                : [styles.cell, cellObj.style]
                        }
                    >
                        <View style={{ height: "20px", display: "flex", justifyContent: "center" }}>
                            <Text>{cellObj.text}</Text>
                        </View>
                    </View>
                ))}
            </View>
        ))}
    </View>
);

let createRow = (row, style = styles.row) => {
    return (
        <View style={style} key={`table Row ${Math.random()}`}>
            {row.cells.map((cellObj, index) => {
                const cellStyle =
                    index + 1 === tableHeaders.length
                        ? [styles.cell, styles.lastCell, cellObj.style]
                        : [styles.cell, cellObj.style];
                if (cellObj.group) return createCellGroup(cellObj.cells, cellStyle);
                else
                    return (
                        <View key={`table-row-${index} single`} style={cellStyle}>
                            <Text>{cellObj.cells}</Text>
                        </View>
                    );
            })}
        </View>
    );
};

let createTableHeader = () => {
    return (
        <View style={styles.tableHeader}>
            <View style={[styles.row, styles.headerRow]}>
                {tableHeaders.map((headerObj, index) => {
                    const style =
                        index + 1 === tableHeaders.length
                            ? [styles.cell, styles.lastCell, headerObj.style]
                            : [styles.cell, headerObj.style];
                    if (headerObj.group) return createCellGroup(headerObj.cells, style);
                    else
                        return (
                            <View key={`table-header-${index} single`} style={style}>
                                <Text>{headerObj.cells}</Text>
                            </View>
                        );
                })}
            </View>
        </View>
    );
};

let createPage = (name, mpName, info, rows, exportedBy, pageNum) => {
    let rowsToDisplay = rows.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`PDF ${Math.random()}`}>
            {/* Page Header */}
            <View style={styles.logoContainer}>
                {/* <Image style={styles.logo} src={logo} /> */}
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <Text style={styles.title}>Merchandiser Daily Report</Text>
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    {/* <Text style={styles.supplierText}>Supplier</Text> */}
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Supplier: <Text style={styles.bold}>{name}</Text>
                    </Text>
                    {/* <Text>
                        Merchandising Provider: <Text style={styles.bold}>{mpName}</Text>
                    </Text> */}
                    <Text>
                        Project No.: <Text style={styles.bold}>{info.projectId}</Text>
                    </Text>
                    <Text>
                        Visit No.: <Text style={styles.bold}>{info.taskId}</Text> <Text style={styles.red}>|</Text>{" "}
                        Visit Date: <Text style={styles.bold}>{formatDate(info.visitDate)}</Text>{" "}
                        <Text style={styles.red}>|</Text> Visit Day: <Text style={styles.bold}>{info.visitDay}</Text>
                    </Text>
                    <Text>
                        City: <Text style={styles.bold}>{info.city}</Text> <Text style={styles.red}>|</Text> Outlet
                        Branch: <Text style={styles.bold}>{info.outletBranch}</Text>
                    </Text>
                </View>
            </View>

            {/* Page Body */}
            <View style={styles.table}>
                {/* Table header */}
                {createTableHeader()}

                {/* Table Body */}
                <View style={styles.tableBody}>
                    {rowsToDisplay.map((row, index) => {
                        let style = [styles.row];
                        if (index === rowsToDisplay.length - 1) style.push(styles.lastRow);
                        if (index % 2 === 0) style.push(styles.coloredRow);

                        return createRow(row, style);
                    })}
                </View>
            </View>

            {/* Page Footer */}
            <View style={styles.footer}>
                <Text>Issue Date: {formatDate(new Date())}</Text>
                <Text>Issue Time: {formatTime(new Date())}</Text>
                <Text>Issued By: {exportedBy}</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                {/* <Text style={styles.logoFooterText}>Murattab</Text> */}
            </View>
        </Page>
    );
};

let setupRows = (rowsData) => {
    let rows = [];
    for (const rowData of rowsData) {
        let row = {};
        row.cells = [
            { group: false, cells: rowData.product.code, style: { flex: 2 } },
            { group: false, cells: rowData.product.barcode, style: { flex: 2 } },
            { group: false, cells: rowData.product.name, style: { flex: 2 } },
            {
                group: false,
                cells:
                    rowData.shelfQuantity !== 0 ||
                    rowData.displayQuantity !== 0 ||
                    rowData.shelfTotal !== 0 ||
                    rowData.displayTotal !== 0
                        ? "Yes"
                        : "No",
                style: { flex: 2 },
            },
            {
                group: true,
                cells: [
                    [
                        { text: rowData.shelfQuantity !== 0 ? rowData.shelfQuantity : "-", style: { flex: 1 } },
                        { text: rowData.displayQuantity !== 0 ? rowData.displayQuantity : "-", style: { flex: 2 } },
                    ],
                ],
                style: { flex: 2 },
            },
            { group: false, cells: rowData.warehouseDraw !== 0 ? `${rowData.warehouseDraw}` : "-", style: { flex: 2 } },
            {
                group: true,
                cells: [
                    [
                        { text: rowData.shelfTotal !== 0 ? rowData.shelfTotal : "-", style: { flex: 1 } },
                        { text: rowData.displayTotal !== 0 ? rowData.displayTotal : "-", style: { flex: 2 } },
                    ],
                ],
                style: { flex: 2 },
            },
        ];
        rows.push(row);
    }

    return rows;
};

let createDoc = (name, mpName, info, rowsData, exportedBy) => {
    let pages = [];
    let rows = setupRows(rowsData);
    totalPages = Math.ceil(rows.length / rowsPerPage);
    for (let pageNum = 0; pageNum < totalPages; pageNum++) {
        pages[pageNum] = createPage(name, mpName, info, rows, exportedBy, pageNum);
    }

    return pages;
};

// Create Document Component
const MDRPdf = (props) => {
    let document = createDoc(props.name, props.mpName, props.info, props.rows, props.exportedBy);

    return <Document>{document}</Document>;
};

export default MDRPdf;
