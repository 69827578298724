const COLORS = {
    PRIMARY: "#602f92",
    SUBMIT: "#4BB543",

    WHITE: "#fff",
    BLACK: "#000",
    EMINENCE: "#602f92",
    LIGHT_EMINENCE: "#e6e3f4",
    FADED_PURPLE: "#896F9F",
    PUMPKIN_ORANGE: "#F27121",
    VALENTINE_RED: "#E94057",

    PRIMARY: "#91041C",
    ACCENT: "#DB715A",
    BACKGROUND: "#F5F9FF",

    GRADIANT_1: "linear-gradient( 136deg, #f27121 0%, #e94057 50%, #602f92 100%)",

    NAV_COLOR_1: "#100e33",
    NAV_COLOR_2: "#d6c4f1",
    NAV_COLOR_3: "#DB715A",
    NAV_COLOR_4: "#7a39dc",

    SUBMIT: "#4BB543",
    CANCEL: "#91041C",
};

export default COLORS;
