import React, { useCallback } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Header, LoadingOverlay } from "../../core-ui/custom";
import { Form, Formik } from "formik";
import {
    DEFAULT_VALUES,
    SCHEMA,
    ACCOUNT_STATUS,
    GENDER_LIST,
    processFormData,
} from "../../services/forms/add-supervisor";
import { Card, Grid } from "@material-ui/core";
import {
    Autocomplete,
    DateTimePicker,
    FileUpload,
    FormHeader,
    PasswordField,
    SubmitButton,
    TextField,
} from "../../core-ui/forms-ui";
import { useState } from "react";
import { useEffect } from "react";
import { getAllCities } from "../../services/firestore/City";
import { COUNTRY_LIST } from "../../constants/countries";
import { useAuth } from "../../contexts/auth-context";
import { getBranchesByMPID } from "../../services/firestore/MP_Branch";
import { isEmailExist } from "../../helpers/firebase-helpers";

const AddSupervisor = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [formValues, setFormValues] = useState(DEFAULT_VALUES);
    const [cities, setCities] = useState([]);
    const [branches, setBranches] = useState([]);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);

    const processForm = (values) => {
        return {
            ...values,
        };
    };

    const handleSubmit = async (values, formik) => {
        try {
            const { email } = values;

            const exist = await isEmailExist(email);
            if (exist) {
                formik.setFieldError("email", "This Email Already Exist");
                return;
            }

            //process form data to get suitlable format for firestore
            const processedValues = processFormData(values);
        } catch (error) {
            console.log(error);
        }
    };

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;
            let [cities, branches] = await Promise.all([getAllCities(), getBranchesByMPID(company_id)]);

            cities = cities.map((city) => ({
                label: city.data().En_name,
                value: city.data().city_id,
            }));

            branches = branches.map((branch) => ({ label: branch.data().En_name, value: branch.id }));

            if (cities.length <= 0) throw new Error("No cities found");
            if (branches.length <= 0) throw new Error("No branches found");

            setCities(cities);
            setBranches(branches);
            setFormValues({ ...DEFAULT_VALUES, city: cities[0], branch: branches[0] });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [companyData]);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.pageRoot}>
            <LoadingOverlay loading={loading} />
            <Formik
                initialValues={formValues || DEFAULT_VALUES}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={SCHEMA}
            >
                {(formik) => {
                    return (
                        <Form>
                            <Header>Create Supervisor Account</Header>
                            <Card className={classes.formCard}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormHeader>Account Information</FormHeader>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField name="email" label="Email*" type="email" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <PasswordField
                                            name="password"
                                            label="Password*"
                                            helperText="Must contain at least 1 lowercase, uppercase and digit. Min. length: 6 Characters"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <PasswordField name="confirm_password" label="Confirm Password*" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            disableClearable
                                            options={branches}
                                            name="branch"
                                            label="Branch*"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            disableClearable
                                            options={ACCOUNT_STATUS}
                                            name="account_status"
                                            label="Account Status"
                                        />
                                    </Grid>
                                </Grid>
                            </Card>

                            <Card className={classes.formCard}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormHeader>Personal Information</FormHeader>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField name="en_first_name" label="EN. First Name*" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField name="en_middle_name" label="EN. Middle Name" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField name="en_last_name" label="EN. Last Name*" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField name="ar_last_name" label="AR. Last Name*" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField name="ar_middle_name" label="AR. Middle Name" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField name="ar_first_name" label="AR. First Name*" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6}>
                                        <DateTimePicker name="birth_date" label="Birth Date*" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            disableClearable
                                            options={GENDER_LIST}
                                            name="gender"
                                            label="Gender*"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField name="phone" label="Phone*" type="tel" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            disableClearable
                                            options={COUNTRY_LIST}
                                            name="nationality"
                                            label="Nationality*"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete disableClearable options={cities} name="city" label="City*" />
                                    </Grid>
                                </Grid>
                            </Card>

                            <Card className={classes.formCard}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormHeader>Health Card Information</FormHeader>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField name="health_card_number" label="Card Number*" type="number" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <DateTimePicker name="health_card_expiry_date" label="Expiry Date*" />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FileUpload name="health_card_image" label="Card Image*" />
                                    </Grid>
                                </Grid>
                            </Card>

                            <SubmitButton className={classes.submitButton} submitting={isSubmitting}>
                                Create
                            </SubmitButton>
                        </Form>
                    );
                }}
            </Formik>
        </section>
    );
};

AddSupervisor.propTypes = {
    classes: PropTypes.object,
};

export default AddSupervisor;
