import { alpha, makeStyles } from "@material-ui/core";
import COLORS from "../../constants/colors";
import panelBackground from "../../assets/images/background1.jpg";

const flexCentered = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

export default makeStyles({
    SignupPage: {
        width: "100%",
        height: "100vh",
    },

    container: {
        width: "100%",
        height: "100%",
    },

    formSection: {
        ...flexCentered,
        alignItems: "flex-start",
        position: "relative",
        width: "100%",
        height: "auto",
        padding: "0px 20px",
    },

    input: {
        margin: "8px 0",
    },

    signinLinkSection: {
        ...flexCentered,
        width: "100%",
        height: "auto",
        padding: "150px 20px",
        backgroundColor: COLORS.EMINENCE,
        backgroundImage: `linear-gradient(315deg, ${alpha(COLORS.EMINENCE, 0.8)} 0%, ${alpha(
            COLORS.FADED_PURPLE,
            0.8
        )} 100%), url(${panelBackground})`,
        textAlign: "center",
        color: COLORS.WHITE,
        textShadow: `1px 1px 2px ${COLORS.BLACK}`,
    },

    signinBtn: {
        color: "black",
        backgroundColor: "#fff",
        textTransform: "none",
        fontWeight: 600,
        padding: "15px 50px",
        transition: "color 0.5s, box-shadow 0.5s",
        boxShadow: `0 2px 2px ${COLORS.BLACK}`,

        "&:hover": {
            backgroundColor: "#fff",
            color: COLORS.EMINENCE,
            boxShadow: "none",
        },
    },

    "@media screen and (min-width: 640px)": {
        container: {
            display: "flex",
        },
        formSection: {
            flex: 1,
            height: "100%",
        },
        signinLinkSection: {
            flex: 1,
            height: "100%",
            maxWidth: "280px",
        },
    },
    "@media screen and (min-width: 736px)": {
        signinLinkSection: {
            maxWidth: "340px",
        },
    },
    "@media screen and (min-width: 1200px)": {
        signinLinkSection: {
            maxWidth: "480px",
        },
    },
    "@media screen and (min-width: 1400px)": {
        signinLinkSection: {
            maxWidth: "540px",
        },
    },
});
