import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

export default makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.EMINENCE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.EMINENCE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.EMINENCE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.EMINENCE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.EMINENCE,
            },
        },

        "& .MuiOutlinedInput-adornedEnd": {
            paddingRight: 0,
        },
    },

    uploadButton: {
        padding: "0px",
        // height: "24px",
    },

    cancelButton: {
        minWidth: "24px",
        padding: "0 12px 0 0",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});
