export const COLLECTIONS = {
    LOG: "Log",
    OUTLET: "Outlet",
    OUTLET_BRANCH: "Outlet_Branch",
    USER: "User",
    SUBSCRIPTION: "Subscription",
    SUBSCRIPTION_SLOT: "Slot",
    PROJECT: "Project",
    PROJECT_BRANCH: "Branch_Outlet_Product",
    PROJECT_OUTLET_BRANCH: "Outlet_Product",
    PROJECT_SCHEDULE: "Schedule",
    PROJECT_PRODUCT: "Product",
    MERCHANDISER: "Merchandiser",
    SUPERVISOR: "Supervisor",
    TASK: "Task",
    TASK_PRODUCT: "Task_Product",
    TASK_DETAILS: "Task_Details",
    VIDEO_TUTORIALS: "Video_Tutorials",
    TERMS: "Terms_And_Conditions",
    TERMS_AGREEMENTS: "Terms_And_Conditions_Agreements",
    PRIVACY_AGREEMENTS: "Privacy_Policies_Agreements",
    PRIVACY: "Privacy_Policies",
};
