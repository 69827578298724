import { flatten } from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { Filters } from "../../components";
import { useAuth } from "../../contexts/auth-context";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { CircularLoader, Header } from "../../core-ui/custom";
import { formatDate, getToday } from "../../helpers/date-utils";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { getLinkedOutletBranchesBySupplierID } from "../../services/firestore/Branch_Outlet_Branch";
import { getAllCities } from "../../services/firestore/City";
import { getSupplierMerchandisers } from "../../services/firestore/Merchandiser";
import { getOutletBranches, getOutletBranchesByCompany } from "../../services/firestore/Outlet_Branch";
import { getProducts, getSupplierProducts } from "../../services/firestore/Product";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { queryTasks_V2 } from "../../services/firestore/Task";
import { getLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import { queryTaskDetails } from "../../services/firestore/Task_Details";
import { getMerchandiserName } from "../../helpers/merchandiser-utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Button } from "@material-ui/core";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import DamageAndExpiryReportPdf from "../../components-lagacy/PDF/DamageAndExpiryReport";
import { generatePDFinNewTab } from "../../helpers/pdf-utils";

//related to meterial ui package
import styles from "./styles";
import { getLinkedSuppliers } from "../../services/firestore/MP_Supplier_Link";

const INITIAL_FILTERS = {
    startDate: {
        label: "Start Date",
        component: "date",
        initialValue: getToday(),
    },
    endDate: {
        label: "End Date",
        component: "date",
        initialValue: getToday(),
    },
    cities: {
        label: "City",
        options: [],
        initialValue: null,
    },
    supplierBranches: {
        label: "Supplier Branch",
        options: [],
        initialValue: null,
    },
    outlets: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranches: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
    // merchandisers: {
    //     label: "Merchandiser",
    //     options: [],
    //     initialValue: null,
    // },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    cities: [],
    // merchandisers: [],
    outlets: [],
    supplierBranches: [],
    outletBranches: [],
    linkedOutletBranches: [],
};

const ExpiryAndDamageReport = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    // states
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [mainTasks, setMainTasks] = useState([]);
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(getToday());
    const [isClicked, setIsClicked] = useState(false);
    const [pdfRows, setPdfRows] = useState([]);
    let counter = 0;

    const init = async () => {
        try {
            const { company_id } = companyData;
            // load filters options
            const [products, merchandisers, cities, supplierBranches, outlets] = (
                await Promise.all([
                    getSupplierProducts(company_id),
                    getSupplierMerchandisers(company_id),
                    getAllCities(),
                    getBranchesBySupplierID(company_id),
                    getLinkedOutlets(company_id),
                ])
            ).map((docs) => docs.map((doc) => doc.data()));

            const linkedOutletBranches = await getLinkedOutletBranchesBySupplierID(company_id);
            //flatten linked outlet branches
            const outletBranches = flatten(
                linkedOutletBranches.map((branch) => branch.outletBranches.map((docs) => docs.data()))
            );

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    // merchandisers: merchandisers.map((m) => ({ value: m.uid, label: getMerchandiserName(m) })),
                    cities: cities.map((c) => ({ value: `${c.city_id}`, label: c.En_name })),
                    supplierBranches: supplierBranches.map((sb) => ({ value: sb.branch_id, label: sb.En_name })),
                    outlets: outlets.map((o) => ({ value: o.outlet_id, label: o.En_short_name })),
                    linkedOutletBranches: outletBranches,
                },
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    };

    const search = async (values) => {
        setPdfRows([]);
        try {
            setLoading(true);
            // const { startDate, endDate, cities, supplierBranches, outlets, outletBranches, merchandisers } = values;
            const { startDate, endDate, cities, supplierBranches, outlets, outletBranches } = values;
            const startofDate = new Date(startDate);
            const endofDate = new Date(endDate);
            startofDate.setHours(0, 0, 0, 0);
            endofDate.setHours(23, 59, 59, 999);
            const query = [
                { key: "date_time_from", operator: ">=", value: startofDate },
                { key: "date_time_from", operator: "<=", value: endofDate },
                { key: "mp_id", operator: "==", value: companyData.company_id },
                { key: "state", operator: "in", value: [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.FINISHED] },
            ];

            if (cities?.value) query.push({ key: "city_id", operator: "==", value: cities.value });
            if (supplierBranches?.value)
                query.push({ key: "supplier_branch_id", operator: "==", value: supplierBranches.value });
            if (outlets?.value) query.push({ key: "outlet_id", operator: "==", value: outlets.value });
            if (outletBranches?.value)
                query.push({ key: "outlet_branch_id", operator: "==", value: outletBranches.value });
            // if (merchandisers?.value) query.push({ key: "uid", operator: "==", value: merchandisers.value });

            let mainTasks = (await queryTasks_V2(query)).map((doc) => doc.data());
            mainTasks = await Promise.all(
                mainTasks.map(async (task) => {
                    const taskDetailsQuery = [
                        {
                            key: "task_id",
                            operator: "==",
                            value: task.task_id,
                        },
                    ];

                    let taskDetails = (await queryTaskDetails(taskDetailsQuery)).map((doc) => doc.data());
                    task.taskDetails = taskDetails;
                    return task;
                })
            );

            //unique outlet branch ids
            let outletBranchIds = mainTasks.map((task) => `${task.outlet_branch_id}`);
            outletBranchIds = [...new Set(outletBranchIds)];
            const outletBranchesList = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());

            //unique product ids
            let allTaskDetails = mainTasks.reduce((prev, curr) => {
                prev = [...prev, ...curr.taskDetails];
                return prev;
            }, []);
            let productIds = allTaskDetails.map((taskDetail) => taskDetail.product_id);
            productIds = [...new Set(productIds)];
            const products = (await getProducts(productIds)).map((doc) => doc.data());

            //assign product info and outlet branch info into their appropriate place
            mainTasks.forEach((task) => {
                //outlet branch
                const outletBranch = outletBranchesList.find((ob) => ob.branch_id === `${task.outlet_branch_id}`);
                task.outletBranch = outletBranch;

                //products
                task.taskDetails.forEach((td) => {
                    const product = products.find((prod) => prod.product_id === td.product_id);
                    td.product = product;
                });
            });

            // let supplierProducts = (await getSupplierProducts(companyData.company_id)).map((doc) => doc.data());

            let allLinkedSupplier = await getLinkedSuppliers(companyData.company_id);

            let supplierProducts = await Promise.all(
                allLinkedSupplier.map(async (supplier) => {
                    let products = (await getSupplierProducts(supplier.id)).map((doc) => doc.data());
                    return products;
                })
            );

            supplierProducts = flatten(supplierProducts);
            const rows = mainTasks.forEach((task) => {
                let productTasks = supplierProducts.filter((product) => {
                    let detail = task.taskDetails.find((sb) => `${sb.product_id}` === `${product.product_id}`);
                    return detail;
                });
                productTasks.map((product) => {
                    const newTask = { ...task };
                    //init properties for tables
                    newTask.damageQty = 0;
                    newTask.expiredQty = 0;
                    newTask.outletBranchName = task.outletBranch;
                    newTask.productName = product.En_name;
                    newTask.barcode = product.barcode;
                    newTask.packing = `${product.outer_unit <= 1 ? "" : product.outer_unit + "x"}${
                        product.packing.no_units <= 1 ? "" : product.packing.no_units + "x"
                    }${product.packing.size}${product.packing.size_unit}`;
                    newTask.remarks = task.remarks === null ? "-" : task.remarks;
                    let productTaskDetails = newTask.taskDetails.filter(
                        (sb) => `${sb.product_id}` === `${product.product_id}`
                    );
                    for (const ptd of productTaskDetails) {
                        newTask.damageQty += ptd.removed_from.damaged || 0;
                        newTask.expiredQty += ptd.removed_from.expired || 0;
                    }
                    if (newTask.damageQty != 0 || newTask.expiredQty != 0) {
                        pdfRows.push(newTask);
                    }
                    return newTask;
                });
            });
            pdfRows.sort(function (a, b) {
                if (a.outletBranch.En_short_name < b.outletBranch.En_short_name) {
                    return -1;
                }
                if (a.outletBranch.En_short_name > b.outletBranch.En_short_name) {
                    return 1;
                }
                return 0;
            });
            setPdfRows(pdfRows);
            setMainTasks(mainTasks);
            setStartDate(startDate);
            setEndDate(endDate);

            setIsClicked(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const handleOutletsChange = async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranches" },
                });
                return;
            }

            let outletBranches = (await getOutletBranchesByCompany(option.value)).map((doc) => doc.data());
            //filter out unlinked outlet branches
            outletBranches = outletBranches.filter((ob) =>
                dataSources.linkedOutletBranches.find((lob) => lob.branch_id === ob.branch_id)
            );

            outletBranches = outletBranches.map((ob) => ({ value: ob.branch_id, label: ob.En_short_name }));

            dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: outletBranches } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranches", options: outletBranches },
            });
        } catch (error) {
            throw error;
        }
    };

    const initFilters = (context) => {
        INITIAL_FILTERS.cities.options = dataSources.cities;
        INITIAL_FILTERS.supplierBranches.options = dataSources.supplierBranches;
        INITIAL_FILTERS.outlets.options = dataSources.outlets;
        // INITIAL_FILTERS.merchandisers.options = dataSources.merchandisers;
        INITIAL_FILTERS.outlets.onChange = handleOutletsChange;

        context.setFilters(INITIAL_FILTERS);
    };

    useEffect(() => {
        init();
    }, []);

    const handleDamageAndExpiryReportPdf = () => {
        generatePDFinNewTab(
            <DamageAndExpiryReportPdf
                info={{
                    companyData: companyData,
                    pdfRows: pdfRows,
                    startDate: startDate,
                    endDate: endDate,
                }}
            />,
            `Damage and Expiry Report`
        );
    };
    const handleDamageAndExpiryReportExcel = () => {};

    return (
        <section className={classes.sectionContainer}>
            <Header className={classes.reportHeader}>
                <span className={classes.headerColor}>Expiry and Damage Report</span>
            </Header>
            {!initializing && <Filters init={initFilters} onSubmit={search} />}
            {initializing || loading ? <CircularLoader className={classes.alignCenter} /> : null}
            {isClicked ? (
                <div className={classes.container}>
                    <div className={classes.topContainer}>
                        <div className={classes.dateContainer}>
                            Date from: <span className={classes.boldFont}>{formatDate(startDate)}</span>
                            <br></br>
                            Date to: <span className={classes.boldFont}>{formatDate(endDate)}</span>
                        </div>
                        <div className={classes.alignRight}>
                            <Button
                                className={classes.pdfButton}
                                title="print pdf"
                                onClick={handleDamageAndExpiryReportPdf}
                            >
                                <div className={classes.pdfIcon}>
                                    <PictureAsPdfIcon />
                                </div>
                            </Button>
                            {/* <Button className={classes.pdfButton} title="export Excel" onClick={handleDamageAndExpiryReportExcel}>
                                <div className={classes.excelIcon}>
                                    <ListAltSharpIcon />
                                </div>
                            </Button> */}
                        </div>
                    </div>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.headerRow}>
                                <TableCell rowSpan={2} className={classes.indexheader}></TableCell>
                                <TableCell rowSpan={2} className={[classes.header, classes.outletHeader]}>
                                    Outlet Name
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={3} className={[classes.header, classes.productHeader]}>
                                    Product
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={2} className={[classes.header, classes.stockHeader]}>
                                    Stock Position
                                </TableCell>
                                <TableCell rowSpan={2} className={[classes.header, classes.remarksHeader]}>
                                    Remarks
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.headerRow}>
                                <TableCell className={[classes.secondHeader, classes.barcodeHeader]}>Barcode</TableCell>
                                <TableCell className={[classes.secondHeader, classes.discHeader]}>
                                    Discription
                                </TableCell>
                                <TableCell className={[classes.secondHeader, classes.packingHeader]}>Packing</TableCell>
                                <TableCell className={[classes.secondHeader, classes.qtyHeader]}>Dmg</TableCell>
                                <TableCell className={[classes.secondHeader, classes.qtyHeader]}>Exp &nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pdfRows.map((row, index) => {
                                if (row.damageQty != 0 || row.expiredQty != 0 || row.nearExpiry != 0) {
                                    counter++;
                                    return (
                                        <>
                                            <TableRow
                                                key={`tdr-${index}`}
                                                className={index % 2 === 0 ? classes.rowEven : classes.row}
                                            >
                                                <TableCell className={classes.indexCell}>{counter}</TableCell>
                                                <TableCell className={classes.outletCell}>
                                                    {row.outletBranchName.En_short_name}
                                                </TableCell>
                                                <TableCell>{row.barcode}</TableCell>
                                                <TableCell className={classes.alignLeft}>{row.productName}</TableCell>
                                                <TableCell>{row.packing}</TableCell>
                                                <TableCell className={classes.qtyCell}>{row.damageQty}</TableCell>
                                                <TableCell className={classes.qtyCell}>{row.expiredQty}</TableCell>
                                                <TableCell className={classes.alignLeft}>{row.remarks}</TableCell>
                                            </TableRow>
                                        </>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div></div>
            )}
        </section>
    );
};

export default ExpiryAndDamageReport;
