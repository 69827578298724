import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    galleryItemDialog: {
        width: "100%",
    },

    productName: {
        fontSize: "1.15rem",
        fontWeight: "bold",
    },
    displayTypeAndLocation: {
        fontSize: "0.85rem",
        color: COLORS.PUMPKIN_ORANGE,
    },

    supermarket: {
        color: COLORS.BLACK,
        fontWeight: "light",
    },
    task: {
        fontSize: "0.85rem",
    },

    imageHeader: {
        fontSize: "0.9rem",
        fontWeight: "bold",
    },

    imageContainer: {
        "& img": {
            height: "250px",
            width: "100%",
        },
    },

    dialogActions: {
        padding: "8px 24px",
        justifyContent: "flex-start",
    },

    visitDetailsBtn: {
        fontSize: "0.9rem",
        backgroundColor: "transparent",
        color: COLORS.EMINENCE,
        border: "1px solid",
        borderColor: COLORS.EMINENCE,
        borderRadius: "4px",
        "&:hover": {
            color: COLORS.WHITE,
        },
    },
});

export default styles;
