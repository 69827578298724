import { PLATFORM } from "../../constants/global";
import { serverTimestamp } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { getUser } from "./User";

export const logSignIn = async (userID, position) => {
    try {
        //if permission is denied or not loaded
        if (!position) return false;

        const user = (await getUser(userID)).data();
        if (!user) return false;
        const name = `${user.first_name} ${user.surname}`;

        const doc = db.collection(COLLECTIONS.LOG).doc();
        return doc.set({
            id: doc.id,
            account_type: "service provider admin",
            action: "sign in",
            page: "/sign-in",
            platform: PLATFORM,
            platform_type: "service provider",
            timestamp: serverTimestamp(),
            uid: userID,
            user_name: name,
            company_id: user.company_id,
            company_type: user.company_type,

            address: position.address,
            city: position.address_components[3]?.long_name,
            coordinates: {
                lat: position.lat,
                lng: position.lng,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const logSignOut = async (userID, position, page) => {
    try {
        //if permission is denied or not loaded
        if (!position) return false;

        const user = (await getUser(userID)).data();
        const name = `${user.first_name} ${user.surname}`;

        const doc = db.collection(COLLECTIONS.LOG).doc();
        return doc.set({
            id: doc.id,
            account_type: "service provider admin",
            action: "sign out",
            page: page,
            platform: PLATFORM,
            platform_type: "service provider",
            timestamp: serverTimestamp(),
            uid: userID,
            user_name: name,
            company_id: user.company_id,
            company_type: user.company_type,

            address: position.address,
            city: position.address_components[3]?.long_name,
            coordinates: {
                lat: position.lat,
                lng: position.lng,
            },
        });
    } catch (error) {
        throw error;
    }
};
