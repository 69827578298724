import { flatten } from "lodash/fp";
import { EMPLOYEE_TYPES } from "../../constants/global";
import { get } from "../../helpers/object-utils";
import { firestore as db } from "../firebase";
import { getBranchesByMPID } from "./MP_Branch";
import { getSupplierBranchInfo } from "./Supplier_Branch";

export const getMerchandiser = async (merchandiserId) => {
    try {
        return await db.collection("Merchandiser").doc(merchandiserId).get();
    } catch (error) {
        throw error;
    }
};

export let getMPMerchandisers = async (mpID) => {
    let merchandisers = [];

    try {
        //* get merchandisers
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.owner_id", "==", mpID)
                // .where("account_information.type", "==", "mp_employee")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return merchandisers;
};

export let getSupplierMerchandisers = async (supplierID) => {
    let merchandisers = [];

    try {
        //* get merchandisers
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.owner_id", "==", supplierID)
                .where("account_information.type", "==", "mp_employee")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return merchandisers;
};

export let getMerchandisersBySupplierBranch = async (supplierBranchID) => {
    let merchandisers = [];
    try {
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.supplier_branch_id", "==", supplierBranchID)
                .get()
        ).docs;

        return merchandisers;
    } catch (error) {
        throw error;
    }
};

export let getSupervisorsByMPBranch = async (mpBranchID) => {
    let supervisors = [];
    try {
        supervisors = (
            await db
                .collection("Merchandiser")
                .where("account_information.mp_branch_id", "==", mpBranchID)
                .where("account_information.type", "==", EMPLOYEE_TYPES.MP_SUPERVISOR)
                .get()
        ).docs;

        return supervisors;
    } catch (error) {
        throw error;
    }
};

export let getMerchandisersByMPBranch = async (MPBranchID) => {
    let merchandisers = [];
    try {
        merchandisers = (
            await db
                .collection("Merchandiser")
                .where("account_information.mp_branch_id", "==", MPBranchID)
                .where("account_information.type", "==", "mp_employee")
                .get()
        ).docs;

        return merchandisers;
    } catch (error) {
        throw error;
    }
};

export const getMerchandisersListByTask = async (task) => {
    try {
        const mpID = task.mp_id;
        const supplierBranchID = `${task.supplier_branch_id}`;
        const supplierBranch = await getSupplierBranchInfo(supplierBranchID);
        const city_id = supplierBranch.data().city_id;
        let mpBranches = await getBranchesByMPID(mpID);
        mpBranches = mpBranches.filter((branch) => branch.data().city_id === city_id);
        let result = await Promise.all(mpBranches.map(async (br) => getMerchandisersByMPBranch(br.id)));

        return flatten(result);
    } catch (error) {
        throw error;
    }
};

export const updateMerchandiser = async (merchandiserID, data) => {
    try {
        return await db.collection("Merchandiser").doc(merchandiserID).update(data);
    } catch (error) {
        throw error;
    }
};

export const getMerchandisersFromTasks = async (tasks, savedMerchandisers = []) => {
    try {
        // get unique uid from tasks
        let uids = [...new Set(tasks.map((task) => task.uid))];
        // filter uids which have new merchandisers
        uids = uids.filter((uid) => {
            if (!uid) return false;
            const isSavedMerchandiser = savedMerchandisers.find((merchandiser) => {
                return uid === merchandiser.uid;
            });
            return !isSavedMerchandiser;
        });

        // get merchandiser info from uids
        let newMerchandisers = await Promise.all(uids.map(async (uid) => getMerchandiser(uid)));
        //doc to data
        newMerchandisers = newMerchandisers.map((merchandiser) => merchandiser.data());
        // merge saved merchandisers with new merchandisers
        return [...savedMerchandisers, ...newMerchandisers];
    } catch (error) {
        throw error;
    }
};
//===============================================================

//########################## Utilities ##########################

//===============================================================

export let getMerchandiserName = (merchandiser) => {
    let merchName = get(merchandiser, "personal_information.name.english");
    merchName = `${merchName.first}${merchName.middle ? ` ${merchName.middle}` : ""}${
        merchName.last ? ` ${merchName.last}` : ""
    }`;
    return merchName;
};
