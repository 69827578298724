/* eslint-disable no-use-before-define */

import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutocompleteWrapper = ({ inputLabel = "", optionLabelProp = "title", ...otherProps }) => {
    return (
        <Autocomplete
            getOptionLabel={(option) => option[optionLabelProp]}
            renderInput={(params) => <TextField {...params} variant="outlined" label={inputLabel} />}
            {...otherProps}
        />
    );
};

export default AutocompleteWrapper;
