import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    addExclusivityContract: {
        padding: "15px",
    },

    headerInputCard: {
        padding: "10px",
        marginBottom: "10px",
    },

    setButton: {
        width: "100%",
        height: "100%",
        maxHeight: "56px",
    },

    merchandisersCard: {
        padding: "10px",
        marginBottom: "10px",
    },
    submitButton: {
        width: "100%",
        height: "100%",
        maxHeight: "56px",
        backgroundColor: COLORS.EMINENCE,
        textTransform: "none",
    },

    merchandiserCard: {
        padding: "10px",
        marginBottom: "10px",
    },
});

export default styles;
