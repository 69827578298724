export const PLATFORM = "emaham";
export const PLATFORM_FULL = "emaham-provider";

export const TASK_TYPES = {
    REGULAR: "regular",
    HANDOVER: "handover",
    MP_INSOURCE: "mp-insource",
    MP_OUTSOURCE: "mp-outsource",
    SUPPLIER_INSOURCE: "self-service-simple",
    SUPPLIER_INSOURCE_ELC: "self-service-elc",
};

export const WEB_USER_ACCOUNT_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
};

export const COMPANY_TYPES = {
    SUPPLIER: "supplier",
    SERVICE_PROVIDER: "mp",
    OUTLET: "outlet",
};

export const USER_TYPES = {
    ADMIN: "admin",
};

export const OUTLET_BRANCH_STATUS = {
    ACTIVE: "1",
    INACTIVE: "0",
    DELETED: "-1",
};

export const EMPLOYEE_TYPES = {
    SUPPLIER_MERCHANDISER: "supplier_employee",
    SUPPLIER_SUPERVISOR: "supplier_supervisor",
    MP_MERCHANDISER: "mp_employee",
    MP_SUPERVISOR: "mp_supervisor",
    STORE_MERCHANDISER: "store_merchandiser",
    STORE_SUPERVISOR: "store_supervisor",
    STORE_WAREHOUSE_KEEPER: "warehouse_keeper",
    FREELANCE_MERCHANDISER: "regular",
};

export const EMPLOYEE_ACCOUNT_STATUS = {
    INVALID: 0,
    VALID: 1,
    INCOMPLETE: 4,
    COMPLETE: 5,
    NOT_PASS: 8,
    PASS: 9,
    REGISTERED: 10,
    INACTIVE: 11,
};

export const EMPLOYEE_APP_STATUS = {
    RESUMED: "resumed",
    INACTIVE: "inactive",
    PAUSED: "paused",
};

export const PROJECT_TYPES = {
    SUPPLIER_OUTSOURCE: "101",
    SUPPLIER_INSOURCE: "102",
    MP_OUTSOURCE: "103",
    OPEN_MP_INSOURCE: "104",
    STORE_INSOURCE: "105",
};

export const OUTLET_SUPPLIER_LINK_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
    PENDING: "2",
    REJECTED: "3",
};
