import React, { useState } from "react";
import styles from "./styles";

import { Collapse, Grid } from "@material-ui/core";

import {
    DailyVisitsPie,
    ExpiredDamagedProductsTable,
    Filters,
    IndicatorChart,
    InstoreTimeTable,
    MissedVisitsTable,
    NearExpiryProductsTable,
    OutOfStockBar,
    OutOfStockTable,
    OutOfStockTrendLine,
    Tabs,
    SKUExpiredAndNearExpiryLine,
    UnitsExpiredAndNearExpiryLine,
    UnderConstructionChart,
    SKUAvailabilityGauge,
    OutOfStockLine,
    OutOfStockTableDialog,
    // UnavailableSKUsBar,
} from "../../components";
import { Button, Header } from "../../core-ui/custom";

import FilterListIcon from "@material-ui/icons/FilterList";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import TimelineOutlinedIcon from "@material-ui/icons/TimelineOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useDashboard from "../../hooks/use-daahboard";
import { Responsive, WidthProvider } from "react-grid-layout";
import useDashboardLayout from "../../hooks/use-dashboard-layout";
import SoldBar from "../../components/analytics/out-of-stock-bar";

const ResponsiveGridLayout = WidthProvider(Responsive);

const TABS = [
    { label: "Operational", icon: <SettingsOutlinedIcon /> },
    { label: "Productivity", icon: <TimelineOutlinedIcon /> },
    { label: "Performance", icon: <AssessmentOutlinedIcon /> },
];

const Dashboard = () => {
    const classes = styles();

    // custom hooks
    const {
        loading,
        tasks,
        getTasks,
        taskDetails,
        outletBranches,
        merchandisers,
        filterValues,
        updateFilterValues,
        initFilters,
        setQueryParams,
        outOfStockDisplays,
        totalQty,
        getAvailabilityRate,
        getExpiredOrDamagedRate,
        getNearExpiryRate,
        getCompletedTasksRate,
        skuExpiredLine,
        skuNearExpiryLine,
        unitsExpiredLine,
        unitsNearExpiryLine,
        outOfStockShelfLine,
        outOfStockExtraDisplayLine,
        outOfStockWarehouseLine,
        outOfStockTableData,
    } = useDashboard();

    const { gridLayoutProps } = useDashboardLayout();

    const [currentTab, setCurrentTab] = useState(0);
    const [filterCollapsed, setFilterCollapsed] = useState(false);
    const [showOutOfStockTableDialog, setShowOutOfStockTableDialog] = useState(false);

    return (
        <section className={classes.dashboard}>
            {/* <h1 className={classes.pageTitle}>Dashboard</h1> */}
            <Header className={classes.header}>Dashboard</Header>

            <div className={classes.actionsContainer}>
                <Tabs
                    classes={{ root: classes.tabsRoot, tabs: classes.tabs }}
                    tabs={TABS}
                    value={currentTab}
                    onSelect={(index) => setCurrentTab(index)}
                />

                <Button
                    className={`${classes.filterBtn} ${filterCollapsed ? classes.activeFilterBtn : ""}`}
                    classes={{ label: classes.filterBtnLabel }}
                    onClick={() => setFilterCollapsed((prev) => !prev)}
                    endIcon={filterCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                    <FilterListIcon />
                </Button>
            </div>
            <Collapse in={filterCollapsed}>
                <div className={classes.filters}>
                    {/* date range filter */}
                    {/* <DateRangePicker onApply={onDateRangeApply} ranges={[dateRange]} /> */}
                    {/* Filters */}
                    <Filters init={initFilters} onSubmit={getTasks} submitText="Apply" />
                </div>
            </Collapse>

            {/* <Header className={classes.header}>Operational</Header> */}
            {currentTab === 0 && (
                <ResponsiveGridLayout {...gridLayoutProps}>
                    {/* on shelf available Dashboard Indicator */}
                    <div key="osa-indicator">
                        <IndicatorChart
                            loading={loading}
                            title="OSA"
                            value={getAvailabilityRate()}
                            type="rate"
                            color="#99e6c1"
                        />
                    </div>
                    <div key="expired-damaged-indicator">
                        <IndicatorChart
                            loading={loading}
                            title="Expired Or Damaged"
                            value={getExpiredOrDamagedRate()}
                            type="rate"
                            color="#ffa0b4"
                        />
                    </div>
                    <div key="near-expiry-indicator">
                        <IndicatorChart
                            loading={loading}
                            title="Near Expiry"
                            value={getNearExpiryRate()}
                            type="rate"
                            color="#e3c381"
                        />
                    </div>
                    <div key="compeleted-visits-indicator">
                        <IndicatorChart
                            loading={loading}
                            title="Completed Visits"
                            value={getCompletedTasksRate()}
                            type="rate"
                            color="#87b2bc"
                        />
                    </div>
                    {/* Pie Chart for tasks */}
                    <div key="tasks-pie">
                        <DailyVisitsPie tasks={tasks} loading={loading} filterValues={filterValues} />
                    </div>
                    <div key="availability-gauge">
                        <SKUAvailabilityGauge
                            loading={loading}
                            maxValue={taskDetails.length}
                            value={outOfStockDisplays.length}
                        />
                    </div>
                    {/* Out Of Stock Lines */}
                    <div key="out-of-stock-line">
                        <OutOfStockLine
                            data={[outOfStockShelfLine, outOfStockExtraDisplayLine, outOfStockWarehouseLine]}
                            colors={["#ffa0b4", "#87b2bc", "#00fe34"]}
                            tableData={outOfStockTableData}
                            loading={loading}
                            footerAction={() => setShowOutOfStockTableDialog(true)}
                            lineSettings={{
                                yScale: {
                                    type: "linear",
                                    min: 0,
                                    max:
                                        Math.max(
                                            outOfStockShelfLine.max,
                                            outOfStockExtraDisplayLine.max,
                                            outOfStockWarehouseLine.max
                                        ) < 10
                                            ? 10
                                            : Math.max(
                                                  outOfStockShelfLine.max,
                                                  outOfStockExtraDisplayLine.max,
                                                  outOfStockWarehouseLine.max
                                              ) + 5,
                                    stacked: false,
                                    reverse: false,
                                },
                            }}
                        />
                    </div>
                    {/* Freshness line chart (number of SKU) */}
                    <div key="sku-expired-near-expired-line">
                        <SKUExpiredAndNearExpiryLine
                            data={[skuExpiredLine, skuNearExpiryLine]}
                            colors={["#ffa0b4", "#e3c381"]}
                            loading={loading}
                        />
                    </div>
                    {/* Out of Stock Table */}
                    <div key="out-of-stock-table">
                        <OutOfStockTable
                            tasks={tasks}
                            taskDetails={taskDetails}
                            outletBranches={outletBranches}
                            loading={loading}
                        />
                    </div>
                    {/* Freshness line chart (number of SKU) */}
                    <div key="units-expired-near-expired-line">
                        <UnitsExpiredAndNearExpiryLine
                            data={[unitsExpiredLine, unitsNearExpiryLine]}
                            colors={["#ffa0b4", "#e3c381"]}
                            loading={loading}
                        />
                    </div>
                    {/* Expired and Damaged Table */}
                    <div key="expired-damaged-table">
                        <ExpiredDamagedProductsTable
                            tasks={tasks}
                            taskDetails={taskDetails}
                            outletBranches={outletBranches}
                            loading={loading}
                            filterValues={filterValues}
                        />
                    </div>
                    {/* near expiry table */}
                    <div key="near-expiry-table">
                        <NearExpiryProductsTable
                            tasks={tasks}
                            taskDetails={taskDetails}
                            outletBranches={outletBranches}
                            loading={loading}
                            filterValues={filterValues}
                        />
                    </div>
                    <div key="under-construction-1">
                        <UnderConstructionChart title="Share Of Shelf" body="Under Development" />
                    </div>
                    <div key="under-construction-2">
                        <UnderConstructionChart title="Promotion In-Store Or Flyer" body="Under Development" />
                    </div>
                </ResponsiveGridLayout>
            )}

            {currentTab === 1 && (
                <Grid container spacing={2} className={classes.dashboardContent}>
                    {/* <Grid item xs={12} sm={12} md={12} className={classes.chart}>
                    <UnavailableSKUsBar tasks={tasks} />
                </Grid> */}
                    <Grid item xs={12} sm={12} md={4} className={classes.chart}>
                        <MissedVisitsTable tasks={tasks} outletBranches={outletBranches} loading={loading} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                        <OutOfStockBar tasks={tasks} taskDetails={taskDetails} loading={loading} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} className={classes.chart}>
                        <InstoreTimeTable tasks={tasks} merchandisers={merchandisers} loading={loading} />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={4} className={classes.chart}>
                        <UnderConstructionChart title="Merchandiser's Performance" body="Under Development" />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <InstoreIdealTimeTable tasks={tasks} merchandisers={merchandisers} loading={loading} />
                </Grid> */}
                </Grid>
            )}

            {currentTab === 2 && (
                <Grid container spacing={2} className={classes.dashboardContent}>
                    <Grid item xs={12} sm={12} md={6} className={classes.chart}>
                        <OutOfStockTrendLine
                            tasks={tasks}
                            taskDetails={taskDetails}
                            startDate={filterValues.date.startDate}
                            endDate={filterValues.date.endDate}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.chart}>
                        <SoldBar tasks={tasks} taskDetails={taskDetails} loading={loading} />
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6} className={classes.chart}>
                    <ConsecutiveUnavailabilityTable tasks={tasks} taskDetails={taskDetails} outletBranches={outletBranches} loading={loading} />
                </Grid> */}
                </Grid>
            )}

            {/* dialogs */}
            <OutOfStockTableDialog
                loading={loading}
                open={showOutOfStockTableDialog}
                onClose={() => setShowOutOfStockTableDialog(false)}
                rows={outOfStockTableData}
            />
        </section>
    );
};

export default Dashboard;
