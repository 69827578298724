import { getBranchesBySupplierID } from "./Supplier_Branch";
import { firestore as db } from "../firebase";

export let getLinkedOutletBranches = async (supplierBranchID) => {
    let outletBranches = [];
    try {
        outletBranches = (
            await db.collection("Branch_Outlet_Branch").doc(supplierBranchID).collection("Outlet_Branch").get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return outletBranches;
};

export let getLinkedOutletBranchesBySupplierID = async (supplierID) => {
    let outletBranches = [];
    let branchesIDs = [];
    try {
        branchesIDs = (await getBranchesBySupplierID(supplierID)).map((branch) => branch.id);
        outletBranches = await Promise.all(
            branchesIDs.map(async (id) => {
                let linkedOutletBranches = await getLinkedOutletBranches(id);
                return { supplierBranchID: id, outletBranches: linkedOutletBranches };
            })
        );

        return outletBranches;
    } catch (error) {
        throw error;
    }
};
