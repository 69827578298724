import React, { useEffect, useState } from "react";
import styles from "./styles";
import LogoHeader from "../../core-ui/custom/logo-header";
import Title from "../../core-ui/custom/title";
import TitleDescription from "../../core-ui/custom/title-description";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import { MultiStepForm, SignUpStepIcon, SignUpSuccess, StepForm } from "../../components";
import {
    STEP_FOUR_VALIDATION_SCHEMA,
    STEP_ONE_VALIDATION_SCHEMA,
    STEP_THREE_VALIDATION_SCHEMA,
    STEP_TWO_VALIDATION_SCHEMA,
} from "../../constants/sign-up-validation-schemas";
import {
    Autocomplete,
    DateTimePicker,
    FileUpload,
    ImageFileButton,
    PasswordField,
    TextField,
} from "../../core-ui/forms-ui";
import { getCitiesList, getOutletsList, isEmailExist } from "../../helpers/firebase-helpers";
import { convertSignUpValuesForMerchProvider, convertSignUpValuesForUser } from "../../helpers/converters";
import { addNewAccount } from "../../services/auth";
import { LoadingOverlay } from "../../core-ui/custom";
import { addNewTermsAgreement } from "../../services/firestore/Terms_And_Conditions_Agreements";
import { addNewPrivacyAgreement } from "../../services/firestore/Privacy_Policies_Agreements";
import PATHS from "../../constants/paths";

const FORM_INITIAL_VALUES = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirm_password: "",

    // company_type: { label: "Merchandising Provider", value: "mp" },
    company_name: "",
    company_short_name_en: "",
    company_short_name_ar: "",
    company_city: { label: "Jeddah", value: "102" },
    // linked_outlet: null,
    company_location: "",
    company_website_link: "",
    company_email: "",
    company_phone_number: "",
    company_fax_number: "",
    company_logo_image: null,

    commercial_registry_number: "",
    commercial_registry_expiry_date: new Date(),
    municipal_license_number: "",
    vat_number: "",
    vat_image: null,
    commercial_registry_image: null,

    contact_first_name: "",
    contact_last_name: "",
    contact_mobile_number: "",
    contact_phone_number: "",
    contact_external_phone_number: "",
    contact_email: "",
    job_title: "",
};

// const TYPES = [
//     { label: "Merchandising Provider", value: "mp" },
//     { label: "Outlet Company", value: "outlet" },
// ];

const SignUp = () => {
    const classes = styles();
    const history = useHistory();
    const [cities, setCities] = useState([]);
    // const [outlets, setOutlets] = useState([]);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [registered, setRegistered] = useState(false);

    const init = async () => {
        try {
            let citiesList = await getCitiesList();
            // let outletsList = await getOutletsList();
            setCities(citiesList);
            // setOutlets(outletsList);
        } catch (error) {
            console.error(error.message);
        }
    };

    const goTo = (destination, state) => {
        history.push(destination);
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setLoading(true);
            const exist = await isEmailExist(values.email);
            if (exist) {
                helpers.setFieldError("email", "Email already exist");
                setLoading(false);
                return;
            }
            const companyData = convertSignUpValuesForMerchProvider(values);
            const userData = convertSignUpValuesForUser(values);

            await addNewAccount(userData, companyData);

            await addNewTermsAgreement(userData.uid);
            await addNewPrivacyAgreement(userData.uid);

            setEmail(values.email);
            setRegistered(true);
        } catch (error) {
            console.log(error.message);
        }
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <section className={classes.SignupPage}>
            <LogoHeader variant="white" />
            <section className={classes.container}>
                <div className={classes.signinLinkSection}>
                    <div className={classes.signinLinkContent}>
                        <Title>You Already Have an Account?</Title>
                        <TitleDescription>Welcome Back! Just sign in and continue your journey!</TitleDescription>
                        <Button className={classes.signinBtn} onClick={() => goTo("/sign-in")}>
                            Sign In
                        </Button>
                    </div>
                </div>
                <div className={classes.formSection}>
                    {loading && <LoadingOverlay test="Processing" />}
                    {registered ? (
                        <SignUpSuccess email={email} onClick={() => goTo("/sign-in")} />
                    ) : (
                        <MultiStepForm
                            initialValues={FORM_INITIAL_VALUES}
                            onSubmit={handleSubmit}
                            stepIcons={SignUpStepIcon}
                        >
                            {/* Account */}
                            <StepForm stepName="Account" validationSchema={STEP_ONE_VALIDATION_SCHEMA}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="first_name" label="First Name*" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="last_name" label="Last Name*" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            name="email"
                                            label="Email*"
                                            type="email"
                                            showValidationIcon
                                            validateOnChange
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField name="phone_number" label="Phone*" type="tel" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <PasswordField name="password" label="Password*" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <PasswordField name="confirm_password" label="Confirm Password*" />
                                    </Grid>
                                </Grid>
                            </StepForm>

                            {/* Company */}
                            <StepForm stepName="Company" validationSchema={STEP_TWO_VALIDATION_SCHEMA}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={3}>
                                        {/* <FileUpload name="company_logo_image" label="Company's Logo" /> */}
                                        <ImageFileButton name="company_logo_image" label="Company's Logo" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField name="company_name" label="Company Name*" />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <TextField
                                                    name="company_short_name_en"
                                                    label="Co. Short Name (English)*"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <TextField
                                                    name="company_short_name_ar"
                                                    label="Co. Short Name (Arabic)*"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Autocomplete options={cities} name="company_city" label="City*" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="company_location" label="Company's Address" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="company_website_link" label="Website's URL" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="comapny_email" label="Email" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="company_phone_number" label="Phone Number" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="company_fax_number" label="Fax Number" />
                                    </Grid>
                                </Grid>
                            </StepForm>

                            {/* CR */}
                            <StepForm stepName="Commercial Registry" validationSchema={STEP_THREE_VALIDATION_SCHEMA}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            name="commercial_registry_number"
                                            label="Commercial Registry Number"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <DateTimePicker
                                            name="commercial_registry_expiry_date"
                                            label="Commercial Registry Expiry Date"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="vat_number" label="VAT Number" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={2} className={classes.attachmentsZone}>
                                            <span>Attachments</span>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <ImageFileButton name="commercial_registry_image" label="CR Image" />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <ImageFileButton name="vat_image" label="VAT Image" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </StepForm>

                            {/* Contact */}
                            <StepForm
                                stepName="Contact"
                                // onSubmit={(values) => console.log(`contact Step values: ${values}`)}
                                validationSchema={STEP_FOUR_VALIDATION_SCHEMA}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="contact_first_name" label="First Name" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField name="contact_last_name" label="Last Name" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField name="contact_email" label="Email" type="email" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField name="contact_mobile_number" label="Mobile Number" type="tel" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField name="contact_phone_number" label="Phone Number" type="tel" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            name="contact_external_phone_number"
                                            label="External Phone Number"
                                            type="tel"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <span>
                                            By clicking submit, you agree to the{" "}
                                            <a href={PATHS.TERMS_AND_CONDITIONS} rel="noreferrer" target={"_blank"}>
                                                Terms and Conditions
                                            </a>{" "}
                                            and{" "}
                                            <a href={PATHS.PRIVACY_POLICY} rel="noreferrer" target={"_blank"}>
                                                Privacy Policy
                                            </a>
                                            .
                                        </span>
                                    </Grid>
                                </Grid>
                            </StepForm>
                        </MultiStepForm>
                    )}
                </div>
            </section>
        </section>
    );
};

export default SignUp;
