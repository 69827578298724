import { Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute, LagacyRoute, PublicRoute } from "./components";
import { AuthContextProvider } from "./contexts/auth-context";
import "./core-ui/core-styles/css/global.module.css";
import "./core-ui/core-styles/scss/material-kit-react.scss";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

import {
    SignIn,
    SignUp,
    UpdateAccountInfo,
    ForgotPassword,
    ResetPassword,
    RoutePlanManagement,
    ExclusivityContracts,
    AddExclusivityContract,
    UpdateExclusivityContract,
    AddSupervisor,
    Subscriptions,
    Dashboard,
    Gallery,
    VisitsPool,
    DailyMerchandisingReport,
    MerchandiserAttendanceReport,
    ExpiryAndDamageReport,
    NearExpiryReport,
} from "./pages";
import {
    AccountInfo,
    AddBranch,
    AddInsourceProject,
    AddMerchandiser,
    AddOutsourceProject,
    BranchesList,
    Guide,
    InsourceProjects,
    ManageOutsourceProject,
    ManageProject,
    MerchandisersList,
    OutsourceProjects,
    OutsourceVisitDetails,
    OutsourceVisitReport,
    OutsourceVisitsManagement,
    RouteVisitsManagement,
    UpdateBranch,
    UpdateMerchandiser,
    VisitDetails,
    VisitReport,
    VisitsManagement,
} from "./pages-lagacy";
import PATHS from "./constants/paths";

function App() {
    return (
        <AuthContextProvider>
            <Switch>
                <PublicRoute path={PATHS.SIGN_IN} exact>
                    <SignIn />
                </PublicRoute>
                <PublicRoute path={PATHS.SIGN_UP} exact>
                    <SignUp />
                </PublicRoute>
                <PublicRoute path={PATHS.FORGOT_PASSWORD} exact>
                    <ForgotPassword />
                </PublicRoute>
                <PublicRoute path={PATHS.RESET_PASSWORD} exact>
                    <ResetPassword />
                </PublicRoute>
                <PrivateRoute path={PATHS.UPDATE_ACCOUNT_INFO} exact>
                    <UpdateAccountInfo />
                </PrivateRoute>

                {/* Subscription */}
                <PrivateRoute path={PATHS.SUBSCRIPTIONS} exact>
                    <Subscriptions />
                </PrivateRoute>

                {/* Dashboard */}
                <LagacyRoute path={PATHS.HELP} exact>
                    <Guide />
                </LagacyRoute>
                {/* Dashboard */}
                <PrivateRoute path={PATHS.DASHBOARD} exact>
                    <Dashboard />
                </PrivateRoute>
                {/* Services */}
                <LagacyRoute path={PATHS.INSOURCE_PROJECTS} exact>
                    <InsourceProjects />
                </LagacyRoute>
                <LagacyRoute path={PATHS.ADD_INSOURCE_PROJECT} exact>
                    <AddInsourceProject />
                </LagacyRoute>
                <LagacyRoute path={PATHS.MANAGE_INSOURCE_PROJECT} exact>
                    <ManageProject />
                </LagacyRoute>
                <PrivateRoute path={PATHS.ROUTE_PLAN_MANAGEMENT} exact>
                    <RoutePlanManagement />
                </PrivateRoute>

                <LagacyRoute path={PATHS.OUTSOURCE_PROJECTS} exact>
                    <OutsourceProjects />
                </LagacyRoute>
                <LagacyRoute path={PATHS.ADD_OUTSOURCE_PROJECT} exact>
                    <AddOutsourceProject />
                </LagacyRoute>
                <LagacyRoute path={PATHS.MANAGE_OUTSOURCE_PROJECT} exact>
                    <ManageOutsourceProject />
                </LagacyRoute>

                {/* Operations */}
                <LagacyRoute path={PATHS.VISITS_MANAGEMENT} exact>
                    <VisitsManagement />
                </LagacyRoute>
                <LagacyRoute path={PATHS.VISIT_DETAILS} exact>
                    <VisitDetails />
                </LagacyRoute>
                <LagacyRoute path={PATHS.VISIT_REPORT} exact>
                    <VisitReport />
                </LagacyRoute>
                <LagacyRoute path={PATHS.OUTSOURCE_VISITS_MANAGEMENT} exact>
                    <OutsourceVisitsManagement />
                </LagacyRoute>
                <LagacyRoute path={PATHS.OUTSOURCE_VISIT_DETAILS} exact>
                    <OutsourceVisitDetails />
                </LagacyRoute>
                <LagacyRoute path={PATHS.OUTSOURCE_VISIT_REPORT} exact>
                    <OutsourceVisitReport />
                </LagacyRoute>
                <PrivateRoute path={PATHS.GALLERY} exact>
                    <Gallery />
                </PrivateRoute>
                <PrivateRoute path={PATHS.VISITS_POOL} exact>
                    <VisitsPool />
                </PrivateRoute>

                {/* Reports */}
                <PrivateRoute path={PATHS.DAILY_MERCHANDISING_REPORT} exact>
                    <DailyMerchandisingReport />
                </PrivateRoute>
                <PrivateRoute path={PATHS.MERCHANDISER_ATTENDANCE_REPORT} exact>
                    <MerchandiserAttendanceReport />
                </PrivateRoute>
                <PrivateRoute path={PATHS.EXPIRY_AND_DAMAGE_REPORT} exact>
                    <ExpiryAndDamageReport />
                </PrivateRoute>
                <PrivateRoute path={PATHS.NEAR_EXPIRY_REPORT} exact>
                    <NearExpiryReport />
                </PrivateRoute>

                <LagacyRoute path={PATHS.ROUTE_VISITS_MANAGEMENT} exact>
                    <RouteVisitsManagement />
                </LagacyRoute>
                {/* Account */}
                <LagacyRoute path={PATHS.ACCOUNT_INFO} exact>
                    <AccountInfo />
                </LagacyRoute>
                {/* Branches */}
                <LagacyRoute path={PATHS.BRANCHES} exact>
                    <BranchesList />
                </LagacyRoute>
                <LagacyRoute path={PATHS.ADD_BRANCH} exact>
                    <AddBranch />
                </LagacyRoute>
                <LagacyRoute path={PATHS.UPDATE_BRANCH} exact>
                    <UpdateBranch />
                </LagacyRoute>
                {/* Merchandisers */}
                <LagacyRoute path={PATHS.MERCHANDISERS} exact>
                    <MerchandisersList />
                </LagacyRoute>
                <LagacyRoute path={PATHS.ADD_MERCHANDISER} exact>
                    <AddMerchandiser />
                </LagacyRoute>
                <LagacyRoute path={PATHS.UPDATE_MERCHANDISER} exact>
                    <UpdateMerchandiser />
                </LagacyRoute>

                {/* Exclusivity Contracts */}
                <PrivateRoute path={PATHS.EXCLUSIVITY_CONTRACTS} exact>
                    <ExclusivityContracts />
                </PrivateRoute>
                <PrivateRoute path={PATHS.ADD_EXCLUSIVITY_CONTRACT} exact>
                    <AddExclusivityContract />
                </PrivateRoute>
                <PrivateRoute path={PATHS.UPDATE_EXCLUSIVITY_CONTRACT} exact>
                    <UpdateExclusivityContract />
                </PrivateRoute>

                {/* Supervisors */}
                <PrivateRoute path={PATHS.ADD_SUPERVISOR} exact>
                    <AddSupervisor />
                </PrivateRoute>

                <Route path="/">
                    <Redirect to={PATHS.SIGN_IN} />
                </Route>
                <Route path="*">
                    <span>No Page Was Found.</span>
                </Route>
            </Switch>
        </AuthContextProvider>
    );
}

export default App;
