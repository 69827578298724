import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

export default makeStyles({
    card: {
        // display: "flex",
        margin: "30px 0",
        height: "100%",
        minHeight: 200,
        position: "relative",

        display: "flex",
        flexDirection: "column",
    },

    tableRoot: {
        marginTop: 15,
        boxShadow:
            "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        "& .MuiTableCell-root": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
        },
    },

    stickyHeader: {
        backgroundColor: COLORS.LIGHT_EMINENCE,
    },

    merchColumn: {
        backgroundColor: lighten(COLORS.VALENTINE_RED, 0.8),
    },

    merchHeaderCell: {
        width: 50,
    },

    routeCard: {
        marginBottom: 10,
    },

    routeCardHeader: {},

    routeCardHeaderText: {
        fontSize: "0.85rem",
        fontWeight: "600",
    },

    routeCardSubHeader: {
        fontSize: "0.75rem",
        // fontWeight: "600",
    },

    scheduleCard: {
        border: "1px solid black",
        padding: 5,
        marginBottom: 5,
        backgroundColor: COLORS.WHITE,
    },

    loader: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    exclString: {
        color: "red",
        fontSize: "0.75rem",
    },
});
