import { StyleSheet } from "@react-pdf/renderer";
import "./fonts";
import { COLORS } from "../../../constants/constants-lagacy";

// Create styles
let styles = StyleSheet.create({
    page: {
        padding: "20px",
    },

    logoContainer: {
        position: "relative",
        display: "flex",
        width: "100%",
        height: "50px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },

    logo: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "50px",
        height: "100%",
        objectFit: "contain",
        objectPosition: "0",
    },

    title: {
        flex: "1",
        textAlign: "center",
        fontSize: 16,
        textDecoration: "underline",
        color: COLORS.accent,
    },

    line: {
        width: "100%",
        height: "2px",
        marginTop: "5px",
        backgroundColor: "grey",
    },

    red: {
        color: COLORS.accent,
    },

    bold: {
        fontFamily: "Roboto",
        fontWeight: "bold",
    },

    headerContainer: {
        width: "100%",
        margin: "5px 0",
        fontSize: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    left: {
        flex: 1,
        textAlign: "left",
    },
    right: {
        flex: 1,
        textAlign: "right",
    },

    table: {
        width: "100%",
        border: 1,
        fontFamily: "Roboto",
    },

    row: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        fontWeight: "bold",
    },

    lastRow: {
        borderBottom: 0,
    },

    headerRow: {
        backgroundColor: COLORS.background,
        color: COLORS.primary,
    },

    coloredRow: {
        backgroundColor: "#ffeded",
    },

    cell: {
        flex: 1,
        // height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    lastCell: {
        borderRight: 0,
    },

    leftAlignCell: {
        textAlign: "left",
    },

    cellGroup: {
        padding: 0,
        flexDirection: "column",
    },

    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    authorInfo: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 15,
        right: 0,
        textAlign: "left",
        color: "grey",
    },
    footer: {
        position: "absolute",
        bottom: 20,
        left: 20,
        fontSize: 8,
        display: "flex",
        color: "grey",
    },
});

export default styles;
