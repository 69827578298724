import React from "react";
import styles from "./styles";
import { Card, Grid } from "@material-ui/core";
import { Autocomplete, AutocompleteMultiple } from "../../../core-ui/custom";

const RoutePlanFilters = ({ loading, lists, selectedItems, listChangeHandlers }) => {
    const classes = styles();
    const { mpBranchesList, suppliersList, projectsList, merchandisersList } = lists;
    const { selectedMpBranch, selectedSuppliers, selectedProjects, selectedMerchandisers } = selectedItems;
    const { onMpBranchChange, onSuppliersChange, onProjectsChange, onMerchandisersChange } = listChangeHandlers;

    return (
        <Card className={classes.card}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={3}>
                    <Autocomplete
                        id="mp-branches-list"
                        inputLabel={"Branches"}
                        optionLabelProp={"En_name"}
                        options={mpBranchesList}
                        value={selectedMpBranch}
                        onChange={onMpBranchChange}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <AutocompleteMultiple
                        id="suppliers-list"
                        inputLabel={"Suppliers"}
                        optionLabelProp={"En_short_name"}
                        options={suppliersList}
                        value={selectedSuppliers}
                        onChange={onSuppliersChange}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <AutocompleteMultiple
                        id="projects-list"
                        inputLabel={"Projects"}
                        optionLabelProp={"projName"}
                        options={projectsList}
                        value={selectedProjects}
                        onChange={onProjectsChange}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <AutocompleteMultiple
                        id="merchandisers-list"
                        inputLabel={"merchandisers"}
                        optionLabelProp={"merch_name"}
                        options={merchandisersList}
                        value={selectedMerchandisers}
                        onChange={onMerchandisersChange}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

export default RoutePlanFilters;
