export let formatDate = (date, separator = ".") => {
    if (!date) return null;
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}${separator}${month}${separator}${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

export let dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start <= a_start && a_end <= b_end) return true; // a in b
    return false;
};

export let formatTime = (time, ampm = true) => {
    let hour = time.getHours();
    let minutes = time.getMinutes();
    let ampmText = "";

    if (ampm) {
        ampmText = hour < 12 ? "AM" : "PM";
        hour = hour % 12;
        hour = hour || 12; //if hour == 0, make it 12
    }

    //prefix 0 if needed
    hour = hour < 10 ? `0${hour}` : hour;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return ampm ? `${hour}:${minutes} ${ampmText}` : `${hour}:${minutes}`;
};

export let getToday = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
};

export let getTomorrow = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
};

export let getDayName = (weekdayIndex, fullName = true) => {
    const weekdayFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const weekdayShort = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    if (fullName) return weekdayFull[weekdayIndex];

    return weekdayShort[weekdayIndex];
};

export let convertMinutesToHoursAndMinutes = (n) => {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    let string = ``;
    if (rhours > 0) string += `${rhours} hours`;
    if (rminutes > 0) string += ` ${rminutes} minutes`;

    return string;
};

export let getRelativeDateFromToday = (after = 1) => {
    const date = getToday();
    date.setDate(date.getDate() + after);
    return date;
};

export const getDaysSinceSpecificDate = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    return diffDays;
};

export const calculateTimeDuration = (start, end) => {
    let duration = Math.round(Math.abs(end - start) / 1000 / 60);
    return duration;
};

export let timestamp = (time) => {
    let date = time.toDate();
    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
};

export const isValidDate = (date) => {
    if (!date) return false;
    //in case date is string
    date = new Date(date);
    if (Object.prototype.toString.call(date) === "[object Date]") {
        // it is a date
        if (isNaN(date)) {
            // d.getTime() or d.valueOf() will also work
            // date object is not valid
            return false;
        } else {
            // date object is valid
            return true;
        }
    } else {
        // not a date object
        return false;
    }
};
