import React, { Component } from "react";
//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

import SchedulesManagement from "../schedules-management/SchedulesManagement";
import ProjectBottomBar from "../bottom-bar/ProjectBottomBar";
//utils
import cloneDeep from "lodash/cloneDeep";
import {
    setupScheduleRows,
    checkIfADaySelected,
    getUniqueCitiesIDsFromProject,
    getUniqueOutletsIDsFromProject,
    getWorkingDaysIDs,
} from "../../../services/firestore/Project";
import ContentHeader from "../../../components-lagacy/Header/ContentHeader";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import ProjectHeaderInputs from "../header-inputs/ProjectHeaderInputs";
import { getSeasons, getVAT } from "../../../helpers/firestore-utils";
import { getSupplier } from "../../../services/firestore/Supplier";

import { getSupplierProducts } from "../../../services/firestore/Product";
import { getCities } from "../../../services/firestore/City";
import { getOutlets } from "../../../services/firestore/Outlet";
import { getExtraDisplayContracts } from "../../../services/firestore/Contract_Extra_Display";
import { getRegularPricesFromProject } from "../../../services/firestore/Price";

import { dateRangeOverlaps, getTomorrow } from "../../../helpers/date-utils";

import Swal from "sweetalert2";
import { uploadFile } from "../../../helpers/files-utils";
import { storage } from "../../../services/firebase";
import { PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { getSeasonalPrices } from "../../../services/firestore/Seasonal_Price";
import { getWeekendPrices } from "../../../services/firestore/Weekend_Price";
import { getEDRegularPrices } from "../../../services/firestore/Extra_Display_Price";
import { getSeasonalEDPrices } from "../../../services/firestore/Seasonal_Extra_Display_Price";
import { getWeekendEDPrices } from "../../../services/firestore/Weekend_Extra_Display_Price";
import { calculateOutletBranchValue } from "../../../helpers/project-price-calculator";
import ProjectPDFs from "../ProjectPDFs";

const useStyles = (theme) => ({
    contentCard: {
        position: "relative",
        width: "100%",
        marginBottom: "150px",
        // maxWidth: "1290px",
    },
});

class ProjectManagementContent extends Component {
    state = {
        user: null,
        rows: [],
        tableToolbarButtons: [],
        loading: false,
        uid: this.props.uid,

        tabIndex: 0,

        products: [],
        seasons: [],
        extraDisplayContracts: [],

        //filters
        supplierBranchesList: [{ value: "All Branches", label: "All Branches" }],
        citiesList: [{ value: "All Cities", label: "All Cities" }],
        outletsList: [{ value: "All Outlets", label: "All Outlets" }],

        //project related data
        authLetterFile: null,
        authLetterURL: null,
        authLetterFileName: "Not yet uploaded.",

        planogramFile: null,
        planogramURL: null,
        planogramFileName: "Not yet uploaded.",

        //project management data
        oldTotalVisits: {
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
        },
        totalVisits: {
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
        },

        // project: this.props.projectFullData,

        //prices
        regularPrices: [],
        seasonalPrices: [],
        weekendPrices: [],
        regularEDPrices: [],
        seasonalEDPrices: [],
        weekendEDPrices: [],
        vat: 0,

        //project value details
        discountAmount: 0,
        discountPercentage: 0,
        totalProjectValue: 0,
        totalProjectValueFinal: 0,

        //project header inputs
        isConfirmed: false,
        projectName: "",
        startDate: new Date(),
        endDate: new Date(),
        remarks: "",

        validInputs: false,
        confirmValidated: false,

        schedulesRows: [],
        supplier: null,
    };

    init = async () => {
        this.setState({ loading: true });
        try {
            let projectFullData = this.props.projectFullData;
            const projectDoc = projectFullData.doc;

            let totalVisits = this.initVisits(projectFullData);

            const {
                products,
                seasons,
                vat,
                filtersMenus,
                extraDisplayContracts,
                prices,
                extraDisplayPrices,
                supplier,
            } = await this.setupData();

            // this.loadFiltersMenus(projectFullData).then(this.populateFiltersMenus);
            //todo gather outlets in the project with their products

            //authorization letter
            let authLetterFileRef = null;
            if (projectDoc.data().authorization_letter) {
                authLetterFileRef = storage.refFromURL(projectDoc.data().authorization_letter);
            }

            // //planogram
            // let planogramFileRef = null;
            // if (projectDoc.data().planogram) {
            //     planogramFileRef = storage.refFromURL(projectDoc.data().planogram);
            // }

            //* setup schedulesRows from project Object for management
            let schedulesRows = setupScheduleRows(this.props.projectFullData);
            // console.log("schedulesRows", schedulesRows);

            const isConfirmed =
                this.props.projectType === PROJECT_TYPES.MP_OUTSOURCE
                    ? projectDoc.data().supplier_confirmation
                    : projectDoc.data().isConfirmed;

            this.setState(
                {
                    // project: projectFullData,
                    products: products || [],
                    seasons: seasons || [],
                    extraDisplayContracts: extraDisplayContracts || [],
                    ...filtersMenus,

                    totalVisits: totalVisits,

                    ...prices,
                    ...extraDisplayPrices,
                    vat: vat ? vat.data().percentage : 0,

                    //project header inputs
                    isConfirmed: isConfirmed,
                    projectName: projectDoc.data().projName,
                    startDate: projectDoc.data().date_from.toDate(),
                    endDate: projectDoc.data().date_to.toDate(),
                    remarks: projectDoc.data().remarks,
                    authLetterURL: projectDoc.data().authorization_letter,
                    authLetterFileName: authLetterFileRef ? authLetterFileRef.name : "Not yet uploaded.",
                    // planogramURL: projectDoc.data().planogram,
                    // planogramFileName: planogramFileRef ? planogramFileRef.name : "Not yet uploaded.",

                    schedulesRows: schedulesRows,

                    supplier: supplier.data(),

                    loading: false,
                },
                () => {
                    if (this.props.projectType === PROJECT_TYPES.MP_OUTSOURCE) {
                        //* calculate total prices
                        this.updateTotalPrice();
                        this.updateDiscount(projectDoc.data().discountAmount, "amount");
                    }
                    this.checkProjectIsReadyForConfirmation();
                    this.validateInputs();
                }
            );
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    setLoading = (value) => {
        this.setState({ loading: value });
    };

    setupData = async () => {
        let data = {};
        try {
            const supplierID = this.props.supplier.id;

            let requests = [
                this.initProjectRelatedData(),
                getSupplierProducts(supplierID),
                getSeasons(),
                getVAT(),
                getSupplier(supplierID),
            ];

            let [projectRelatedData, products, seasons, vat, supplier] = await Promise.all(requests);
            const { filtersMenus, extraDisplayContracts, prices, extraDisplayPrices } = projectRelatedData;

            return {
                products,
                seasons,
                vat,
                filtersMenus,
                extraDisplayContracts,
                prices,
                extraDisplayPrices,
                supplier,
            };
        } catch (error) {
            console.log(error);
        }

        return data;
    };

    initProjectRelatedData = async () => {
        let data = {};
        try {
            let projectFullData = this.props.projectFullData;
            // after loading project full data,
            // get and setup various things such as filters (cities, supplier branches, outlet companies)
            // prices, extra display contracts ...etc
            if (this.props.projectType === PROJECT_TYPES.OPEN_MP_INSOURCE) {
                let [filtersMenus, extraDisplayContracts] = await Promise.all([
                    this.loadFiltersMenus(projectFullData),
                    // loadextraDisplayContractsFromProject(projectFullData),
                    getExtraDisplayContracts(projectFullData.data.supplier_id),
                ]);
                data = { filtersMenus, extraDisplayContracts };
            } else {
                let [filtersMenus, extraDisplayContracts, prices, extraDisplayPrices] = await Promise.all([
                    this.loadFiltersMenus(projectFullData),
                    getExtraDisplayContracts(projectFullData.data.supplier_id),
                    // loadextraDisplayContractsFromProject(projectFullData),
                    this.loadPricesFromProject(projectFullData),
                    this.loadExtraDisplayPricesFromProject(projectFullData),
                ]);

                data = { filtersMenus, extraDisplayContracts, prices, extraDisplayPrices };
            }
        } catch (error) {
            console.log(error);
            return false;
        }
        return data;
    };

    initVisits = (project) => {
        let totalVisits = {
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
        };

        for (const suppBranch of project.supplierBranches) {
            for (const outletBranch of suppBranch.outletBranches) {
                for (const schedule of outletBranch.schedules) {
                    schedule.totalVisits = {
                        sun: schedule.data.merchandising_days.sun.selected ? 1 : 0,
                        mon: schedule.data.merchandising_days.mon.selected ? 1 : 0,
                        tue: schedule.data.merchandising_days.tue.selected ? 1 : 0,
                        wed: schedule.data.merchandising_days.wed.selected ? 1 : 0,
                        thu: schedule.data.merchandising_days.thu.selected ? 1 : 0,
                        fri: schedule.data.merchandising_days.fri.selected ? 1 : 0,
                        sat: schedule.data.merchandising_days.sat.selected ? 1 : 0,
                    };
                    for (const day in schedule.totalVisits) {
                        if (Object.hasOwnProperty.call(schedule.totalVisits, day)) {
                            totalVisits[day] += schedule.totalVisits[day];
                        }
                    }
                }
            }
        }

        return totalVisits;
    };

    initOutletCoverageData = () => {
        const projectFullData = this.props.projectFullData;
        let outlets = this.state.outletsList
            .filter((o) => o.value !== "All Outlets")
            .map((o) => ({ id: o.value, name: o.label, products: [] }));

        for (const outlet of outlets) {
            let map = new Map();
            for (const sb of projectFullData.supplierBranches) {
                const outletBranches = this.filterOutletBranchesByOutletID(sb.outletBranches, outlet.id);
                for (const ob of outletBranches) {
                    if (ob.schedules.length <= 0) continue;

                    const schedule = ob.schedules[0];
                    for (const product of schedule.products) {
                        if (!map.has(product.info.id)) {
                            map.set(product.info.id, true);
                            outlet.products.push({
                                id: product.info.id,
                                code: product.info.data().product_number,
                                name: product.info.data().En_name,
                            });
                        }
                    }
                }
            }
        }

        return outlets;
    };

    filterOutletBranchesByOutletID = (outletBranches, outletID) => {
        return outletBranches.filter((ob) => ob.data.outlet_id === outletID);
    };

    updateTotalVisits = () => {
        let totalVisits = {
            sun: 0,
            mon: 0,
            tue: 0,
            wed: 0,
            thu: 0,
            fri: 0,
            sat: 0,
        };
        const schedulesMerchDays = this.state.schedulesRows.map((row) => row.scheduleData.merchandising_days);
        for (const merchDays of schedulesMerchDays) {
            for (const day in merchDays) {
                if (Object.hasOwnProperty.call(merchDays, day)) {
                    totalVisits[day] += merchDays[day].selected ? 1 : 0;
                }
            }
        }

        this.setState({ totalVisits: totalVisits });
    };

    updateDiscount = (value, type) => {
        let amount = this.state.discountAmount;
        let percentage = this.state.discountPercentage;
        const totalProjectValue = this.state.totalProjectValue;

        // validate and update
        if (type === "amount" && (value === "" || Number(value) === 0 || Number(value))) {
            amount = value === "" ? 0 : value;
            percentage = Number(amount) ? Number((Number(amount) / totalProjectValue).toFixed(2)) : 0;
        } else if (type === "percentage" && (value === "" || ((Number(value) === 0 || Number(value)) && value >= 0))) {
            percentage = value === "" ? 0 : value;
            amount = Number(percentage) ? Number(percentage) * totalProjectValue : 0;
            amount = +(Math.round(amount + "e+2") + "e-2");
        }

        this.setState(
            (prevState) => ({
                ...prevState,
                discountAmount: amount,
                discountPercentage: percentage,
            }),
            this.applyDiscountAndVAT
        );
    };

    applyDiscountAndVAT = () => {
        const { vat, discountAmount, totalProjectValue } = this.state;
        //including vat and discount
        let totalProjectValueFinal = totalProjectValue - discountAmount;
        totalProjectValueFinal = totalProjectValueFinal + vat * totalProjectValueFinal;
        this.setState({
            totalProjectValueFinal: totalProjectValueFinal,
        });
    };

    updateSchedulePrice = (scheduleRow) => {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;

        const scheduleData = scheduleRow.scheduleData;
        //bring active contracts which are during the project period + selected in the outlet branch products
        const activeContracts = this.updateActiveEDContracts(startDate, endDate, scheduleData.extra_display_contracts);
        //seasons
        scheduleData.seasons = this.updateActiveSeasons(startDate, endDate);
        scheduleData.seasons = scheduleData.seasons.map((season) => season.data());
        // regular prices
        scheduleData.normal_prices = this.updateOutletBranchNormalPrices(
            scheduleData.class_id,
            scheduleData.merchandising_days,
            scheduleData.seasons
        );
        //extra display prices
        scheduleData.extra_display_contracts_prices = this.updateExtraDisplayPrices(
            scheduleData.merchandising_days,
            scheduleData.seasons,
            scheduleData.extra_display_contracts,
            activeContracts
        );

        //total
        scheduleData.total_price = calculateOutletBranchValue(scheduleData, { startDate, endDate });

        scheduleRow.toBeUpdated = true;

        return scheduleRow;
    };

    updateTotalPrice = () => {
        const startDate = this.state.startDate;
        const endDate = this.state.endDate;
        let totalProjectValue = 0;
        let newSchedulesRows = this.state.schedulesRows;

        for (const scheduleRow of newSchedulesRows) {
            const scheduleData = scheduleRow.scheduleData;
            //bring active contracts which are during the project period + selected in the outlet branch products
            const activeContracts = this.updateActiveEDContracts(
                startDate,
                endDate,
                scheduleData.extra_display_contracts
            );
            //seasons
            scheduleData.seasons = this.updateActiveSeasons(startDate, endDate);
            scheduleData.seasons = scheduleData.seasons.map((season) => season.data());
            // regular prices
            scheduleData.normal_prices = this.updateOutletBranchNormalPrices(
                scheduleData.class_id,
                scheduleData.merchandising_days,
                scheduleData.seasons
            );
            //extra display prices
            scheduleData.extra_display_contracts_prices = this.updateExtraDisplayPrices(
                scheduleData.merchandising_days,
                scheduleData.seasons,
                scheduleData.extra_display_contracts,
                activeContracts
            );

            //total
            scheduleData.total_price = calculateOutletBranchValue(scheduleData, { startDate, endDate });
            // console.log("after", { ...scheduleData });

            scheduleRow.toBeUpdated = true;
            totalProjectValue += scheduleData.total_price.selling.total;
        }

        this.setState(
            { totalProjectValue: totalProjectValue, scheduleRows: newSchedulesRows },
            this.applyDiscountAndVAT
        );
    };

    updateActiveSeasons = (startDate, endDate) =>
        this.state.seasons.filter((season) =>
            dateRangeOverlaps(season.data().start_date.toDate(), season.data().end_date.toDate(), startDate, endDate)
        );

    updateActiveEDContracts = (startDate, endDate, obContracts) =>
        this.state.extraDisplayContracts.filter(
            (contract) =>
                dateRangeOverlaps(
                    contract.data().date_from.toDate(),
                    contract.data().date_to.toDate(),
                    startDate,
                    endDate
                ) && obContracts.find((contr) => contr.contract_id === contract.data().contract_id)
        );

    updateOutletBranchNormalPrices = (class_id, merchandising_days, activeSeasons) => {
        const workingDaysIDs = getWorkingDaysIDs(merchandising_days);
        const projectType = PROJECT_TYPES.MP_OUTSOURCE;

        //* update price list of of the outlet branch
        //seasonal
        let seasonalPrices = [];
        for (const season of activeSeasons) {
            const priceID = `${projectType}-${class_id}-${season.id}`;
            let seasonalPrice = this.state.seasonalPrices.find((sp) => sp.price_id === priceID);
            if (seasonalPrice) seasonalPrices.push(seasonalPrice);
        }

        //weekend
        let weekendPrices = [];
        for (const dayID of workingDaysIDs) {
            const priceID = `${projectType}-${class_id}-${dayID}`;
            let weekendPrice = this.state.weekendPrices.find((wp) => wp.id === priceID);
            if (weekendPrice) weekendPrices.push(weekendPrice);
        }

        //regular
        let regularPrice = this.state.regularPrices.find(
            (price) => price.class_id === class_id && price.project_type === projectType
        );

        return {
            regular: { ...regularPrice },
            seasonal: seasonalPrices,
            weekend: weekendPrices,
        };
    };

    updateExtraDisplayPrices = (merchandising_days, activeSeasons, obContracts, activeContracts) => {
        const workingDaysIDs = getWorkingDaysIDs(merchandising_days);

        // init extra display contract prices
        let extraDisplayContractPrices = [];
        for (const contract of activeContracts) {
            const obContract = obContracts.find((contr) => contr.contract_id === contract.data().contract_id);
            let contrPrice = {
                contract: { ...contract.data() },
                types: obContract.types.map((type) => {
                    return {
                        display_ids: type.display_ids,
                        id: type.extra_display_id,
                        num_of_products: type.num_of_products,
                        regular: null,
                        seasonal: [],
                        weekend: [],
                    };
                }),
            };

            //find prices for each type
            // for (const type of contrPrice.types) {
            for (let index = 0; index < contrPrice.types.length; index++) {
                let type = contrPrice.types[index];
                //seasonal
                let seasonalEDPrices = [];
                for (const season of activeSeasons) {
                    const priceID = `${type.id}-${season.id}`;
                    let seasonalEDPrice = this.state.seasonalEDPrices.find((sp) => sp.id === priceID);
                    if (seasonalEDPrice) seasonalEDPrices.push(seasonalEDPrice);
                }
                //weekend
                let weekendEDPrices = [];
                for (const dayID of workingDaysIDs) {
                    const priceID = `${type.id}-${dayID}`;
                    let weekendEDPrice = this.state.weekendEDPrices.find((wp) => wp.id === priceID);
                    if (weekendEDPrice) weekendEDPrices.push(weekendEDPrice);
                }

                //regular
                let regularEDPrice = this.state.regularEDPrices.find(
                    (price) => price.extra_display_price_id === type.id
                );

                contrPrice.types[index] = {
                    ...type,
                    regular: { ...regularEDPrice },
                    seasonal: seasonalEDPrices,
                    weekend: weekendEDPrices,
                };
            }
            extraDisplayContractPrices.push(contrPrice);
        }

        return extraDisplayContractPrices;
    };
    loadFiltersMenus = async (projectData) => {
        //get unique city IDs from outlet Branches
        let citiesIDs = getUniqueCitiesIDsFromProject(projectData);

        //get unique outlet companies IDs
        let outletsIDs = getUniqueOutletsIDsFromProject(projectData);

        let [cities, outlets] = await Promise.all([getCities(citiesIDs), getOutlets(outletsIDs)]);

        //get unique supplier branches
        let supplierBranchesList = [
            { value: "All Branches", label: "All Branches" },
            ...projectData.supplierBranches.map((sb) => ({
                value: sb.info.id,
                label: sb.info.data().En_name,
            })),
        ];

        let citiesList = [
            { value: "All Cities", label: "All Cities" },
            ...cities.map((city) => ({
                value: city.id,
                label: city.data().En_name,
            })),
        ];

        let outletsList = [
            { value: "All Outlets", label: "All Outlets" },
            ...outlets.map((outlet) => ({
                value: outlet.id,
                label: outlet.data().En_short_name,
            })),
        ];

        return { supplierBranchesList, citiesList, outletsList };
    };

    populateFiltersMenus = (menus) => {
        this.setState({ ...menus });
    };

    loadPricesFromProject = async (project) => {
        let regularPrices = [];
        let seasonalPrices = [];
        let weekendPrices = [];
        try {
            [regularPrices, seasonalPrices, weekendPrices] = await Promise.all([
                getRegularPricesFromProject(project),
                getSeasonalPrices(PROJECT_TYPES.MP_OUTSOURCE),
                getWeekendPrices(PROJECT_TYPES.MP_OUTSOURCE),
            ]);

            regularPrices = regularPrices.map((p) => p.data());
            seasonalPrices = seasonalPrices.map((p) => p.data());
            weekendPrices = weekendPrices.map((p) => p.data());
        } catch (error) {
            console.log(error);
        }
        return { regularPrices, seasonalPrices, weekendPrices };
    };

    getUniqueEDIDFromContracts = (contracts) => {
        let uniqueIDs = [];
        for (const contract of contracts) {
            let types = contract.types.map((type) => type.extra_display_id);
            uniqueIDs = [...new Set(uniqueIDs.concat(...types))];
        }
        return uniqueIDs;
    };

    loadExtraDisplayPricesFromProject = async (project) => {
        let regularPrices = [];
        let seasonalPrices = [];
        let weekendPrices = [];
        try {
            //load regular prices provided by the outlets (by extra display id)
            let uniqueIDs = [];
            for (const supplierBranch of project.supplierBranches) {
                for (const outletBranch of supplierBranch.outletBranches) {
                    for (const schedule of outletBranch.schedules) {
                        const contracts = schedule.data.extra_display_contracts || [];
                        //this is unique IDs per outlet branch, a further duplicate removal on the whole coverage
                        const extraDisplayIDs = this.getUniqueEDIDFromContracts(contracts);
                        uniqueIDs = [...new Set(uniqueIDs.concat(...extraDisplayIDs))];
                    }
                }
            }

            let requests = [getEDRegularPrices()];
            if (uniqueIDs.length > 0) {
                requests.push(getSeasonalEDPrices(uniqueIDs));
                requests.push(getWeekendEDPrices(uniqueIDs));
            }

            [regularPrices, seasonalPrices, weekendPrices] = await Promise.all(requests);
            regularPrices = regularPrices?.map((p) => p.data()) ?? [];
            seasonalPrices = seasonalPrices?.map((p) => p.data()) ?? [];
            weekendPrices = weekendPrices?.map((p) => p.data()) ?? [];
        } catch (error) {
            console.log(error);
        }

        return { regularEDPrices: regularPrices, seasonalEDPrices: seasonalPrices, weekendEDPrices: weekendPrices };
    };

    handlePojectNameChange = (e) => {
        const newValue = e.target.value;
        this.setState(
            {
                projectName: newValue,
            },
            this.validateInputs
        );
    };

    handleRemarksChange = (e) => {
        const newValue = e.target.value;
        this.setState(
            {
                remarks: newValue,
            },
            this.validateInputs
        );
    };

    handleStartDateChange = (date) => {
        date.setHours(0, 0, 0, 0);
        const tmw = getTomorrow();
        const endDate = this.state.endDate;

        if (date < tmw) date = tmw;
        else if (date > endDate) date = endDate;

        this.setState({ startDate: date }, () => {
            this.validateInputs();
            if (this.props.projectType === PROJECT_TYPES.MP_OUTSOURCE) {
                this.updateTotalPrice();
            }
        });
    };

    handleEndDateChange = (date) => {
        date.setHours(23, 59, 59, 59);
        const tmw = getTomorrow();
        const startDate = this.state.startDate;

        if (date < tmw) date = tmw;
        else if (date < startDate) date = startDate;

        this.setState({ endDate: date }, () => {
            this.validateInputs();
            if (this.props.projectType === PROJECT_TYPES.MP_OUTSOURCE) {
                this.updateTotalPrice();
            }
        });
    };

    authLetterSelected = (event) => {
        if (event.target.files.length <= 0) return false;

        const authFile = event.target.files[0];
        if (authFile.type !== "application/pdf") {
            // this.showDialogMsg("Invalid File Format", "Please upload the authorization letter in PDF format only.", this.hideDialogMsg);
            return false;
        }
        this.setState({
            authLetterFile: authFile,
            authLetterFileName: authFile.name,
        });
    };

    planogramSelected = (event) => {
        if (event.target.files.length <= 0) return false;
        const file = event.target.files[0];
        if (file.type !== "application/pdf") {
            // this.showDialogMsg("Invalid File Format", "Please upload the authorization letter in PDF format only.", this.hideDialogMsg);
            return false;
        }
        this.setState({
            planogramFile: file,
            planogramFileName: file.name,
        });
    };

    applyScheduleRowsChangesToProject = (scheduleRows, projectFullData) => {
        const supplierBranches = projectFullData.supplierBranches;
        for (const row of scheduleRows) {
            try {
                let supplierBranch = supplierBranches.find(
                    (sb) => sb.data.branch_id === row.scheduleData.supplier_branch_id
                );
                let outletBranch = supplierBranch.outletBranches.find(
                    (ob) => ob.data.branch_id === row.scheduleData.outlet_branch_id
                );
                let schedule = outletBranch.schedules.find((sch) => sch.data.id === row.scheduleData.id);

                schedule.data = cloneDeep(row.scheduleData);
                schedule.toBeUpdated = true;
            } catch (error) {
                throw error;
            }
        }
    };

    updateSchedulesRows = () => {
        let schedulesRows = setupScheduleRows(this.props.projectFullData);
        this.setState({ schedulesRows: schedulesRows }, () => {
            this.checkProjectIsReadyForConfirmation();
            if (this.props.projectType === PROJECT_TYPES.MP_OUTSOURCE) {
                this.updateTotalPrice();
            }
        });
    };

    updateRowData = (rowData) => {
        //! no need to search, rowData has the reference of the element of scheduleRows array.
        let schedulesRows = [...this.state.schedulesRows];
        let index = schedulesRows.findIndex(
            (row) =>
                row.scheduleData.id === rowData.scheduleData.id &&
                row.scheduleData.outlet_branch_id === rowData.scheduleData.outlet_branch_id &&
                row.scheduleData.supplier_branch_id === rowData.scheduleData.supplier_branch_id
        );

        schedulesRows[index] = rowData;
        schedulesRows[index].toBeUpdated = true;

        this.setState({ schedulesRows }, () => {
            this.updateTotalVisits();
            if (this.props.projectType === PROJECT_TYPES.MP_OUTSOURCE) {
                this.updateTotalPrice();
            }
        });

        try {
            //validate
            this.checkProjectIsReadyForConfirmation();
            this.validateInputs();
        } catch (error) {
            console.log(error);
        }
    };

    validateTextInput = (value) => {
        if (value.length === 0) return false;
        return true;
    };

    validateDates = (start, end) => {
        let tomorrow = getTomorrow();
        //validate start
        if (start < tomorrow && !this.state.isConfirmed) return false;
        //validate end
        if (end < start) return false;

        return true;
    };

    validateSchedules = (schedulesRows) => {
        if (schedulesRows.length <= 0) return false;
        //validate if they have at least a day and a product or extra display
        let valid = true;
        for (const row of schedulesRows) {
            let days = row.scheduleData.merchandising_days;
            let daysValid = checkIfADaySelected(days);
            let productsValid = row.scheduleData.number_of_products > 0;
            let isAssigned = row.scheduleData.merchandiser_id !== null;
            let validDuration = row.scheduleData.duration >= 10;

            if (!daysValid || !productsValid || !isAssigned || !validDuration) {
                valid = false;
                break;
            }
        }

        return valid;
    };

    validateInputs = () => {
        let isProjectNameValid = this.validateTextInput(this.state.projectName);
        let areDatesValid = this.validateDates(this.state.startDate, this.state.endDate);
        let project = this.props.projectFullData;
        this.setState({ validInputs: isProjectNameValid && areDatesValid && project });
    };

    checkProjectIsReadyForConfirmation = () => {
        let isProjectNameValid = this.validateTextInput(this.state.projectName);
        let areDatesValid = this.validateDates(this.state.startDate, this.state.endDate);
        let schedulesValid = this.validateSchedules(this.state.schedulesRows);

        this.setState({ confirmValidated: isProjectNameValid && schedulesValid && areDatesValid });
    };

    hasWorkDays = (schedule) => {
        const mDays = schedule.merchandising_days;
        for (const day in mDays) {
            if (Object.hasOwnProperty.call(mDays, day)) {
                const mDay = mDays[day];
                if (mDay.selected) return true;
            }
        }
        return false;
    };

    unlinkEmptyScheduleWithRoute = (scheduleRows) => {
        for (const row of scheduleRows) {
            const schedule = row.scheduleData;
            const notEmpty = this.hasWorkDays(schedule);
            if (!notEmpty) {
                schedule.route_id = null;
                schedule.merchandiser_id = null;
            }
        }

        return scheduleRows;
    };

    save = async () => {
        this.setState({ loading: true });
        try {
            let projectFullData = this.props.projectFullData;
            const projectID = projectFullData.data.project_id;
            //get modified data header
            let {
                projectName,
                startDate,
                endDate,
                remarks,
                authLetterURL,
                authLetterFile,
                authLetterFileName,
                planogramURL,
                planogramFile,
                planogramFileName,
            } = this.state;

            //* upload authorization file or delete if it is empty
            if (authLetterFile) {
                let task = uploadFile(`Project/${projectID}`, authLetterFileName, authLetterFile);
                let fileRef = await task;
                authLetterURL = await fileRef.ref.getDownloadURL();
            }
            //* upload planogram file or delete if it is empty
            if (planogramFile) {
                let task = uploadFile(`Project/${projectID}`, planogramFileName, planogramFile);
                let fileRef = await task;
                planogramURL = await fileRef.ref.getDownloadURL();
            }

            //apply those changes on project header
            projectFullData.data = {
                ...projectFullData.data,
                projName: projectName,
                date_from: startDate,
                date_to: endDate,
                remarks: remarks,
                authorization_letter: authLetterURL,
                planogram: planogramURL,
            };

            let scheduleRows = this.state.schedulesRows;

            this.applyScheduleRowsChangesToProject(scheduleRows, projectFullData);

            //flag with update for syncing
            projectFullData.toBeUpdated = true;
            await this.props.syncProject(projectFullData);
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    confirm = async () => {
        const isConfirmed = this.state.isConfirmed;

        if (isConfirmed) return;

        const result = await Swal.fire({
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "",
            cancelButtonColor: "",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            title: "Are You sure?",
            text: "By clicking 'Yes', there is no turning back and the visits will be published to your merchandisers on day-to-day basis.",
        });

        if (result.value) {
            this.confirmProject();
        }
    };

    confirmProject = async () => {
        this.setState({ loading: true });
        try {
            let projectFullData = this.props.projectFullData;
            const { supplier_confirmation } = projectFullData.data;
            if (!supplier_confirmation) {
                await Swal.fire({
                    icon: "error",
                    title: "Unable to Confirm",
                    text: "The supplier must confirm the project first.",
                });
                this.setState({ loading: false });
                return false;
            }
            //apply those changes on project header
            projectFullData.data = { ...projectFullData.data, isConfirmed: true, confirm: "1" };
            //flag with update for syncing
            projectFullData.toBeUpdated = true;
            await this.props.syncProject(projectFullData);

            this.setState({ isConfirmed: true });
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    componentDidMount() {
        this.init();
    }

    render() {
        const classes = this.props.classes;

        return (
            <Card className={classes.contentCard}>
                <FullPageLoader loading={this.state.loading} />

                {/* page title */}
                <ContentHeader title="Project Management" />
                {/* project header inputs */}

                <ProjectHeaderInputs
                    supplierName={this.state.supplier?.En_short_name || "---"}
                    projectID={this.props.projectFullData ? this.props.projectFullData.doc.id : ""}
                    isConfirmed={this.state.isConfirmed}
                    projectNameProps={{
                        value: this.state.projectName,
                        onChange: this.handlePojectNameChange,
                    }}
                    startDateProps={{
                        value: this.state.startDate,
                        onChange: this.handleStartDateChange,
                        minDate: this.state.isConfirmed ? null : getTomorrow(),
                        minDateMessage: "Date Should be from tomorrow or above.",
                    }}
                    endDateProps={{
                        value: this.state.endDate,
                        onChange: this.handleEndDateChange,
                        minDate: this.state.startDate,
                        minDateMessage: "Date Should not be before start date",
                    }}
                    remarksProps={{
                        value: this.state.remarks,
                        onChange: this.handleRemarksChange,
                    }}
                    authProps={{
                        inputProps: { id: "auth-pdf-file", value: this.state.authLetterFileName },
                        fileURL: this.state.authLetterURL,
                        fileObject: this.state.authLetterFile,
                        onFileSelect: this.authLetterSelected,
                        onClear: () =>
                            this.setState({
                                authLetterFileName: "Not yet uploaded.",
                                authLetterFile: null,
                                authLetterURL: null,
                            }),
                    }}
                    // planogramProps={{
                    //     inputProps: { id: "planogram-pdf-file", value: this.state.planogramFileName },
                    //     fileURL: this.state.planogramURL,
                    //     fileObject: this.state.planogramFile,
                    //     onFileSelect: this.planogramSelected,
                    //     onClear: () =>
                    //         this.setState({
                    //             planogramFileName: "Not yet uploaded.",
                    //             planogramFile: null,
                    //             planogramURL: null,
                    //         }),
                    // }}
                />

                <ProjectPDFs
                    getOutletCoverage={this.initOutletCoverageData}
                    supplier={this.state.supplier}
                    project={this.props.projectFullData.data}
                    date_from={this.state.startDate}
                    date_to={this.state.endDate}
                    totalProjectValue={this.state.totalProjectValue}
                    vat={this.state.vat}
                    coverageCities={this.state.citiesList
                        .filter((city) => city.value !== "All Cities")
                        .map((city) => city.label)}
                    scheduleRows={this.state.schedulesRows}
                    totalVisits={this.state.totalVisits}
                />

                <SchedulesManagement
                    projectFullData={this.props.projectFullData}
                    schedulesRows={this.state.schedulesRows}
                    totalVisits={this.state.totalVisits}
                    updateSchedulesRows={this.updateSchedulesRows}
                    updateRowData={this.updateRowData}
                    filters={{
                        supplierBranchesList: this.state.supplierBranchesList,
                        citiesList: this.state.citiesList,
                        outletsList: this.state.outletsList,
                    }}
                    projectType={this.props.projectType}
                    isConfirmed={this.state.isConfirmed}
                    refreshProjectState={this.props.updateProjectData}
                    validateInputs={this.validateInputs}
                    setLoading={this.setLoading}
                    syncProject={this.props.syncProject}
                    saveProject={this.save}
                    // updateSchedulePrice={this.updateSchedulePrice}
                    // updateTotalPrice={this.updateTotalPrice}
                />

                <ProjectBottomBar
                    onSave={this.save}
                    projectType={this.props.projectType}
                    supplierID={this.props.supplier}
                    isConfirmed={this.state.isConfirmed}
                    validInputs={this.state.validInputs}
                    priceDetails={{
                        vat: this.state.vat,
                        discountAmount: this.state.discountAmount,
                        totalProjectValue: this.state.totalProjectValue,
                        totalProjectValueFinal: this.state.totalProjectValueFinal,
                    }}
                    onBackClicked={this.props.onBackClicked}
                />
            </Card>
        );
    }
}

ProjectManagementContent.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(ProjectManagementContent);
