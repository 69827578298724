import * as Yup from "yup";
import { isEmailExist } from "../helpers/firebase-helpers";
import { auth } from "../services/firebase";

// const takenEmails = ["info@derwaza.tech"];

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const englishRegex = /[A-Za-z]/;
const arabicRegex = /[\u0600-\u06FF]/;

const urlWithoutHttp =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const phoneRegExp =
    /^((\+?\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

const MAX_IMAGE_SIZE = 1_048_576; //1MB
const SUPPORTED_IMAGE_FORMATS = ["image/jpeg", "image/png", "image/gif"];

Yup.addMethod(Yup.string, "phone", function (msg) {
    return this.matches(phoneRegExp, msg);
});

Yup.addMethod(Yup.string, "notDuplicateEmail", function (errorMessage) {
    return this.test("check-email-duplication", errorMessage, async function (email) {
        const { path, createError } = this;
        let isDuplicate = false;

        if (email) {
            try {
                const signInMethods = await auth.fetchSignInMethodsForEmail(email);
                isDuplicate = signInMethods.length > 0;
            } catch (error) {
                return createError({
                    path,
                    message: "Could not validate your email. Please check your internet connection.",
                });
            }
        }
        return !isDuplicate || createError({ path, message: errorMessage });
    });
});

export const STEP_ONE_VALIDATION_SCHEMA = Yup.object().shape({
    first_name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
    last_name: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
    phone_number: Yup.number().integer("Invalid phone number").positive("Invalid phone number").required("Required"),
    email: Yup.string().test("validate-email", "Invalid email", async (value, context) => {
        try {
            Yup.string().required("required").email("Invalid email").validateSync(value);
            // const exist = await isEmailExist(value);
            // if (exist) {
            //     return context.createError({ path: context.path, message: "This email already exists" });
            // }
            return true;
        } catch (error) {
            return context.createError({ path: context.path, message: error.message });
        }
    }),
    password: Yup.string()
        .matches(lowercaseRegex, "One lowercase required")
        .matches(uppercaseRegex, "One uppercase required")
        .matches(numericRegex, "One number required")
        .min(6, "Must be at least 6 characters")
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Password must match")
        .required("Required"),
});

export const STEP_TWO_VALIDATION_SCHEMA = Yup.object().shape({
    company_name: Yup.string().max(50, "Must be 50 characters or less").required("Required"),
    company_short_name_en: Yup.string()
        .matches(englishRegex, "Must be in English letters")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
    company_short_name_ar: Yup.string()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(15, "Must be 15 characters or less")
        .required("Required"),
    // company_type: Yup.object().required("Required").shape({
    //     label: Yup.string(),
    //     value: Yup.string().required(),
    // }),
    // linked_outlet: Yup.object().required("Required").shape({
    //     label: Yup.string(),
    //     value: Yup.string().required(),
    // }),
    company_city: Yup.object().required("Required").shape({
        label: Yup.string(),
        value: Yup.string().required(),
    }),
    company_location: Yup.string(),
    company_website_link: Yup.string().matches(urlWithoutHttp, "Enter correct url"),
    company_email: Yup.string().email("Invalid email"),
    company_phone_number: Yup.string().phone(),
    company_fax_number: Yup.string().phone(),
    company_logo_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE),
});

export const STEP_THREE_VALIDATION_SCHEMA = Yup.object().shape({
    commercial_registry_number: Yup.number().positive().integer(),
    commercial_registry_expiry_date: Yup.date().nullable(),
    municipal_license_number: Yup.number().positive().integer(),
    vat_number: Yup.number().positive().integer(),
    vat_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE),
    commercial_registry_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE),
});

export const STEP_FOUR_VALIDATION_SCHEMA = Yup.object().shape({
    contact_first_name: Yup.string().max(15, "Must be 15 characters or less"),
    contact_last_name: Yup.string().max(20, "Must be 20 characters or less"),
    contact_mobile_number: Yup.string().phone(),
    contact_phone_number: Yup.string().phone(),
    contact_external_phone_number: Yup.string().phone(),
    contact_email: Yup.string().email("Invalid Email"),
    job_title: Yup.string(),
});
