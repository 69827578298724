import { flatten, isDate, isObject } from "lodash/fp";
import { arrayChunks } from "../../helpers/array-helpers";
import { getSchedulesByRoute } from "./Project";
import { firestore as db } from "../firebase";
import { PLATFORM } from "../../constants/global";

const ROUTE = "Route";

export const getRoutes = async (options) => {
    try {
        let ref = db.collection(ROUTE);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }

        for (const key in options) {
            if (Object.hasOwnProperty.call(options, key)) {
                let query = { value: null, operator: "==" };

                if (!isObject(options[key]) || isDate(options[key])) {
                    query.value = options[key];
                } else {
                    query = options[key];
                }
                const { value, operator } = query;

                if (key === "projectID") ref = ref.where("project_id", operator, value);
                if (key === "supplierBranchID") ref = ref.where("supplier_branch_id", operator, value);
                if (key === "deleted") ref = ref.where("is_deleted", operator, value);
            }
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};

export const getRoutesByTasks = async (tasks) => {
    try {
        //get unique route IDs
        const routeIDs = tasks.reduce((IDs, task) => {
            if (!task.route_id) return IDs;
            if (IDs.includes(task.route_id)) return IDs;
            IDs.push(task.route_id);
            return IDs;
        }, []);

        const routes = await Promise.all(routeIDs.map(async (id) => getRoutes({ docID: id })));

        return routes;
    } catch (error) {
        throw error;
    }
};

export const saveRoutes = async (routes) => {
    try {
        let batch = db.batch();
        //check if there is no duplicate IDs
        const hasDuplicateIDs = checkDuplicates(routes, "id");
        if (hasDuplicateIDs) throw new Error("Cannot Add Routes because there are duplicate IDs.");

        for (const route of routes) {
            let ref = db.collection(ROUTE).doc(route.id);
            batch.set(ref, { ...route, platform: PLATFORM });
        }

        await batch.commit();
    } catch (error) {
        throw error;
    }
};

export const updateRoute = async (id, data) => {
    try {
        await db.collection(ROUTE).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};

export const changeRouteMerchandiser = async (routeID, merchandiserID) => {
    try {
        const route = await getRoutes({ docID: routeID });
        const newRoute = { ...route.data(), merchandiser_id: merchandiserID };
        await updateRoute(routeID, newRoute);
        // update merchandiser id in all project schedules
        const schedules = await getSchedulesByRoute(newRoute);
        const batch = db.batch();
        schedules.forEach((schedule) => {
            batch.update(schedule.ref, { merchandiser_id: merchandiserID });
        });
        await batch.commit();
    } catch (error) {
        throw error;
    }
};

// export const deleteRoutes = async (IDs) => {
//     try {
//         let batch = db.batch();
//         for (const id of IDs) {
//             let ref = db.collection(ROUTE).doc(id);
//             batch.delete(ref);
//         }

//         await batch.commit();
//     } catch (error) {
//         throw error;
//     }
// };

export const findRoute = async ({ projectID, supplierBranchID, path }) => {
    try {
        const routes = await getRoutes({ projectID, supplierBranchID });

        if (routes.length <= 0) return null;

        for (const route of routes) {
            if (hasPath(route.data(), path)) return route;
        }

        return null;
    } catch (error) {
        throw error;
    }
};

export const removePathFromRoute = async (route, path) => {
    try {
        let newPlan = { ...route.plan };
        for (const day in newPlan) {
            if (Object.hasOwnProperty.call(newPlan, day)) {
                const index = newPlan[day].findIndex((rPath) => rPath === path);
                if (index === -1) continue;
                newPlan[day].splice(index, 1);
            }
        }

        if (isEmptyRoute(newPlan)) {
            await db.collection(ROUTE).doc(route.id).delete();
        } else {
            await db.collection(ROUTE).doc(route.id).update({
                plan: newPlan,
            });
        }
    } catch (error) {
        throw error;
    }
};

export const queryRoutes = async (options) => {
    try {
        let ref = db.collection(ROUTE);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }

        //check for in operator
        let inQuery = options.filter((op) => op?.operator === "in");
        if (inQuery.length > 1) throw new Error("There are more than 1 in operator");

        for (const option of options) {
            const { key, value = null, operator = "==" } = option;
            if (operator === "in") continue;

            ref = ref.where(key, operator, value);
        }

        if (inQuery.length === 1) {
            const { key, value = null, operator = "in" } = inQuery[0];

            //inQuery value should an array
            const chunks = arrayChunks(value, 10);
            const res = await Promise.all(
                chunks.map(async (chunk) => {
                    return (await ref.where(key, operator, chunk).get()).docs;
                })
            );

            //flatten
            return flatten(res);
        } else {
            return (await ref.get()).docs;
        }
    } catch (error) {
        throw error;
    }
};

/* utilts */

export const extractPathsFromPlan = (plan) => {
    let unique = [];
    for (const day in plan) {
        if (Object.hasOwnProperty.call(plan, day)) {
            const paths = plan[day];
            unique = [...new Set([...paths, ...unique])];
        }
    }

    return unique;
};

const hasPath = (route, path) => {
    const plan = route.plan;
    for (const day in plan) {
        if (Object.hasOwnProperty.call(plan, day)) {
            const paths = plan[day];
            if (paths.includes(path)) return true;
        }
    }
    return false;
};

const checkDuplicates = (objects, property) => {
    let seen = new Set();
    const hasDuplicates = objects.some((obj) => {
        //if set size before is equal to the size after adding, there is a duplicate
        return seen.size === seen.add(obj[property]).size;
    });

    return hasDuplicates;
};

export const isEmptyRoute = (plan) => {
    for (const day in plan) {
        if (Object.hasOwnProperty.call(plan, day)) {
            if (plan[day].length > 0) return false;
        }
    }
    return true;
};
