import React, { Component } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import componentsStyle from "../../core-ui/core-styles/jss/general-component-style";

import Content from "./Content";
import { COLORS } from "../../constants/constants-lagacy";
import { Redirect } from "react-router";
import PATHS from "../../constants/paths";

class VisitReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uid: this.props.uid,
            id: this.props.id,
            pending: true,
        };
    }

    render() {
        const { classes, taskId } = this.props;
        if (!taskId) {
            return <Redirect to={PATHS.VISITS_MANAGEMENT} />;
        }
        return (
            <div
                style={{ backgroundColor: COLORS.background, margin: "0px", padding: "0px" }}
                className={classes.pageHeader}
            >
                <Content
                    mpID={this.state.id}
                    uid={this.state.uid}
                    taskId={this.props.taskId}
                    date={this.props.date}
                    city={this.props.city}
                    outletBranch={this.props.outletBranch}
                />
            </div>
        );
    }
}

VisitReport.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(VisitReport);
