import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Header, TransitionAlert } from "../../core-ui/custom";

import * as Yup from "yup";
import { useCallback } from "react";
import { useState } from "react";

import { useAuth } from "../../contexts/auth-context";
import { getRelativeDateFromToday, getToday } from "../../helpers/date-utils";
import {
    queryMerchandiserExclusivityContracts,
    updateMerchandiserExclusivityContract,
} from "../../services/firestore/Merchandiser_Exclusivity_Contract";
import { Card, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { DateTimePicker, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { changeRouteMerchandiser, queryRoutes } from "../../services/firestore/Route";
import { MERCH_CONTRACT_STATUS, PROJECT_TYPES } from "../../constants/constants-lagacy";
import { useHistory } from "react-router";
import { getSupplier } from "../../services/firestore/Supplier";
import useNonExclusiveMerchandisers from "../../hooks/use-non-exclusive-merchandisers";
import UpdateExclusiveMerchandiserDialog from "../../components-lagacy/Dialog/UpdateExclusiveMerchandiserDialog";

let INITIAL_VALUES = {
    contract_name: "",
    contract_number: "",
    supplier: "",
    start_date: getToday(), // if date is defiend as '' yup will throw a invalid date error
    end_date: getRelativeDateFromToday(7),
    merchandisers: [],
};

const SCHEMAS = Yup.object().shape({
    // start_date: Yup.date().min(getToday(), "start date can't be before today").required("Required"),
    // end_date: Yup.date().min(Yup.ref("start_date"), "End date can't be before start date").required("Required"),
    contract_name: Yup.string().required("Contract Name is required"),
});

const UpdateExclusivityContract = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    const history = useHistory();
    const [formValues, setFormValues] = useState(null);
    const [currentContract, setCurrentContract] = useState(null);
    const [contractMerchandisers, setContractMerchandisers] = useState([]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMerchIndex, setSelectedMerchIndex] = useState(-1);

    const [alerts, setAlerts] = useState([]);

    const handleError = useCallback((code) => {
        if ("no-merchandisers" === code) {
            addAlert("error", "No merchandisers found");
        }
    }, []);

    const addAlert = (severity, message) => {
        setAlerts((prevAlerts) => [...prevAlerts, { severity, message, open: true }]);
    };

    const init = useCallback(
        async (context) => {
            try {
                let currentContract = await queryMerchandiserExclusivityContracts({
                    docID: history.location.state.contractID,
                });

                let supplier = await getSupplier(currentContract.data().supplier_id);

                const { number, name, start_date, end_date, merchandisers: mList } = currentContract.data();
                const { merchandisers } = context;

                setCurrentContract(currentContract.data());
                setContractMerchandisers(
                    mList.map((merch) => {
                        const merchData = merchandisers.find((merchandiser) => merchandiser.value === merch.id);
                        return {
                            ...merchData,
                            status: merch.status || 0,
                            supplier_branch_id: merch.supplier_branch_id || null,
                        };
                    })
                );
                setFormValues({
                    ...INITIAL_VALUES,
                    contract_name: name,
                    contract_number: number,
                    supplier: supplier.data().company_name,
                    start_date: start_date.toDate(),
                    end_date: end_date.toDate(),
                });
            } catch (error) {
                console.log(error);
            }
        },
        [history.location.state.contractID]
    );

    const { allMerchandisers, getAvailableMerchandisers, refresh } = useNonExclusiveMerchandisers(
        companyData.company_id,
        init,
        handleError
    );

    const handleMerchCardClick = (formik, merchIndex) => {
        setSelectedMerchIndex(merchIndex);
        setIsDialogOpen(true);
    };

    const handleClosingDialog = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = async (currentMerchandiser, newMerchandiser, status) => {
        try {
            //get current merchandiser routes
            const currentMerchandiserRoutes = (
                await queryRoutes([
                    { key: "merchandiser_id", value: currentMerchandiser.value },
                    { key: "supplier_branch_id", value: currentMerchandiser.supplier_branch_id },
                    { key: "project_type", value: PROJECT_TYPES.SUPPLIER_INSOURCE },
                ])
            ).map((route) => route.data());

            //update routes according to the current status
            if (status === MERCH_CONTRACT_STATUS.ACTIVE && newMerchandiser) {
                await Promise.all(
                    currentMerchandiserRoutes.map(async (route) => {
                        route.merchandiser_id = newMerchandiser.value;
                        await changeRouteMerchandiser(route.id, route.merchandiser_id);
                    })
                );
            } else if (status === MERCH_CONTRACT_STATUS.INACTIVE) {
                await Promise.all(
                    currentMerchandiserRoutes.map(async (route) => {
                        route.merchandiser_id = null;
                        await changeRouteMerchandiser(route.id, route.merchandiser_id);
                    })
                );
            }

            //update contract
            const newCurrentContract = {
                ...currentContract,
                merchandisers: currentContract.merchandisers.map((merch) => {
                    if (merch.id === currentMerchandiser.value) {
                        return {
                            ...merch,
                            id: newMerchandiser.value,
                        };
                    }
                    return merch;
                }),
            };
            await updateMerchandiserExclusivityContract(newCurrentContract.id, {
                merchandisers: newCurrentContract.merchandisers,
            });
            await refresh();
            setContractMerchandisers(
                newCurrentContract.merchandisers.map((merch) => {
                    const merchData = allMerchandisers.find((merchandiser) => merchandiser.value === merch.id);
                    return {
                        ...merchData,
                        status: merch.status || 0,
                        supplier_branch_id: merch.supplier_branch_id || null,
                    };
                })
            );
            addAlert("success", "Merchandiser updated successfully");
            setIsDialogOpen(false);
            setCurrentContract(newCurrentContract);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (values, helpers) => {
        try {
            let { contract_name } = values;
            await updateMerchandiserExclusivityContract(currentContract.id, { name: contract_name });
            addAlert("success", "Contract successfully created");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <section className={classes.UpdateExclusivityContract}>
            {alerts.map((alert, index) => (
                <TransitionAlert
                    key={`alert-${index}`}
                    severity={alert.severity}
                    variant="filled"
                    open={alert.open}
                    setOpen={(open) => {
                        setAlerts((prevAlerts) => {
                            let newAlerts = [...prevAlerts];
                            newAlerts[index].open = open;
                            return newAlerts;
                        });
                    }}
                >
                    {alert.message}
                </TransitionAlert>
            ))}
            <Header>Edit Exclusivity Contract</Header>
            <Formik
                initialValues={formValues || INITIAL_VALUES}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={SCHEMAS}
            >
                {(Formik) => (
                    <Form>
                        <Card className={classes.headerInputCard}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField name="supplier" label="Supplier" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField name="contract_number" label="No" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField name="contract_name" label="Name" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DateTimePicker name="start_date" label="Start Date" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DateTimePicker name="end_date" label="End Date" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SubmitButton className={classes.submitButton}>Save</SubmitButton>
                                </Grid>
                            </Grid>
                        </Card>

                        <Header>Merchandisers</Header>
                        <Grid container spacing={1}>
                            {contractMerchandisers.map((merchandiser, index) => (
                                <Grid item xs={12} sm={6} md={3} key={merchandiser.value}>
                                    <Card
                                        className={classes.merchandiserCard}
                                        onClick={() => handleMerchCardClick(Formik, index)}
                                    >
                                        {merchandiser.label}
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <UpdateExclusiveMerchandiserDialog
                            open={isDialogOpen}
                            onClose={handleClosingDialog}
                            onSubmit={handleDialogSave}
                            title="Update Exclusive Merchandiser"
                            submitText="Save"
                            merchandiser={selectedMerchIndex !== -1 ? contractMerchandisers[selectedMerchIndex] : null}
                            merchandisersList={
                                isDialogOpen
                                    ? getAvailableMerchandisers(Formik.values.start_date, Formik.values.end_date)
                                    : []
                            }
                        />
                    </Form>
                )}
            </Formik>
        </section>
    );
};

UpdateExclusivityContract.propTypes = {
    classes: PropTypes.object,
};

export default UpdateExclusivityContract;
