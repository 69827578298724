import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import styles from "./styles";
import { useFormikContext } from "formik";

const ButtonWrapper = ({ children, submitting = false, className, ...otherProps }) => {
    const classes = styles();
    const formik = useFormikContext();

    const configButton = {
        ...otherProps,
        type: "submit",
        variant: "contained",
        color: "primary",
        fullWidth: true,
        className: `${classes.button} ${className}`,
        classes: { disabled: classes.disabled },
        disabled: submitting || !formik.dirty || !formik.isValid,
    };

    if (submitting) {
        configButton.disabled = true;
    }

    return (
        <Button {...configButton}>
            {submitting ? <CircularProgress className={classes.circularProgress} /> : children}
        </Button>
    );
};

export default ButtonWrapper;
