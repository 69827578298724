import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import componentsStyle from "../../core-ui/core-styles/jss/general-component-style";
import PATHS from "../../constants/paths";
import { withRouter } from "react-router";
import Content from "./Content";

class VisitsManagement extends React.Component {
    state = {
        loading: false,
        dialogOpen: false,
        dialogHeader: "",
        dialogMsg: "",
        dialogCloseHandler: null,
        uid: this.props.uid,
        mpID: this.props.id,
    };

    gotToVisitDetails = (IDs) => {
        if (IDs.length !== 1) {
            return false;
        }

        this.props.history.push({
            pathname: PATHS.VISIT_DETAILS,
            state: {
                id: this.props.id,
                taskID: IDs[0],
                type: "insource",
            },
        });
    };

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.pageHeader}>
                <Content type="insource" mpID={this.props.id} gotToVisitDetails={this.gotToVisitDetails} />

                <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                    <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: "#91041c" }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

VisitsManagement.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(withRouter(VisitsManagement));
