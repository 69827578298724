import { useCallback, useEffect, useState } from "react";
import { getMerchandiserName } from "../helpers/merchandiser-utils";
import { getMPMerchandisers } from "../services/firestore/Merchandiser";
import { queryMerchandiserExclusivityContracts } from "../services/firestore/Merchandiser_Exclusivity_Contract";
import { getProject } from "../services/firestore/Project";
import { queryRoutes } from "../services/firestore/Route";

const defaultHandleError = (code) => {
    console.log(code);
};
const useNonExclusiveMerchandisers = (mpID, postInit = async () => {}, handleError = defaultHandleError) => {
    const [allMerchandisers, setAllMerchandisers] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [projects, setProjects] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [isInitializing, setIsInitializing] = useState(true);

    const refresh = async () => {
        init(true);
    };

    const getAvailableMerchandisers = (start_date, end_date) => {
        let freeMerch = allMerchandisers.filter((merchandiser) => {
            //see if a merchandiser is already linked to a contract
            const merchandiser_contracts = contracts.filter(
                (contract) => contract.merchandisers.find((cMerch) => cMerch.id === merchandiser.data.uid) !== undefined
            );
            //if no contract is found, then the merchandiser is available
            if (merchandiser_contracts.length === 0) {
                return true;
            }
            //if a contract is found, check if the merchandiser is available for the given date
            const merchandiser_contracts_dates = merchandiser_contracts.map((contract) => {
                return {
                    start_date: contract.start_date.toDate(),
                    end_date: contract.end_date.toDate(),
                };
            });

            const merchandiser_contracts_dates_overlapping = merchandiser_contracts_dates.filter((contract_date) => {
                return start_date <= contract_date.end_date && end_date >= contract_date.start_date;
            });
            return merchandiser_contracts_dates_overlapping.length === 0;
        });

        // see if there is a merchandiser has a route plan for the given date
        freeMerch = freeMerch.filter((merchandiser) => {
            const merchandiser_route_plans = routes.filter((route) => {
                return route.merchandiser_id === merchandiser.data.uid;
            });
            if (merchandiser_route_plans.length === 0) {
                return true;
            }

            let projIDs = merchandiser_route_plans.map((route) => {
                return route.project_id;
            });
            projIDs = [...new Set(projIDs)];
            const merchandiser_projects = projIDs.filter((id) => {
                const proj = projects.find((proj) => proj.project_id === id);
                return proj.date_from.toDate() <= end_date && proj.date_to.toDate() >= start_date;
            });
            return merchandiser_projects.length === 0;
        });

        return freeMerch;
    };

    const init = useCallback(
        async (refresh = false) => {
            try {
                //get all merchandisers and contracts
                let [merchandisers, exclContracts] = await Promise.all([
                    getMPMerchandisers(mpID),
                    queryMerchandiserExclusivityContracts([{ key: "mp_id", value: mpID }]),
                ]);

                //exclusivity contracts
                exclContracts = exclContracts.map((exclContract) => exclContract.data());

                if (merchandisers.length <= 0) {
                    handleError("no-merchandisers");
                    return;
                }

                //get all merchandisers routes
                let routes = await queryRoutes([
                    { key: "merchandiser_id", value: merchandisers.map((merch) => merch.id), operator: "in" },
                    { key: "is_deleted", value: false },
                ]);
                routes = routes.map((route) => route.data());

                //from the routes get all projects
                const projIDs = routes.reduce((ids, route) => {
                    return [...new Set([...ids, route.project_id])];
                }, []);

                let projects = await Promise.all(projIDs.map((id) => getProject(id)));
                projects = projects.map((project) => project.data());

                //merchandisers
                merchandisers = merchandisers.map((merchandiser) => {
                    return {
                        label: getMerchandiserName(merchandiser.data()),
                        value: merchandiser.id,
                        data: merchandiser.data(),
                    };
                });

                setAllMerchandisers(merchandisers);
                setRoutes(routes);
                setProjects(projects);
                setContracts(exclContracts);

                if (!refresh) {
                    await postInit({ merchandisers, routes, projects, exclContracts });
                }
            } catch (error) {
                console.log(error);
            }
            setIsInitializing(false);
        },
        [mpID, handleError, postInit]
    );

    useEffect(() => {
        init();
    }, [init]);

    return {
        isInitializing,
        allMerchandisers,
        getAvailableMerchandisers,
        refresh,
    };
};
export default useNonExclusiveMerchandisers;
