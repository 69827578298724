import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button, Header, TransitionAlert } from "../../core-ui/custom";

import * as Yup from "yup";
import { useCallback } from "react";
import { useState } from "react";
import { getLinkedSuppliers } from "../../services/firestore/MP_Supplier_Link";
import { useAuth } from "../../contexts/auth-context";
import { getRelativeDateFromToday, getToday } from "../../helpers/date-utils";
import {
    addMerchandiserExclusivityContract,
    queryMerchandiserExclusivityContracts,
} from "../../services/firestore/Merchandiser_Exclusivity_Contract";
import { Card, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { AutocompleteMultiple, Autocomplete, DateTimePicker, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { CONTRACT_MERCH_STATUS, MERCH_CONTRACT_STATUS } from "../../constants/constants-lagacy";
import useNonExclusiveMerchandisers from "../../hooks/use-non-exclusive-merchandisers";

let INITIAL_VALUES = {
    contract_number: "",
    contract_name: "",
    supplier: null,
    start_date: getToday(), // if date is defiend as '' yup will throw a invalid date error
    end_date: getRelativeDateFromToday(7),
    merchandisers: [],
};

const SCHEMAS = [
    Yup.object().shape({
        contract_number: Yup.string().test(
            "validate-contract-number",
            "Contract Number already exists",
            async (value, context) => {
                try {
                    Yup.string().required("Contract Number is required").validateSync(value);
                    const result = await queryMerchandiserExclusivityContracts([
                        { key: "number", operator: "==", value: value },
                    ]);

                    if (result.length > 0) {
                        return context.createError({
                            path: context.path,
                            message: "This contract number already exists",
                        });
                    }
                    return true;
                } catch (error) {
                    return context.createError({ path: context.path, message: error.message });
                }
            }
        ),
        contract_name: Yup.string().required("Contract Name is required"),
        supplier: Yup.object().required("Required").shape({
            label: Yup.string(),
            value: Yup.string().required(),
            data: Yup.object(),
        }),
        start_date: Yup.date().min(getToday(), "start date can't be before today").required("Required"),
        end_date: Yup.date().min(Yup.ref("start_date"), "End date can't be before start date").required("Required"),
    }),
    Yup.object().shape({
        merchandisers: Yup.array().min(1, "At least choose 1 merchandiser").required("Required"),
    }),
];

const AddExclusivityContract = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [formValues, setFormValues] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isInitializing, setIsInitializing] = useState(true);

    const [suppliersList, setSuppliersList] = useState([]);
    const [merchandisersList, setMerchandisersList] = useState([]);

    const [currentStep, setCurrentStep] = useState(0);
    const [alerts, setAlerts] = useState([]);

    const handleError = useCallback((code) => {
        if ("no-merchandisers" === code) {
            addAlert("error", "No merchandisers found");
        }
    }, []);

    const addAlert = (severity, message) => {
        setAlerts((prevAlerts) => [...prevAlerts, { severity, message, open: true }]);
    };

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;
            //get all linked suppliers
            //get all merchandisers
            let suppliers = await getLinkedSuppliers(company_id);

            if (suppliers.length <= 0) {
                addAlert("error", "No suppliers found");
                return;
            }

            suppliers = suppliers.map((supplier) => {
                return {
                    label: supplier.data().company_name,
                    value: supplier.id,
                    data: supplier.data(),
                };
            });

            setSuppliersList(suppliers);

            setFormValues({
                ...INITIAL_VALUES,
                supplier: suppliers[0],
            });
        } catch (error) {
            console.log(error);
        }
        setIsInitializing(false);
    }, [companyData]);

    const { getAvailableMerchandisers, isInitializing: isNonExclMerchInitializing } = useNonExclusiveMerchandisers(
        companyData.company_id,
        init,
        handleError
    );

    const contractHeaderSetHandler = async (formik) => {
        const { values } = formik;
        if (currentStep === 0) {
            const merchandisers = getAvailableMerchandisers(values.start_date, values.end_date);
            setMerchandisersList(merchandisers);
            setCurrentStep(1);
        } else {
            setCurrentStep(0);
        }

        formik.setFieldValue("merchandisers", []);
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setIsSaving(true);
            let { supplier, start_date, end_date, merchandisers, contract_number, contract_name } = values;
            const { company_id } = companyData;
            const contractMerchandisers = merchandisers.map((merchandiser) => {
                return {
                    id: merchandiser.value,
                    supplier_branch_id: null,
                    status: CONTRACT_MERCH_STATUS.ACTIVE,
                };
            });
            await addMerchandiserExclusivityContract({
                number: contract_number,
                name: contract_name,
                supplier_id: supplier.value,
                mp_id: company_id,
                start_date: start_date,
                end_date: end_date,
                status: MERCH_CONTRACT_STATUS.PENDING,
                merchandisers: contractMerchandisers,
            });

            addAlert("success", "Contract successfully created");
        } catch (error) {
            console.log(error);
        }
        setIsSaving(false);
    };

    return (
        <section className={classes.addExclusivityContract}>
            {alerts.map((alert, index) => (
                <TransitionAlert
                    key={`alert-${index}`}
                    severity={alert.severity}
                    variant="filled"
                    open={alert.open}
                    setOpen={(open) => {
                        setAlerts((prevAlerts) => {
                            let newAlerts = [...prevAlerts];
                            newAlerts[index].open = open;
                            return newAlerts;
                        });
                    }}
                >
                    {alert.message}
                </TransitionAlert>
            ))}
            <Header>Create New Exclusivity Contract</Header>
            <Formik
                initialValues={formValues || INITIAL_VALUES}
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={SCHEMAS[currentStep]}
            >
                {(Formik) => (
                    <Form>
                        <Card className={classes.headerInputCard}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Autocomplete
                                        disableClearable
                                        options={suppliersList}
                                        name="supplier"
                                        label="Supplier"
                                        onChange={() => {
                                            setCurrentStep(0);
                                        }}
                                        disabled={currentStep !== 0}
                                        loading={isInitializing}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        name="contract_number"
                                        label="Contract No"
                                        disabled={currentStep !== 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField name="contract_name" label="Name" disabled={currentStep !== 0} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DateTimePicker
                                        name="start_date"
                                        label="Start Date"
                                        minDate={getToday()}
                                        maxDate={Formik.values.end_date}
                                        onChange={() => {
                                            setCurrentStep(0);
                                        }}
                                        disabled={currentStep !== 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DateTimePicker
                                        name="end_date"
                                        label="End Date"
                                        minDate={Formik.values.start_date}
                                        onChange={() => {
                                            setCurrentStep(0);
                                        }}
                                        disabled={currentStep !== 0}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    {/* {console.log(Formik.isValidating, Formik.dirty, Formik.isValid)} */}
                                    <Button
                                        className={classes.setButton}
                                        onClick={() => contractHeaderSetHandler(Formik)}
                                        disabled={
                                            currentStep === 0 &&
                                            isInitializing &&
                                            isNonExclMerchInitializing &&
                                            (!Formik.dirty || !Formik.isValid)
                                        }
                                    >
                                        {currentStep === 0 ? "See Available Merchandisers" : "Reset"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>

                        <Header>Select Available Merchandisers</Header>
                        {currentStep === 1 && (
                            <Card className={classes.merchandisersCard}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={9}>
                                        <AutocompleteMultiple
                                            options={merchandisersList}
                                            name="merchandisers"
                                            inputLabel={`${Formik.values.merchandisers.length} Merchandiser(s) Selected`}
                                            optionLabelProp="label"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <SubmitButton className={classes.submitButton} submitting={isSaving}>
                                            Save
                                        </SubmitButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                        <Grid container spacing={1}>
                            {Formik.values.merchandisers.map((merchandiser) => (
                                <Grid item xs={12} sm={6} md={3} key={merchandiser.value}>
                                    <Card className={classes.merchandiserCard}>{merchandiser.label}</Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </section>
    );
};

AddExclusivityContract.propTypes = {
    classes: PropTypes.object,
};

export default AddExclusivityContract;
