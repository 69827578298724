import { query } from "./helpers";
import { COLLECTIONS } from "./constants";
import { firestore as db } from "../firebase";
import { flatten } from "lodash";
import { arrayChunks } from "../../helpers/array-helpers";

export const queryTaskDetails = async (q, { enableOnSnapshot = false, ...otherOptions } = {}) => {
    try {
        return await query(COLLECTIONS.TASK_DETAILS, q, { enableOnSnapshot, ...otherOptions });
    } catch (error) {
        throw error;
    }
};

export const getDetailsFromTasks = async (
    tasks,
    { isEnded = null, isRemoved = null, enableOnSnapshot = false, onSnapshot = () => {}, onError = () => {} } = {}
) => {
    try {
        // converts tasks into chunks of 10
        const chunks = arrayChunks(tasks, 10);

        // result = [array of task details] or array of unsubs
        const result = await Promise.all(
            chunks.map(async (chunk, index) => {
                let query = [{ key: "task_id", operator: "in", value: chunk.map((task) => task.task_id) }];
                if (isRemoved !== null) query.push({ key: "removed", operator: "==", value: isRemoved });
                if (isEnded !== null) query.push({ key: "isEnded", operator: "==", value: isEnded });

                if (enableOnSnapshot) {
                    let unsub = await queryTaskDetails(query, {
                        enableOnSnapshot,
                        onSnapshot: (snapshot) => onSnapshot(snapshot, index),
                        onError: (error) => onError(error, index),
                    });
                    return unsub;
                } else {
                    let details = await queryTaskDetails(query);
                    //doc to data
                    return details.map((doc) => doc.data());
                }
            })
        );

        return flatten(result);
    } catch (error) {
        throw error;
    }
};
