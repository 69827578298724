import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "../components-lagacy/Grid/GridContainer.jsx";
import GridItem from "../components-lagacy/Grid/GridItem.jsx";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "../components-lagacy/Card/Card.jsx";
import CardBody from "../components-lagacy/Card/CardBody.jsx";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "../components-lagacy/CustomButtons/Button.jsx";
//import { ReCaptcha } from "react-recaptcha-google";
import Paper from "@material-ui/core/Paper";

import { Redirect, withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//const callback = function () {};
//const expiredCallback = function () {};
import ChevronRight from "@material-ui/icons/ChevronRight";
import Swal from "sweetalert2";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";

import { COLORS } from "../constants/constants-lagacy";
import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";
import { PLATFORM } from "../constants/global.js";
import { getAllOutlets } from "../services/firestore/Outlet.js";
import { getOutletBranchesByCompany, queryOutletBranches } from "../services/firestore/Outlet_Branch.js";
import AuthContext from "../contexts/auth-context.js";
import { getAllCities } from "../services/firestore/City.js";
class AddBranch extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            surname: "",
            mobile: "",
            job_title: "",
            phone: "",
            ext_phone: "",
            En_name: "",
            Ar_name: "",
            city: "",
            district: "",
            street: "",
            building_Number: "",

            branch_email: "",
            branch_phone: "",
            fax: "",
            branch_municipal_license: "",
            expiry_date: new Date("01/01/2000"),
            form_number: 1,

            types: [
                {
                    value: "regular",
                    label: "Regular",
                },
                {
                    value: "supermarket",
                    label: "Supermarket",
                },
            ],
            selected_type: "regular",
            outlets: [],
            selected_outlet: "",
            outlet_branches: [],
            selected_outlet_branch: "",

            cities: [],
            supplier_name: "",

            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
    }

    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    init = async () => {
        try {
            const company = this.context.companyData;
            const cities = (await getAllCities()).map((doc) => doc.data());
            if (cities.length <= 0) {
                alert("No cities was found.");
                this.props.history.push({
                    pathname: PATHS.BRANCHES,
                    state: {
                        uid: this.props.uid,
                        id: this.props.id,
                    },
                });
                return;
            }

            const city = cities.find((c) => c.city_id === company.city_id).city_id;
            const outlets = (await getAllOutlets()).map((doc) => doc.data());

            if (outlets.length <= 0) {
                this.setState({
                    types: [{ value: "regular", label: "Regular" }],
                    selected_type: "regular",
                });
                return;
            }

            const selected_outlet = outlets[0].outlet_id;
            const outlet_branches = (
                await queryOutletBranches([
                    { key: "outlet_id", operator: "==", value: selected_outlet },
                    { key: "city_id", operator: "==", value: city },
                ])
            ).map((doc) => doc.data());

            let selected_outlet_branch = "";
            if (outlet_branches.length > 0) {
                selected_outlet_branch = outlet_branches[0].branch_id;
            }

            this.setState({
                supplier_name: company.En_short_name,
                cities,
                city,
                outlets,
                selected_outlet,
                outlet_branches,
                selected_outlet_branch,
            });
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        this.init();
    }

    validateFirstForm() {
        if (this.state.selected_type === "regular") {
            return this.state.city > 0 && this.state.En_name.length > 0 && this.state.Ar_name.length > 0;
        } else {
            return (
                this.state.city > 0 &&
                this.state.En_name.length > 0 &&
                this.state.Ar_name.length > 0 &&
                this.state.selected_outlet_branch.length > 0
            );
        }
    }

    validateSecondForm() {
        let validate = this.state.city > 0 && this.state.En_name.length > 0 && this.state.Ar_name.length > 0;

        return validate;
    }

    validateThirdForm() {
        let validate = this.state.city > 0 && this.state.En_name.length > 0 && this.state.Ar_name.length > 0;

        return validate;
    }

    citySelected = async (city) => {
        try {
            if (this.state.selected_type === "regular") {
                this.setState({ city });
                return;
            }

            const selected_outlet = this.state.selected_outlet;
            const outlet_branches = (
                await queryOutletBranches([
                    { key: "outlet_id", operator: "==", value: selected_outlet },
                    { key: "city_id", operator: "==", value: city },
                ])
            ).map((doc) => doc.data());

            let selected_outlet_branch = "";
            if (outlet_branches.length > 0) {
                selected_outlet_branch = outlet_branches[0].branch_id;
            }

            this.setState({
                city,
                outlet_branches,
                selected_outlet_branch,
            });
        } catch (error) {
            throw error;
        }
    };

    outletSelected = async (selected_outlet) => {
        try {
            const city = this.state.city;
            const outlet_branches = (
                await queryOutletBranches([
                    { key: "outlet_id", operator: "==", value: selected_outlet },
                    { key: "city_id", operator: "==", value: city },
                ])
            ).map((doc) => doc.data());

            let selected_outlet_branch = "";
            if (outlet_branches.length > 0) {
                selected_outlet_branch = outlet_branches[0].branch_id;
            }

            this.setState({
                selected_outlet,
                outlet_branches,
                selected_outlet_branch,
            });
        } catch (error) {
            throw error;
        }
    };

    handleChange = (event, id) => {
        if (id === "email" || id === "branch_email") {
            this.setState({ [id]: event.target.value.replace(/[^a-z0-9._%+-@]/, "") });
        } else if (id === "Ar_name") {
            this.setState({ [id]: event.target.value.replace(/[^\u0600-\u06FF-9._!@#$%^&*)(-=+/ ]/, "") });
        } else if (id === "En_name") {
            this.setState({ [id]: event.target.value.replace(/[^a-z-A-Z-9._!@#$%^&*)(-=+/ ]/, "") });
        } else if (id === "city") {
            this.citySelected(event.target.value);
        } else if (id === "selected_outlet") {
            this.outletSelected(event.target.value);
        } else {
            this.setState({ [id]: event.target.value });
        }
    };
    handleChangeFormNumber = (num) => {
        this.setState({ form_number: num });
    };
    handleChangeExpiryDate = (date) => {
        this.setState({ expiry_date: date });
    };

    writeBranchData = (e) => {
        this.setState({ loading: true });

        if (navigator.onLine) {
            let En_name_check =
                this.state.En_name === undefined
                    ? null
                    : this.state.En_name.charAt(0).toUpperCase() + this.state.En_name.slice(1);
            var branchRef = db
                .collection("MP_Branch")
                .where("mp_id", "==", this.props.id)
                .where("En_name", "==", En_name_check)
                .where("Ar_name", "==", this.state.Ar_name);
            branchRef
                .get()
                .then((snapshotBranch) => {
                    if (snapshotBranch.size > 0) {
                        this.showDialogMsg(
                            "Message",
                            "This branch you have entered is already registered!",
                            this.hideDialogMsg
                        );
                    } else {
                        let supplier_id = this.props.id;
                        let first_name = this.state.first_name === undefined ? null : this.state.first_name;
                        let surname = this.state.surname === undefined ? null : this.state.surname;
                        let mobile = this.state.mobile === undefined ? null : this.state.mobile;
                        let email = this.state.email === undefined ? null : this.state.email;
                        let job_title = this.state.job_title === undefined ? null : this.state.job_title;
                        let phone = this.state.phone === undefined ? null : this.state.phone;
                        let ext_phone = this.state.ext_phone === undefined ? null : this.state.ext_phone;
                        let street = this.state.street === undefined ? null : this.state.street;
                        let building_Number =
                            this.state.building_Number === undefined ? null : this.state.building_Number;
                        let city = this.state.city === undefined ? null : this.state.city;
                        let district = this.state.district === undefined ? null : this.state.district;
                        let En_name = this.state.En_name === undefined ? null : this.state.En_name;
                        let Ar_name = this.state.Ar_name === undefined ? null : this.state.Ar_name;
                        let branch_municipal_license =
                            this.state.branch_municipal_license === undefined
                                ? null
                                : this.state.branch_municipal_license;
                        let expiry_date = this.state.expiry_date === undefined ? null : this.state.expiry_date;
                        let branch_email = this.state.branch_email === undefined ? null : this.state.branch_email;
                        let branch_phone = this.state.branch_phone === undefined ? null : this.state.branch_phone;
                        let fax = this.state.fax === undefined ? null : this.state.fax;

                        let selected_type = this.state.selected_type;
                        let selected_outlet = selected_type === "supermarket" ? this.state.selected_outlet : "";
                        let selected_outlet_branch =
                            selected_type === "supermarket" ? this.state.selected_outlet_branch : "";

                        let branch_id = "100";
                        let swap = 99;
                        let datat = "";
                        var branchesRef = db.collection("MP_Branch");
                        branchesRef
                            .get()
                            .then((snapshot) => {
                                snapshot.forEach((doc) => {
                                    datat = doc.data();

                                    var id = Number((" " + datat.branch_id).toString().valueOf());
                                    if (swap < id) {
                                        swap = id;
                                    }
                                });
                            })
                            .then(() => {
                                if (navigator.onLine) {
                                    branch_id = (swap + 1).toString();
                                    //add branch info. to Branch collection
                                    branchesRef = db.collection("MP_Branch");
                                    branchesRef
                                        .doc(branch_id)
                                        .set({
                                            platform: PLATFORM,
                                            branch_id: branch_id,
                                            mp_id: supplier_id,
                                            type: selected_type,
                                            outlet_id: selected_outlet,
                                            outlet_branch_id: selected_outlet_branch,

                                            contact_person: {
                                                first_name: first_name.charAt(0).toUpperCase() + first_name.slice(1),
                                                surname: surname.charAt(0).toUpperCase() + surname.slice(1),
                                                mobile: mobile,
                                                job_title:
                                                    job_title === null
                                                        ? ""
                                                        : job_title.charAt(0).toUpperCase() + job_title.slice(1),
                                                phone: phone,
                                                ext_phone: ext_phone,
                                                email: email === null ? "" : email.toLowerCase(),
                                            },

                                            En_name: En_name.charAt(0).toUpperCase() + En_name.slice(1),
                                            Ar_name: Ar_name,
                                            city_id: city,
                                            district: district,
                                            street: street,
                                            building_Number: building_Number,

                                            contact_information: {
                                                email: branch_email === null ? "" : branch_email.toLowerCase(),
                                                phone: branch_phone,
                                                fax: fax,
                                            },

                                            municipal_license: {
                                                branch_municipal_license: branch_municipal_license,
                                                expiry_date: expiry_date,
                                            },

                                            status: "1",
                                        })
                                        .then(() => {
                                            this.setState({
                                                /*first_name: '',
                      surname: '',
                      mobile: '',
                      email:'',
                      job_title: '',
                      phone: '',
                      ext_phone: '',
                      street: '',
                      building_Number: '',
                      En_name: '',
                      Ar_name: '',
                      branch_municipal_license: '',
                      expiry_date: new Date("01/01/2000"),
                      //city: '',
                      district: '',
                      status:'',*/
                                                form_number: 1,
                                            });
                                            this.showDialogMsg(
                                                "Message",
                                                "Branch added Successfully!",
                                                this.hideDialogMsg
                                            );
                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            this.showDialogMsg(
                                                "Message",
                                                "There is something wrong!",
                                                this.hideDialogMsg
                                            );
                                        });
                                } else {
                                    this.showDialogMsg("Message", "you're offline!", this.hideDialogMsg);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                this.showDialogMsg("Message", "There is something wrong!", this.hideDialogMsg);
                            });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.showDialogMsg("Message", "There is something wrong!", this.hideDialogMsg);
                });
        } else {
            this.showDialogMsg("Message", "you're offline!", this.hideDialogMsg);
        }
    };

    goToSupplierBranchesListPage = (e) => {
        if (this.state.city > 0 || this.state.En_name.length > 0 || this.state.Ar_name.length > 0) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Discard All Changes!",
                text: "Are you sure you want to discard the changes?",
            }).then((result) => {
                if (!result.value) {
                    this.setState({ pathBack: PATHS.BRANCHES });
                }
            });
        } else {
            this.setState({ pathBack: PATHS.BRANCHES });
        }
    };
    //==================================================================================
    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    to={{
                        pathname: this.state.pathBack,
                        state: {
                            id: this.props.id,
                        },
                    }}
                />
            );
        } else {
            var fieldsArray = [];
            if (this.state.form_number === 1) {
                fieldsArray.push(
                    <GridContainer key={`fields-2`}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div className={classes.title} style={{ width: "3%" }}>
                                <div
                                    style={{
                                        fontWeight: "700",
                                        // backgroundColor: "#91041C",
                                        color: "#EEEEEE",
                                        height: "30px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "2px solid #575757",
                                        // borderRightColor: "#91041C",
                                        // borderBottomColor: "#91041C",
                                        backgroundColor: COLORS.primary,
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                ></div>
                            </div>
                            <div className={classes.title} style={{ width: "96.7%", marginLeft: "2px" }}>
                                <h4
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        // backgroundColor: "#EEEEEE",
                                        // color: "#91041C",
                                        backgroundColor: COLORS.background,
                                        color: COLORS.primary,
                                        height: "30px",
                                        paddingLeft: "10px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "1px solid #000000",
                                    }}
                                >
                                    Add New Branch (Branch Information)
                                </h4>
                            </div>
                        </div>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.En_name}
                                variant="filled"
                                fullWidth
                                id="En_name"
                                label="English Name*"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "En_name")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.Ar_name}
                                variant="filled"
                                fullWidth
                                id="Ar_name"
                                label="Arabic Name*"
                                dir="rtl"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "Ar_name")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.selected_type}
                                variant="filled"
                                fullWidth
                                id="selected_type"
                                label="Type*"
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "selected_type")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            >
                                {this.state.types.map((option) => (
                                    <MenuItem key={`type-item-${option.value}`} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.city}
                                variant="filled"
                                fullWidth
                                id="city"
                                label="City*"
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "city")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            >
                                {this.state.cities.map((option) => (
                                    <MenuItem key={`city-item-${option.city_id}`} value={option.city_id}>
                                        {option.En_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        {this.state.selected_type === "supermarket" && (
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            root: classes.textFieldRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textFieldLabel,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    select
                                    value={this.state.selected_outlet}
                                    variant="filled"
                                    fullWidth
                                    id="selected_outlet"
                                    label="Link to Outlet Company*"
                                    placeholder=""
                                    multiline
                                    style={{ textAlign: "left" }}
                                    //value={"" + this.props.input}
                                    onChange={(event) => this.handleChange(event, "selected_outlet")}
                                    className={classes.textField}
                                    margin="dense"
                                    maxRows={1}
                                >
                                    {this.state.outlets.map((option) => (
                                        <MenuItem key={`outlet-item-${option.outlet_id}`} value={option.outlet_id}>
                                            {option.En_short_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </GridItem>
                        )}
                        {this.state.selected_type === "supermarket" && (
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            root: classes.textFieldRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textFieldLabel,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    select
                                    value={this.state.selected_outlet_branch}
                                    variant="filled"
                                    fullWidth
                                    id="selected_outlet_branch"
                                    label="Link to Outlet Branch*"
                                    placeholder=""
                                    multiline
                                    style={{ textAlign: "left" }}
                                    //value={"" + this.props.input}
                                    onChange={(event) => this.handleChange(event, "selected_outlet_branch")}
                                    className={classes.textField}
                                    margin="dense"
                                    maxRows={1}
                                >
                                    {this.state.outlet_branches.map((option) => (
                                        <MenuItem
                                            key={`outlet-branch-item-${option.branch_id}`}
                                            value={option.branch_id}
                                        >
                                            {option.En_short_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </GridItem>
                        )}
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.district}
                                variant="filled"
                                fullWidth
                                id="district"
                                label="District"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "district")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.street}
                                variant="filled"
                                fullWidth
                                id="street"
                                label="Street"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "street")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.building_Number}
                                variant="filled"
                                fullWidth
                                id="building_Number"
                                label="Building No."
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "building_Number")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.branch_municipal_license}
                                variant="filled"
                                fullWidth
                                id="branch_municipal_license"
                                label="Municipal License Number"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "branch_municipal_license")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <MuiPickersUtilsProvider style={{ selectColor: "#91041C" }} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    InputProps={{
                                        classes: {
                                            root: classes.textFieldRoot,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.textFieldLabel,
                                            disabled: classes.disabled,
                                        },
                                    }}
                                    //disableToolbar
                                    className={classes.textField}
                                    fullWidth
                                    multiline
                                    inputVariant="filled"
                                    format="dd/MM/yyyy"
                                    // format="MM/dd/yyyy"
                                    margin="dense"
                                    id="expiry_date"
                                    label="Expiry Date"
                                    // selectColor="#91041C"
                                    // InputProps={{  }}
                                    style={{ selectColor: "#91041C" }}
                                    // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                    value={this.state.expiry_date}
                                    onChange={this.handleChangeExpiryDate}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.branch_email}
                                variant="filled"
                                fullWidth
                                id="branch_email"
                                label="Email"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "branch_email")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.branch_phone}
                                variant="filled"
                                fullWidth
                                id="branch_phone"
                                label="Phone"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "branch_phone")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.fax}
                                variant="filled"
                                fullWidth
                                id="fax"
                                label="Fax"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "fax")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <GridItem xs={12} sm={12} md={10}>
                            <Button
                                simple
                                size="lg"
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.accent,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    marginRight: 40,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                                className={classes.navLink}
                                onClick={this.goToSupplierBranchesListPage}
                            >
                                {" "}
                                Cancel{" "}
                            </Button>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                            <Button
                                simple
                                size="lg"
                                disabled={!this.validateFirstForm()}
                                onClick={() => this.handleChangeFormNumber(3)}
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.submit,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                            >
                                {" "}
                                Next <ChevronRight />{" "}
                            </Button>
                        </GridItem>
                        <Divider orientation="vertical" />
                    </GridContainer>
                );
            } else {
                fieldsArray.push(
                    <GridContainer key={`fields-1`}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div className={classes.title} style={{ width: "3%" }}>
                                <div
                                    style={{
                                        fontWeight: "700",
                                        // backgroundColor: "#91041C",
                                        color: "#EEEEEE",
                                        height: "30px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "2px solid #575757",
                                        backgroundColor: COLORS.primary,
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                ></div>
                            </div>
                            <div className={classes.title} style={{ width: "96.7%", marginLeft: "2px" }}>
                                <h4
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        backgroundColor: COLORS.background,
                                        color: COLORS.primary,
                                        height: "30px",
                                        paddingLeft: "10px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "1px solid #000000",
                                    }}
                                >
                                    Add New Branch (Contact Person Information)
                                </h4>
                            </div>
                        </div>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.first_name}
                                variant="filled"
                                fullWidth
                                id="first_name"
                                label="First Name"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "first_name")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.surname}
                                variant="filled"
                                fullWidth
                                id="surname"
                                label="Surname"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "surname")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.mobile}
                                variant="filled"
                                fullWidth
                                id="mobile"
                                label="Mobile"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "mobile")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.email}
                                variant="filled"
                                fullWidth
                                id="email"
                                label="Email"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "email")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.job_title}
                                variant="filled"
                                fullWidth
                                id="job_title"
                                label="Job Title"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "job_title")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.phone}
                                variant="filled"
                                fullWidth
                                id="phone"
                                label="Phone"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "phone")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.ext_phone}
                                variant="filled"
                                fullWidth
                                id="ext_phone"
                                label="Ext Phone"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "ext_phone")}
                                className={classes.textField}
                                margin="dense"
                                maxRows={1}
                            />
                        </GridItem>

                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>

                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                simple
                                size="lg"
                                disabled={!this.validateThirdForm()}
                                onClick={this.writeBranchData}
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.submit,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                            >
                                {" "}
                                Save{" "}
                            </Button>
                            <Button
                                simple
                                size="lg"
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.accent,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                                className={classes.navLink}
                                onClick={this.goToSupplierBranchesListPage}
                            >
                                {" "}
                                Cancel{" "}
                            </Button>
                        </GridItem>
                        <Divider orientation="vertical" />
                    </GridContainer>
                );
            }

            return (
                <div
                    style={{ backgroundColor: COLORS.background, margin: "0px", padding: "0px" }}
                    className={classes.pageHeader}
                >
                    <div className={`${classes.backdrop} ${this.state.loading ? null : classes.loadingHidden}`}></div>
                    <div className={`${classes.loaderContainer} ${this.state.loading ? null : classes.loadingHidden}`}>
                        <CircularProgress />
                    </div>

                    <div>
                        <div
                            style={{
                                marginBottom: "30px",
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "row",
                            }}
                            className={classNames(classes.textCenter, classes.container)}
                        >
                            <div style={{ width: "24%" }}>
                                <Grid style={{}}>
                                    <Card
                                        style={{
                                            backgroundColor: "#fff",
                                            boxShadow: "#96a1a9 0 4px 5px 0px",
                                            marginBottom: "40px",
                                        }}
                                    >
                                        <CardBody style={{ padding: "5px" }}>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Button
                                                    simple
                                                    size="lg"
                                                    // disabled={!this.validateFirstForm()}
                                                    onClick={() => this.handleChangeFormNumber(1)}
                                                    style={
                                                        this.state.form_number === 1
                                                            ? {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "10px",
                                                                  paddingBottom: "10px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: COLORS.primary,
                                                                  color: "#EEEEEE",
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                            : {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "5px",
                                                                  paddingBottom: "5px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: "transparent",
                                                                  color: "#000000",
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                    }
                                                >
                                                    {" "}
                                                    Branch Information{" "}
                                                </Button>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12}>
                                                <Button
                                                    simple
                                                    size="lg"
                                                    // disabled={!this.validateFirstForm()}
                                                    disabled={!this.validateSecondForm()}
                                                    onClick={() => this.handleChangeFormNumber(3)}
                                                    style={
                                                        this.state.form_number === 3
                                                            ? {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "10px",
                                                                  paddingBottom: "10px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: COLORS.primary,
                                                                  color: "#EEEEEE",
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                            : {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "5px",
                                                                  paddingBottom: "5px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: "transparent",
                                                                  color: "#000000",
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                    }
                                                >
                                                    {" "}
                                                    Contact Person Information{" "}
                                                </Button>
                                            </GridItem>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </div>
                            <div className={classes.container} pl={5} style={{ width: "75%" }}>
                                <Grid>
                                    <Card color="primary">
                                        <CardBody>
                                            {fieldsArray}

                                            {/* <div style={{ width: "100%" }}>
                                                    <Button
                                                        simple
                                                        onClick={this.goToSupplierBranchesListPage}
                                                        size="lg"
                                                        // disabled={!this.validateThirdForm()}
                                                        style={{
                                                            marginTop: 10,
                                                            marginLeft: "10px",
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            textTransform: "none",
                                                            backgroundColor: "transparent",
                                                            color: "#91041C",
                                                            fontWeight: "400",
                                                            float: "left",
                                                        }}
                                                        type="submit"
                                                    >
                                                        <ChevronLeft /> Back{" "}
                                                    </Button>
                                                </div> */}
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: COLORS.accent }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}
AddBranch.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(withRouter(AddBranch));
