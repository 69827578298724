import { serverTimestamp } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { getCurrentTerms } from "./Terms_And_Agreements";

export const addNewTermsAgreement = async (uid) => {
    try {
        const currentTerms = await getCurrentTerms();
        const ref = db.collection(COLLECTIONS.TERMS_AGREEMENTS).doc();

        return ref.set({
            id: ref.id,
            terms_id: currentTerms.id,
            user_id: uid,
            user_type: "service-provider-admin",
            agreement_date: serverTimestamp(),
        });
    } catch (error) {
        throw error;
    }
};
