import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    darken,
    Grid,
    lighten,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import { getMerchandiserName } from "../../../helpers/merchandiser-utils";
// import { getMerchandiserRoute, setupRouteDetails } from "../../../services/firestore/Route";
import { getMerchandisersByMPBranch } from "../../../services/firestore/Merchandiser";
import { MERCHANDISER_ACCOUNT_STATUS, TASKS_STATUS, COLORS } from "../../../constants/constants-lagacy";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomDatePicker from "../../../components-lagacy/CustomInput/CustomDatePicker";

import { getOutletBranches } from "../../../services/firestore/Outlet_Branch";
import { convertMinutesToHoursAndMinutes } from "../../../helpers/date-utils";
import TransitionAlert from "../../../core-ui/custom/transition-alert";
import { getBranchesByMPID } from "../../../services/firestore/MP_Branch";

import { firestore as db } from "../../../services/firebase";
import { getSupplierBranchesByMPBranchCity } from "../../../services/firestore/Supplier_Branch";
import { populateTaskHeader, queryTasks } from "../../../services/firestore/Task";
import { flatten } from "lodash/fp";
import { getRoutesByTasks } from "../../../services/firestore/Route";
import RouteEditDialog from "../../../components-lagacy/Dialog/RouteEditDialog";

const useStyles = (theme) => ({
    root: {
        position: "relative",
        // marginTop: 20,
        width: "100%",
        maxWidth: 1920,
    },
    container: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
    },

    statusButtons: {
        margin: "30px 0",
    },

    gridItemBtn: {
        margin: "0 5px",
    },

    statusBtn: {
        cursor: "default",
    },

    contentCard: {
        width: "100%",
        // maxWidth: "1290px",
    },
    loader: {
        position: "relative",
        marginTop: "130px",
    },
    merchandiserCardContainer: {},
    cardHeader: {
        textAlign: "center",
        backgroundColor: COLORS.background,
    },

    inactiveCardHeader: {
        textAlign: "center",
        backgroundColor: "#d7d7d7",
    },

    scheduleCardHeader: {
        backgroundColor: lighten(COLORS.accent, 0.8),
    },
    headerText: {
        color: COLORS.primary,
    },
    inactiveHeaderText: {
        color: "#565656",
    },
    scheduleHeaderText: {
        color: COLORS.accent,
    },
    subheaderText: {
        color: COLORS.accent,
    },
    inactiveSubheaderText: {
        color: "#565656",
    },

    cardItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid black",
        marginBottom: "8px",
        padding: "8px",
        backgroundColor: "#fff",
        "& p": {
            margin: 0,
        },
    },
    controlsCard: {
        width: "100%",
        maxWidth: "1200px",
        margin: "10px auto",
        overflow: "visible",
        "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
            "@media (min-width: 960px)": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },

    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    filterInput: {
        width: "100%",
        height: "100%",
    },
    button: {
        width: "100%",
        height: "100%",
        backgroundColor: COLORS.submit,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
            color: "#fff",
        },
    },

    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },

    disabledBtn: {
        backgroundColor: "#c9c9c9",
        color: "#00000042",
    },

    completed: {
        border: "2px solid #000000",
        color: "#000000",
    },
    open: {
        border: "2px solid #575757",
        color: "#000000",
    },

    scheduled: { border: "2px solid #4BB543", color: "#000000" },

    noShow: { border: "2px solid #cf0404", color: "#000000" },

    underProcess: { border: "2px solid #36A2EB", color: "#000000" },

    finished: { border: "2px solid #A67A44", color: "#000000" },

    auditing: { border: "2px solid #A67A44", color: "#000000" },
    canceled: { border: "2px solid #a0a0a0", color: "#000000" },
    default: { border: "2px solid #575757", color: "#000000" },

    routeCardActions: {
        backgroundColor: COLORS.background,
        justifyContent: "center",
    },
});

const createButton = (id, label, onClick, style, classes) => {
    return (
        <Button id={id} onClick={onClick} style={style} className={classes}>
            {label}
        </Button>
    );
};

class Content extends React.Component {
    state = {
        date: new Date(new Date().setHours(0, 0, 0, 0)),
        merchandisers: [
            // {
            //      merchandiserDoc, days: {sun: [scheduleId, ], mon, tue, wed, tue, fri, sat}
            // },
        ],
        notAssignedTasksOrder: [],

        merchandisersOrder: [],

        tasks: [],

        avgTravelTime: 15,
        mpBranchesList: [],
        selectedMPBranch: null,

        alertOpened: false,
        alertMsg: "",
        alertSeverity: "info",

        routes: [],

        isRouteEditDialogOpened: false,
        routeIndex: -1,

        loading: true,
    };

    classes = this.props.classes;

    createStatusButtons = () => {
        const selectedStyle = { backgroundColor: "#EEEEEE", color: "#91041C", borderBottom: "3px solid #91041C" };
        const currentIndex = this.state.selectedStatusIndex;

        return [
            // createButton(
            //     "all-status-btn",
            //     "All",
            //     () => this.statusSelected(0),
            //     currentIndex === 0 ? selectedStyle : { backgroundColor: "#91041C", color: "#EEEEEE" },
            //     this.classes.statusBtn
            // ),
            createButton(
                "open-status-btn",
                this.getStatusLabel(TASKS_STATUS.OPEN),
                () => {},
                currentIndex === 1 ? selectedStyle : this.getStatusStyle(TASKS_STATUS.OPEN),
                this.classes.statusBtn
            ),
            createButton(
                "booked-status-btn",
                this.getStatusLabel(TASKS_STATUS.BOOKED),
                () => {},
                currentIndex === 2 ? selectedStyle : this.getStatusStyle(TASKS_STATUS.BOOKED),
                this.classes.statusBtn
            ),
            createButton(
                "process-status-btn",
                this.getStatusLabel(TASKS_STATUS.UNDER_PROCESS),
                () => {},
                currentIndex === 3 ? selectedStyle : this.getStatusStyle(TASKS_STATUS.UNDER_PROCESS),
                this.classes.statusBtn
            ),
            createButton(
                "auditing-status-btn",
                this.getStatusLabel(TASKS_STATUS.FINISHED),
                () => {},
                currentIndex === 4 ? selectedStyle : this.getStatusStyle(TASKS_STATUS.FINISHED),
                this.classes.statusBtn
            ),
            createButton(
                "completed-status-btn",
                this.getStatusLabel(TASKS_STATUS.NO_SHOW),
                () => {},
                currentIndex === 5 ? selectedStyle : this.getStatusStyle(TASKS_STATUS.NO_SHOW),
                this.classes.statusBtn
            ),
            createButton(
                "canceled-status-btn",
                this.getStatusLabel(TASKS_STATUS.CANCELED),
                () => {},
                currentIndex === 6 ? selectedStyle : this.getStatusStyle(TASKS_STATUS.CANCELED),
                this.classes.statusBtn
            ),
        ];
    };

    getStatusLabel = (id) => {
        switch (id) {
            case TASKS_STATUS.COMPLETED:
                return "Completed";
            case TASKS_STATUS.OPEN:
                return "Not Assigned";
            case TASKS_STATUS.RESERVED:
            case TASKS_STATUS.BOOKED:
                return "Scheduled";
            case TASKS_STATUS.NO_SHOW:
                return "No Show";
            case TASKS_STATUS.DELAY:
            case TASKS_STATUS.UNDER_PROCESS:
                return "Under Process";
            case TASKS_STATUS.FINISHED:
                return "Finished";
            case TASKS_STATUS.REJECTED:
            case TASKS_STATUS.AUDITING:
            case TASKS_STATUS.FINISHED_LATE:
            case TASKS_STATUS.FINISHED_R:
                return "Auditing";
            case TASKS_STATUS.CANCELED:
                return "Canceled";
            default:
                return "Unknown";
        }
    };

    getStatusStyleClass = (id) => {
        switch (id) {
            case TASKS_STATUS.COMPLETED:
                return this.classes.completed;
            case TASKS_STATUS.OPEN:
                return this.classes.open;
            case TASKS_STATUS.RESERVED:
            case TASKS_STATUS.BOOKED:
                return this.classes.scheduled;
            case TASKS_STATUS.NO_SHOW:
                return this.classes.noShow;
            case TASKS_STATUS.DELAY:
            case TASKS_STATUS.UNDER_PROCESS:
                return this.classes.underProcess;
            case TASKS_STATUS.FINISHED:
                return this.classes.finished;

            case TASKS_STATUS.REJECTED:
            case TASKS_STATUS.AUDITING:
            case TASKS_STATUS.FINISHED_LATE:
            case TASKS_STATUS.FINISHED_R:
                return this.classes.auditing;

            case TASKS_STATUS.CANCELED:
                return this.classes.canceled;

            default:
                return this.classes.default;
        }
    };

    getStatusStyle = (id) => {
        switch (id) {
            case TASKS_STATUS.COMPLETED:
                return { border: "2px solid #000000", color: "#000000" };
            case TASKS_STATUS.OPEN:
                return { border: "2px solid #575757", color: "#000000" };
            case TASKS_STATUS.RESERVED:
            case TASKS_STATUS.BOOKED:
                return { border: "2px solid #4BB543", color: "#000000" };
            case TASKS_STATUS.NO_SHOW:
                return { border: "2px solid #cf0404", color: "#000000" };
            case TASKS_STATUS.DELAY:
            case TASKS_STATUS.UNDER_PROCESS:
                return { border: "2px solid #36A2EB", color: "#000000" };
            case TASKS_STATUS.FINISHED:
                return { border: "2px solid #A67A44", color: "#000000" };
            case TASKS_STATUS.REJECTED:
            case TASKS_STATUS.AUDITING:
            case TASKS_STATUS.FINISHED_LATE:
            case TASKS_STATUS.FINISHED_R:
                return { border: "2px solid #A67A44", color: "#000000" };
            case TASKS_STATUS.CANCELED:
                return { border: "2px solid #a0a0a0", color: "#000000" };
            default:
                return { border: "2px solid #575757", color: "#000000" };
        }
    };

    init = async () => {
        try {
            //get supplier branches list
            const mpBranchesList = (await getBranchesByMPID(this.props.mpID)).map((sp) => ({
                id: sp.id,
                name: sp.data().En_name,
                info: sp.data(),
            }));

            if (mpBranchesList.length === 0) {
                this.setState({ loading: false });
                return;
            }

            //get supplier branches with the same location as selected mp branch
            let supplierBranches = await getSupplierBranchesByMPBranchCity(
                this.props.mpID,
                mpBranchesList[0].info.city_id
            );

            supplierBranches = supplierBranches.map((s) => s.data());

            this.setState(
                {
                    supplierBranches: supplierBranches,
                    mpBranchesList: mpBranchesList,
                    selectedMPBranch: mpBranchesList[0],
                },
                () => {
                    this.search();
                }
            );
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    downloadTasks = async (date, supplierBranches) => {
        if (date === null || supplierBranches.length <= 0) return [];

        try {
            //get tasks under selected mp branch coverage on a specific date
            let tasks = await Promise.all(
                supplierBranches.map(async (branch) =>
                    queryTasks({
                        type: "mp-insource",
                        date: date,
                        mpID: this.props.mpID,
                        supplierBranchID: +branch.branch_id,
                    })
                )
            );

            tasks = flatten(tasks);

            if (tasks.length <= 0) return [];

            tasks = tasks.map((t) => t.data());

            // populate task headers
            tasks = await Promise.all(tasks.map(populateTaskHeader));

            tasks = tasks.map((task) => ({
                id: `${task.task_id}`,
                outletBranch: "",
                data: { ...task },
            }));

            // tasks = await Promise.all(
            //     tasks.map(async (task) => {
            //         if (!task.data.only_header) return task;

            //         let schedule = await getScheduleByTask(task.data);
            //         if (!schedule) {
            //             throw new Error(`Could not find schedule for the task ID: ${task.id}`);
            //         }

            //         schedule = schedule.data();
            //         const modifiedProps = task.data.modified_properties ?? [];
            //         task.data.uid = modifiedProps.indexOf("uid") !== -1 ? task.data.uid : schedule.merchandiser_id;
            //         task.data.state =
            //             modifiedProps.indexOf("state") !== -1
            //                 ? task.data.state
            //                 : task.data.uid
            //                 ? TASKS_STATUS.BOOKED
            //                 : TASKS_STATUS.OPEN;
            //         task.data.duration = schedule.duration;

            //         return task;
            //     })
            // );

            //get unique outlet branch IDs
            let outletBranchIDs = tasks.reduce((arr, task) => {
                if (arr.includes(task.data.outlet_branch_id)) return arr;
                return [...arr, `${task.data.outlet_branch_id}`];
            }, []);

            //get outlet branch info
            let outletBranches = (await getOutletBranches(outletBranchIDs)).map((ob) => ob.data());
            tasks.forEach((task) => {
                let obID = `${task.data.outlet_branch_id}`;
                task.outletBranch = outletBranches.find((ob) => ob.branch_id === obID).En_short_name;
            });

            return tasks;
        } catch (error) {
            throw error;
        }
    };

    getListFromDropId = (droppableId) => {
        if (droppableId === "unassigned-visits") return this.state.notAssignedTasksOrder;

        let route = this.state.routes.find((route) => route.id === droppableId);
        return route.taskOrder || [];
    };

    updateOrderList = (droppableId, newList) => {
        if (droppableId === "unassigned-visits") {
            let tasks = [...this.state.tasks];
            newList.forEach((taskID, index) => {
                const task = tasks.find((t) => t.id === taskID);
                if (task.data.state !== TASKS_STATUS.CANCELED) {
                    task.data.state = TASKS_STATUS.OPEN;
                }
                task.data.uid = null;
                task.data.route_id = null;
                task.data.dailyOrder = index;
            });
            newList.forEach((taskID) => {
                const task = tasks.find((t) => t.id === taskID);
                let projectTasks = tasks.filter(
                    (t) => t.data.project_id === task.data.project_id && t.data.route_id === task.data.route_id
                );
                projectTasks.sort((a, b) => (a.data.dailyOrder < b.data.dailyOrder ? -1 : 1));
                projectTasks.forEach((task, index) => (task.data.order = index));
            });
            this.setState({ notAssignedTasksOrder: [...newList], tasks: tasks });
        } else {
            let tasks = [...this.state.tasks];
            let routes = this.state.routes;
            let route = routes.find((route) => route.id === droppableId);
            newList.forEach((taskID, index) => {
                const task = tasks.find((t) => t.id === taskID);

                if (route.tasksOwner) {
                    task.data.state = task.data.state === TASKS_STATUS.OPEN ? TASKS_STATUS.BOOKED : task.data.state;
                } else {
                    task.data.state =
                        task.data.state === TASKS_STATUS.BOOKED || task.data.state === TASKS_STATUS.RESERVED
                            ? TASKS_STATUS.OPEN
                            : task.data.state;
                }
                task.data.route_id = route.id;
                task.data.uid = route.tasksOwner;
                task.data.dailyOrder = index;
            });

            newList.forEach((taskID) => {
                const task = tasks.find((t) => t.id === taskID);

                let projectTasks = tasks.filter(
                    (t) => t.data.project_id === task.data.project_id && t.data.route_id === task.data.route_id
                );
                projectTasks.sort((a, b) => (a.data.dailyOrder < b.data.dailyOrder ? -1 : 1));
                projectTasks.forEach((task, index) => (task.data.order = index));
            });
            route.taskOrder = [...newList];

            this.setState({ routes: routes, tasks: tasks });
        }
    };

    isTaskEligibleToDrag = (taskID) => {
        const task = this.state.tasks.find((t) => t.id === taskID);
        return (
            task.data.state === TASKS_STATUS.OPEN ||
            task.data.state === TASKS_STATUS.BOOKED ||
            task.data.state === TASKS_STATUS.RESERVED ||
            task.data.state === TASKS_STATUS.CANCELED
        );
    };

    checkDestinationIsActive = (merchandiserID) => {
        const merchandiser = this.state.merchandisers.find((mr) => mr.info.uid === merchandiserID);
        const merchStatus = merchandiser?.info?.account_information?.state;

        return merchStatus !== MERCHANDISER_ACCOUNT_STATUS.INACTIVE;
    };

    onDragEnd = (result) => {
        //droppableId could be 'unassigned-visits' or a merchandiser uid, draggableId is schedule path: supplier branch id:outlet branch id:schedule id
        const { destination, source, draggableId } = result;
        if (!destination) return;
        // if (destination.droppableId !== "supermarkets" && !this.checkDestinationIsActive(destination.droppableId))
        //     return;

        // dropped on the same route card and on same order

        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        //check task status before apply the drag and drop
        if (!this.isTaskEligibleToDrag(draggableId)) {
            return;
        }

        //re-order in the same card
        if (destination.droppableId === source.droppableId) {
            let list = this.getListFromDropId(source.droppableId);

            let newList = Array.from(list);
            newList.splice(source.index, 1);
            newList.splice(destination.index, 0, draggableId);

            this.updateOrderList(source.droppableId, newList);
            return;
        }

        //the schedule is travelled from one to another
        let sourceList = Array.from(this.getListFromDropId(source.droppableId));
        let destinationList = Array.from(this.getListFromDropId(destination.droppableId));

        //remove
        sourceList.splice(source.index, 1);

        //add at the specifiied order
        destinationList.splice(destination.index, 0, draggableId);

        this.updateOrderList(source.droppableId, sourceList);
        this.updateOrderList(destination.droppableId, destinationList);
    };

    findScheduleInProject = (scheduleData) => {
        let projectFullData = this.props.projectFullData;
        let supplierBranchID = scheduleData.supplier_branch_id;
        let outletBranchID = scheduleData.outlet_branch_id;
        let scheduleID = scheduleData.id;

        let supplierBranch = projectFullData.supplierBranches.find((sb) => sb.doc.id === supplierBranchID);
        let outletBranch = supplierBranch.outletBranches.find((ob) => ob.doc.id === outletBranchID);
        let schedule = outletBranch.schedules.find((sch) => sch.doc.id === scheduleID);

        return schedule;
    };

    // getMPBranchMerchandisers = async (mpBranchID) => {
    //     let merchandisers = [];
    //     try {
    //         merchandisers = await getMerchandisersByMPBranch(mpBranchID);
    //         // merchandisers = await Promise.all(
    //         //     merchandisers.map(async (merch) => {
    //         //         let route = await getMerchandiserRoute(merch.id);
    //         //         if (!route.exists) throw new Error(`could not find merchandiser's route plan ${merch.id}`);

    //         //         return { info: merch.data(), route: route.data() };
    //         //     })
    //         // );

    //         return merchandisers;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    search = async () => {
        const { date, selectedMPBranch, supplierBranches } = this.state;

        if (!selectedMPBranch || !date) {
            return;
        }

        this.setState({ loading: true });
        try {
            //get merchandisers based on the current selected supplier branch
            //get all tasks
            let [merchandisers, tasks] = await Promise.all([
                getMerchandisersByMPBranch(selectedMPBranch.id),
                this.downloadTasks(date, supplierBranches),
            ]);

            merchandisers = merchandisers.map((m) => m.data());
            //get routes from tasks
            const routes = (await getRoutesByTasks(tasks.map((t) => t.data))).map((r) => r.data());

            //init cards of merchandisers order array
            // merchandisers.sort((a, b) => {
            //     const nameA = getMerchandiserName(a.info);
            //     const nameB = getMerchandiserName(b.info);
            //     return nameA < nameB ? -1 : 1;
            // });
            // const merchandisersOrder = merchandisers.map((mr) => mr.info.uid);

            //calculate the order for each route visits
            for (const route of routes) {
                route.taskOrder = [];
                route.tasksOwner = null;

                let rTasks = tasks.filter((t) => t.data.route_id === route.id);

                rTasks.sort((a, b) => {
                    if (a.data.dailyOrder !== null && b.data.dailyOrder !== null) {
                        return a.data.dailyOrder < b.data.dailyOrder ? -1 : 1;
                    }

                    if (a.data.project_id < b.data.project_id) return -1;
                    if (a.data.project_id > b.data.project_id) return 1;

                    return a.data.order < b.data.order ? -1 : 1;
                });

                rTasks.forEach((t, index) => (t.data.dailyOrder = index));
                route.taskOrder = rTasks.map((t) => t.id);

                //the task must belong to 1 merchandiser, if more throw error
                try {
                    if (rTasks.length > 0) {
                        const first = rTasks[0].data.uid;
                        const same = rTasks.every((task) => task.data.uid === first);
                        if (!same)
                            throw new Error(
                                `error in route tasks: there are different merchandisers in 1 route. route id ${route.id}`
                            );
                    }
                } catch (error) {
                    console.log(error);
                }

                //if route has no tasks get merchandiser from route, otherwise from tasks
                route.tasksOwner = merchandisers.find((mr) => mr.uid === route.merchandiser_id)?.uid || null;
                if (rTasks.length > 0) {
                    route.tasksOwner = merchandisers.find((mr) => mr.uid === rTasks[0].data.uid)?.uid || null;
                }
            }

            //calculate free visits (not in a route plan) order
            let notAssignedTasksOrder = tasks.filter((t) => t.data.route_id === null);
            notAssignedTasksOrder.sort((a, b) => {
                if (a.data.dailyOrder !== null && b.data.dailyOrder !== null) {
                    return a.data.dailyOrder < b.data.dailyOrder ? -1 : 1;
                }

                if (a.data.project_id < b.data.project_id) return -1;
                if (a.data.project_id > b.data.project_id) return 1;

                return a.data.order < b.data.order ? -1 : 1;
            });
            notAssignedTasksOrder.forEach((t, index) => (t.data.dailyOrder = index));
            notAssignedTasksOrder = notAssignedTasksOrder.map((t) => t.id);

            this.setState({
                tasks: tasks,
                routes: routes,
                merchandisers: merchandisers,
                // merchandisersOrder: merchandisersOrder,
                notAssignedTasksOrder: notAssignedTasksOrder,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    onSupplierBranchSelect = (supplierBranch) => {
        this.setState({ selectedMPBranch: supplierBranch }, this.search);
    };

    onDateChange = (value) => {
        this.setState({ date: value }, this.search);
    };

    onSave = async () => {
        this.setState({ loading: true });
        try {
            const tasks = this.state.tasks;
            await Promise.all(
                tasks.map(async (task) => {
                    await db
                        .collection("Task")
                        .doc(task.id)
                        .update({
                            dailyOrder: task.data.dailyOrder,
                            order: task.data.order,
                            state: task.data.state,
                            route_id: task.data.route_id,
                            uid: task.data.uid,
                            modified_properties: ["dailyOrder", "order", "state", "uid", "route_id"],
                        });
                })
            );

            this.setState({
                alertMsg: "Save Successful.",
                alertSeverity: "success",
                alertOpened: true,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    openRouteCardEditor = (index) => {
        this.setState({
            isRouteEditDialogOpened: true,
            routeIndex: index,
        });
    };

    closeRouteCardEditor = () => {
        this.setState({
            isRouteEditDialogOpened: false,
            routeIndex: -1,
        });
    };

    handleRouteDialogSave = (route, newValues) => {
        const { merchandiser } = newValues;
        let tasks = [...this.state.tasks];

        //check if the every route tasks is open, booked, reserved, or canceled
        const allowedToChange = tasks.every((task) => {
            //if it is not part of route, skip it by returning true
            if (task.data.route_id !== route.id) return true;

            const state = task.data.state;
            const index = [
                TASKS_STATUS.OPEN,
                TASKS_STATUS.BOOKED,
                TASKS_STATUS.RESERVED,
                TASKS_STATUS.CANCELED,
            ].indexOf(state);
            return index !== -1;
        });

        if (!allowedToChange) {
            this.setState({
                alertOpened: true,
                alertMsg:
                    "Some of the tasks status in the route (e.g under process or finished) cannot be allowed to switch between routes or merchandisers.",
                alertSeverity: "error",
            });
            return;
        }
        for (const task of tasks) {
            if (task.data.route_id !== route.id) continue;
            task.data.uid = merchandiser?.uid || null;
            if (task.data.uid) {
                if (task.data.state === TASKS_STATUS.OPEN) {
                    task.data.state = TASKS_STATUS.BOOKED;
                }
            } else {
                if (task.data.state === TASKS_STATUS.BOOKED || task.data.state === TASKS_STATUS.RESERVED) {
                    task.data.state = TASKS_STATUS.OPEN;
                }
            }
        }

        let newRoutes = [...this.state.routes];
        newRoutes[this.state.routeIndex].tasksOwner = merchandiser?.uid || null;

        this.setState({ tasks, routes: newRoutes, isRouteEditDialogOpened: false, routeIndex: -1 });
    };

    onAvgTravelTimeChange = (e) => {
        let value = e.target.value;
        if (value < 0 || value === "") value = 0;
        let num = Number(value);
        this.setState({ avgTravelTime: num });
    };

    setAlertOpen = (bool) => {
        this.setState({ alertOpened: bool });
    };

    componentDidMount() {
        this.init();
    }
    render() {
        const classes = this.classes;
        const statusButtons = this.createStatusButtons();

        return (
            <div className={classes.root}>
                <TransitionAlert
                    variant="filled"
                    open={this.state.alertOpened}
                    setOpen={this.setAlertOpen}
                    className={classes.stickyAlert}
                    severity={this.state.alertSeverity}
                >
                    {this.state.alertMsg}
                </TransitionAlert>

                {/* status buttons */}
                <Grid container justifyContent="center" className={classes.statusButtons}>
                    {statusButtons.map((button, index) => (
                        <Grid item className={classes.gridItemBtn} key={`status-btn-${index}`}>
                            {button}
                        </Grid>
                    ))}
                </Grid>

                <Card className={classes.controlsCard}>
                    <Grid container spacing={1}>
                        {/* supplier branches list */}
                        <Grid item xs={12} sm={6} md={4} className={classes.controlsGridItem} style={{ paddingTop: 0 }}>
                            <Autocomplete
                                // autoSelect
                                disableClearable
                                id="supplier-branches-list"
                                className={classes.autoCompleteList}
                                options={this.state.mpBranchesList}
                                value={this.state.selectedMPBranch}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Choose Your Branches" variant="outlined" />
                                )}
                                onChange={(event, supplierBranch) => this.onSupplierBranchSelect(supplierBranch)}
                            />
                        </Grid>

                        {/* Date */}
                        <Grid item xs={12} sm={6} md={4} className={classes.controlsGridItem}>
                            <CustomDatePicker
                                rootProps={{
                                    id: "date-picker",
                                    label: "Date",
                                    value: this.state.date,
                                    onChange: this.onDateChange,
                                    className: "",
                                    inputVariant: "outlined",
                                }}
                            />
                        </Grid>
                        {/* average travel time */}
                        {/* <Grid item xs={12} sm={6} md={3} className={classes.controlsGridItem}>
                            <TextField
                                disabled={true}
                                variant="outlined"
                                type="number"
                                label="Average Travel Time"
                                placeholder="Average Travel Time (In minutes)"
                                className={classes.filterInput}
                                onChange={this.onAvgTravelTimeChange}
                                value={this.state.avgTravelTime}
                            />
                        </Grid> */}
                        {/* search button */}
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={classes.controlsGridItem}
                            style={{ paddingBottom: 0 }}
                        >
                            <Button
                                className={classes.button}
                                classes={{ disabled: classes.disabledBtn }}
                                onClick={this.onSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

                <FullPageLoader loading={this.state.loading} />

                {/* cards */}
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Grid container justifyContent="center">
                        {/* routes */}
                        <Grid container item spacing={2} xs={6} sm={9} md={10} style={{ marginRight: 2 }}>
                            {this.state.routes.map((route, routeIndex) => {
                                const {
                                    id,
                                    name,
                                    project_id,
                                    // supplier_branch_id,
                                    // merchandiser_id,
                                    // plan,
                                    avg_travel_time,
                                    tasksOwner,
                                } = route;

                                //get tasks by daily order
                                const tasks = route.taskOrder.map((taskID) =>
                                    this.state.tasks.find((t) => t.id === taskID)
                                );

                                // const day = getDayName(this.state.date.getDay());

                                let totalDuration = tasks.reduce((sum, task) => sum + task.data.duration, 0);
                                totalDuration += tasks.length === 0 ? 0 : (tasks.length - 1) * avg_travel_time;
                                const durationStr = convertMinutesToHoursAndMinutes(totalDuration);

                                const merchandiser = this.state.merchandisers.find((m) => m.uid === tasksOwner);
                                const merchName = merchandiser ? getMerchandiserName(merchandiser) : "Not Assigned";
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        key={`${id}-daily-card`}
                                        className={classes.merchandiserCardContainer}
                                    >
                                        <Card variant="outlined">
                                            <CardHeader
                                                title={name}
                                                subheader={
                                                    <>
                                                        <b style={{ color: "black" }}>{project_id}</b>
                                                        <br />
                                                        {`${merchName}${durationStr ? ` (${durationStr} )` : ""}`}
                                                    </>
                                                }
                                                className={classes.cardHeader}
                                                titleTypographyProps={{
                                                    className: classes.headerText,
                                                }}
                                                subheaderTypographyProps={{
                                                    className: classes.subheaderText,
                                                }}
                                            ></CardHeader>

                                            <Droppable droppableId={id}>
                                                {(provided) => (
                                                    <CardContent
                                                        innerRef={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        {tasks.map((task, index) => {
                                                            const className = this.getStatusStyleClass(task.data.state);
                                                            return (
                                                                <Draggable
                                                                    key={`route-task-item-${task.id}`}
                                                                    draggableId={`${task.id}`}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <Typography
                                                                            component="div"
                                                                            className={`${classes.cardItem} ${className}`}
                                                                            innerRef={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <p style={{ textAlign: "left" }}>
                                                                                {task.outletBranch || "No Data"}
                                                                            </p>
                                                                            <p
                                                                                style={{ textAlign: "right" }}
                                                                            >{`${task.data.duration} Min`}</p>
                                                                        </Typography>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </CardContent>
                                                )}
                                            </Droppable>
                                            <CardActions className={classes.routeCardActions}>
                                                <Button
                                                    size="small"
                                                    onClick={() => this.openRouteCardEditor(routeIndex)}
                                                >
                                                    Edit
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>

                        {/* not assigned task list */}

                        <Grid container item xs={6} sm={3} md={2}>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <CardHeader
                                        title={"Supermarkets"}
                                        className={`${classes.cardHeader} ${classes.scheduleCardHeader}`}
                                        titleTypographyProps={{ className: classes.scheduleHeaderText }}
                                        subheaderTypographyProps={{ className: classes.subheaderText }}
                                    />
                                    <Droppable droppableId={`unassigned-visits`}>
                                        {(provided) => (
                                            <CardContent innerRef={provided.innerRef} {...provided.droppableProps}>
                                                {this.state.notAssignedTasksOrder.map((taskID, index) => {
                                                    const task = this.state.tasks.find((task) => task.id === taskID);
                                                    const className = this.getStatusStyleClass(task.data.state);
                                                    return (
                                                        <Draggable
                                                            key={`vacant-item-${task.id}`}
                                                            draggableId={`${task.id}`}
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <Typography
                                                                    component="div"
                                                                    className={`${classes.cardItem} ${className}`}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    innerRef={provided.innerRef}
                                                                >
                                                                    <p style={{ textAlign: "left" }}>
                                                                        {task.outletBranch || "No Data"}
                                                                    </p>
                                                                    <p
                                                                        style={{ textAlign: "right" }}
                                                                    >{`${task.data.duration} Min`}</p>
                                                                </Typography>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </CardContent>
                                        )}
                                    </Droppable>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <RouteEditDialog
                        inOperations={true}
                        open={this.state.isRouteEditDialogOpened}
                        onClose={this.closeRouteCardEditor}
                        onSubmit={this.handleRouteDialogSave}
                        title="Route Editor"
                        submitText="Save"
                        route={this.state.isRouteEditDialogOpened ? this.state.routes[this.state.routeIndex] : null}
                        merchandisersList={this.state.merchandisers}
                    />
                </DragDropContext>
            </div>
        );
    }
}

export default withStyles(useStyles)(Content);
