import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import PageSubHeader from "../../../components-lagacy/Header/PageSubHeader";
import { Grid, TextField } from "@material-ui/core";
import CustomTextField from "../../../components-lagacy/CustomInput/CustomTextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
    textField: {
        margin: 0,
        height: "56px",
    },
    fieldsWrap: {
        marginTop: "10px",
    },
});

let VisitInfoSection = (props) => {
    const classes = useStyles(props);

    let createFieldEl = (data) => {
        switch (data.type) {
            case "autoComplete":
                return (
                    <Autocomplete
                        disableClearable={data.disableClearable ?? false}
                        value={data.value}
                        onChange={data.onChange}
                        options={data.options}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={data.label}
                                variant="outlined"
                                error={data.error ? true : false}
                                helperText={data.error}
                            />
                        )}
                    />
                );
            default:
                return (
                    <CustomTextField
                        rootProps={{
                            label: data.label,
                            variant: "standard",
                            disabled: data.readOnly ? true : false,
                            value: data.value,
                            className: classes.textField,
                        }}
                    />
                );
        }
    };
    return (
        <>
            <PageSubHeader title={props.title} />
            <Grid container spacing={2} className={classes.fieldsWrap}>
                {props.fields.map((data, index) => {
                    let fieldEl = createFieldEl(data);

                    return (
                        <Grid key={`readonly-project-info-${index}`} item xs={12} sm={6} md={3}>
                            {fieldEl}
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

VisitInfoSection.propTypes = {
    classes: PropTypes.object,
};

export default VisitInfoSection;
