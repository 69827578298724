import { capitalize } from "lodash/fp";
import { getCurrentUser, serverTimestamp } from "./firebase-helpers";

export const convertSignUpValuesForMerchProvider = (values) => ({
    company_id: "",
    id: "",
    status: "0",

    contact_person: {
        first_name: capitalize(values.contact_first_name),
        surname: capitalize(values.contact_last_name),
        mobile: `${values.contact_mobile_number}`,
        job_title: values.job_title,
        phone: `${values.contact_phone_number}`,
        ext_phone: `${values.contact_external_phone_number}`,
        email: values.contact_email.toLowerCase(),
    },

    company_name: capitalize(values.company_name),
    En_short_name: capitalize(values.company_short_name_en),
    Ar_short_name: values.company_short_name_ar,
    city_id: +values.company_city.value,
    location: values.company_location,
    logo: values.company_logo_image,
    contact_information: {
        website: values.company_website_link.toLowerCase(),
        company_email: values.company_email.toLowerCase(),
        company_phone: `${values.company_phone_number}`,
        fax: `${values.company_fax_number}`,
    },

    commercial_register: {
        commNumber: `${values.commercial_registry_number}`,
        expiry_date: values.commercial_registry_expiry_date,
        municipal_license: `${values.municipal_license_number}`,
        tax_number: values.vat_number,
        municipal_license_image: "",
        tax_image: values.vat_image,
        commercial_register_image: values.commercial_registry_image,
    },

    general_manager: {
        general_first_name: capitalize(values.gm_first_name ?? ""),
        general_surname: capitalize(values.gm_first_name ?? ""),
        general_mobile: `${values.gm_mobile_number ?? ""}`,
        general_email: values.gm_email?.toLowerCase() ?? "",
        general_phone: `${values.gm_phone_number ?? ""}`,
        general_ext_phone: `${values.gm_external_phone ?? ""}`,
    },

    financial_manager: {
        financial_first_name: capitalize(values.financial_manager_first_name ?? ""),
        financial_surname: capitalize(values.financial_manager_first_name ?? ""),
        financial_mobile: `${values.financial_manager_mobile_number ?? ""}`,
        financial_email: values.financial_manager_email?.toLowerCase() ?? "",
        financial_phone: `${values.financial_manager_phone_number ?? ""}`,
        financial_ext_phone: `${values.financial_manager_external_phone ?? ""}`,
    },

    //add timestamp to document
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    created_by: null,
    updated_by: null,
});

export const convertUpdateAccountValuesForMerchProvider = (values) => ({
    "contact_person.first_name": capitalize(values.contact_first_name),
    "contact_person.surname": capitalize(values.contact_last_name),
    "contact_person.mobile": `${values.contact_mobile_number}`,
    "contact_person.job_title": values.job_title,
    "contact_person.phone": `${values.contact_phone_number}`,
    "contact_person.ext_phone": `${values.contact_external_phone_number}`,
    "contact_person.email": values.contact_email.toLowerCase(),

    company_name: capitalize(values.company_name),
    En_short_name: capitalize(values.company_short_name_en),
    Ar_short_name: values.company_short_name_ar,
    city_id: +values.company_city.value,
    location: values.company_location,
    logo: values.company_logo_image,

    "contact_information.website": values.company_website_link.toLowerCase(),
    "contact_information.company_email": values.company_email.toLowerCase(),
    "contact_information.company_phone": `${values.company_phone_number}`,
    "contact_information.fax": `${values.company_fax_number}`,

    "commercial_register.commNumber": `${values.commercial_registry_number}`,
    "commercial_register.expiry_date": values.commercial_registry_expiry_date,
    "commercial_register.municipal_license": `${values.municipal_license_number}`,
    "commercial_register.tax_number": values.vat_number,
    "commercial_register.municipal_license_image": "",
    "commercial_register.tax_image": values.vat_image,
    "commercial_register.commercial_register_image": values.commercial_registry_image,

    "general_manager.general_first_name": capitalize(values.gm_first_name ?? ""),
    "general_manager.general_surname": capitalize(values.gm_first_name ?? ""),
    "general_manager.general_mobile": `${values.gm_mobile_number}` ?? "",
    "general_manager.general_email": values.gm_email?.toLowerCase() ?? "",
    "general_manager.general_phone": `${values.gm_phone_number}`,
    "general_manager.general_ext_phone": `${values.gm_external_phone}`,

    "financial_manager.financial_first_name": capitalize(values.financial_manager_first_name ?? ""),
    "financial_manager.financial_surname": capitalize(values.financial_manager_first_name ?? ""),
    "financial_manager.financial_mobile": `${values.financial_manager_mobile_number}` ?? "",
    "financial_manager.financial_email": values.financial_manager_email?.toLowerCase() ?? "",
    "financial_manager.financial_phone": `${values.financial_manager_phone_number}`,
    "financial_manager.financial_ext_phone": `${values.financial_manager_external_phone}`,

    //add timestamp to document
    updated_at: serverTimestamp(),
    updated_by: getCurrentUser().uid,
});

export const convertSignUpValuesForUser = (values) => {
    return {
        uid: "",
        company_id: "",
        branch_id: "",
        first_name: capitalize(values.first_name),
        surname: capitalize(values.last_name),
        email: values.email.toLowerCase(),
        password: values.password,
        user_type: "admin",
        blocked: false,
        owner: true,
        phone: `${values.phone_number}`,

        //new
        company_type: "mp",
        status: "0",
        is_verified: false,

        //add timestamp to document
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        created_by: null,
        updated_by: null,
    };
};

export const convertUpdateAccountValuesForUser = (values) => {
    return {
        first_name: capitalize(values.first_name),
        surname: capitalize(values.last_name),
        phone: `${values.phone_number}`,
        //add timestamp to document

        updated_at: serverTimestamp(),
        updated_by: getCurrentUser().uid,
    };
};

export const convertURLToFile = async (url, name) => {
    if (!url) return null;

    const defaultType = "image/jpeg";
    const response = await fetch(url);
    // response.headers.forEach((val, key) => console.log(`${key}: ${val}`));
    const blob = await response.blob();
    const fileType = response.headers.get("content-type").split("/").pop();

    return new File([blob], `${name}.${fileType}`, {
        type: response.headers.get("content-type") || defaultType,
    });
};
