import PATHS from "./paths";

const HOME = {
    link: PATHS.DASHBOARD,
    label: "Dashboard",
    // menu: [],
};

const SERVICES_MENU = {
    label: "Services",
    menu: [
        {
            label: "Insource",
            active: false,
            menu: [
                {
                    label: "Merchandising",
                    active: false,
                    menu: [
                        {
                            label: "Product Merchandising",
                            link: PATHS.INSOURCE_PROJECTS,
                        },
                        {
                            label: "Outlet Merchandising",
                        },
                        {
                            label: "Promoting",
                        },
                    ],
                },
                {
                    label: "Route Plan Management",
                    link: PATHS.ROUTE_PLAN_MANAGEMENT,
                },
                {
                    label: "One Time Services",
                    active: false,
                    menu: [
                        {
                            label: "Auditing",
                        },
                        {
                            label: "Inventory",
                        },
                        {
                            label: "Competitor Activity",
                        },
                        {
                            label: "Stand Installation and Setup",
                        },
                        {
                            label: "Outlet Setup",
                        },
                        {
                            label: "Wrapping Services",
                        },
                        {
                            label: "Promoter",
                        },
                    ],
                },
            ],
        },
        {
            label: "Outsource",
            menu: [
                {
                    label: "Merchandising",
                    menu: [
                        {
                            label: "Product Merchandising",
                            link: PATHS.OUTSOURCE_PROJECTS,
                        },
                        {
                            label: "Outlet Merchandising",
                            // link: '/dashboard/insource-outlet-merchandising-projects'
                        },
                        {
                            label: "Promoting",
                            // link: '/dashboard/insource-promoting-projects'
                        },
                    ],
                },
                {
                    label: "One Time Services",
                    menu: [
                        {
                            label: "Auditing",
                        },
                        {
                            label: "Inventory",
                        },
                        {
                            label: "Competitor Activity",
                        },
                        {
                            label: "Stand Installation and Setup",
                        },
                        {
                            label: "Outlet Setup",
                        },
                        {
                            label: "Wrapping Services",
                        },
                        {
                            label: "Promoter",
                        },
                    ],
                },
            ],
        },
    ],
};

const OPERATIONS_MENU = {
    label: "Operations",
    menu: [
        {
            label: "Insource",
            menu: [
                {
                    label: "Visits Management",
                    link: PATHS.VISITS_MANAGEMENT,
                },
                // {
                //     label: "Route Visits Management",
                //     link: PATHS.ROUTE_VISITS_MANAGEMENT,
                // },
                {
                    label: "Visits Pool",
                    link: PATHS.VISITS_POOL,
                },
            ],
        },
        {
            label: "Outsource",
            menu: [
                {
                    label: "Visits Management",
                    link: PATHS.OUTSOURCE_VISITS_MANAGEMENT,
                },
                {
                    label: "Coverage Gallery",
                    // link: "/dashboard/outsource-coverage-gallery",
                },
                // {
                //     label: "Reports",
                //     menu: [
                //         { label: "Daily Merchandising Report" },
                //         { label: "Expiry and Damage Reports" },
                //         { label: "Drawn and Back Reports" },
                //         { label: "Drawn From Markets Reports" },
                //         { label: "Rent Display Reports" },
                //         { label: "Coverage and Distribution Reports" },
                //     ],
                // },
            ],
        },
        {
            label: "Gallery",
            link: PATHS.GALLERY,
        },
        {
            label: "Reports",
            menu: [
                {
                    label: "Daily Merchandising Report",
                    link: PATHS.DAILY_MERCHANDISING_REPORT,
                },
                { label: "Monthly Merchandising Report" },
                {
                    label: "Merchandiser Attendance Report",
                    link: PATHS.MERCHANDISER_ATTENDANCE_REPORT,
                },
                {
                    label: "Expiry and Damage Reports",
                    link: PATHS.EXPIRY_AND_DAMAGE_REPORT,
                },
                {
                    label: "Near Expiry Reports",
                    link: PATHS.NEAR_EXPIRY_REPORT,
                },
                { label: "Drawn and Back Reports" },
                { label: "Drawn From Markets Reports" },
                { label: "Coverage and Distribution Reports" },
            ],
        },
    ],
};

const FINANCE_MENU = {
    label: "Finance",
    menu: [
        {
            label: "Payment Vouchers",
        },
        {
            label: "Receive Vouchers",
        },
        {
            label: "Account Statement",
        },
    ],
};

const MANAGEMENT_MENU = {
    label: "Management",
    menu: [
        {
            label: "Employees",
            menu: [
                {
                    label: "Insource Employees",
                    link: PATHS.MERCHANDISERS,
                },
                {
                    label: "Exclusivity Contracts",
                    link: PATHS.EXCLUSIVITY_CONTRACTS,
                },
            ],
        },
    ],
};

const DATA_MENU = {
    label: "Co. Data",
    menu: [
        {
            label: "Branches",
            link: PATHS.BRANCHES,
        },
    ],
};

const HELP_MENU = {
    label: "Help",
    link: PATHS.HELP,
};

// const NAV_MENU = [{ ...SERVICES_MENU }];
const NAV_MENU = [
    { ...HOME },
    { ...SERVICES_MENU },
    { ...OPERATIONS_MENU },
    { ...FINANCE_MENU },
    { ...MANAGEMENT_MENU },
    { ...DATA_MENU },
    { ...HELP_MENU },
];

export default NAV_MENU;
