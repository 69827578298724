import { getSeasons } from "./Season";
import { firestore as db } from "../firebase";

export let getSeasonalPrices = async (projectType, outlet_class_id = null, startDate = null, endDate = null) => {
    let prices = [];
    try {
        let seasonIDs = [];
        let ref = db.collection("Seasonal_Price").where("project_type", "==", projectType);

        if (outlet_class_id) {
            ref = ref.where("class_id", "==", outlet_class_id);
        }
        if (startDate && endDate) {
            seasonIDs = (await getSeasons(startDate, endDate)).map((season) => season.id);
            prices = await loadSeasonalPrices(outlet_class_id, projectType, seasonIDs);
        } else {
            prices = (await ref.get()).docs;
        }
    } catch (error) {
        console.error(error);
    }

    return prices;
};

export let loadSeasonalPrices = async (classID = null, projectType, seasonIDs) => {
    let seasonalPrices = [];
    try {
        let seasonalPricesRef = db.collection("Seasonal_Price");

        if (classID) {
            seasonalPrices = await Promise.all(
                seasonIDs.map(async (seasonID) => {
                    const priceID = `${projectType}-${classID}-${seasonID}`;
                    const seasonalPrice = await seasonalPricesRef.doc(priceID).get();
                    return seasonalPrice;
                })
            );
            seasonalPrices = seasonalPrices.filter((sp) => (sp.exists ? true : false));
            // for (const seasonID of seasonIDs) {
            //     const priceID = `${projectType}-${classID}-${seasonID}`;
            //     const seasonalPrice = await seasonalPricesRef.doc(priceID).get();
            //     if (seasonalPrice.exists) seasonalPrices.push(seasonalPrice);
            // }
        } else {
            seasonalPrices = await Promise.all(
                seasonIDs.map(async (seasonID) => {
                    let docs = (
                        await seasonalPricesRef
                            .where("project_type", "==", projectType)
                            .where("season_id", "==", seasonID)
                            .get()
                    ).docs;

                    return docs;
                })
            );

            seasonalPrices = seasonalPrices.reduce((arr, prices) => {
                return [...arr, ...prices];
            }, []);
            // for (const seasonID of seasonIDs) {
            //     let docs = (await seasonalPricesRef
            //         .where("project_type", "==", projectType)
            //         .where("season_id", "==", seasonID)
            //         .get()).docs;

            //     seasonalPrices = [...seasonalPrices, ...docs];
            // }
        }
    } catch (err) {
        console.error(err);
    }
    return seasonalPrices;
};
