import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import styles from "./styles";
// import { Font } from "./fonts.js";

let rowsPerPage = 26;
// let totalPages = 0;

let createRow = (visit, style) => {
    if (!visit)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${visit.id}`}>
                <Text style={styles.lastCell}>Error getting the visit's data</Text>
            </View>
        );

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${visit.id}`}>
            <Text style={styles.cellNoNo}>{visit.id}</Text>
            <Text style={styles.cellNo}>{visit.date}</Text>
            <Text style={styles.cellNo}>{visit.time}</Text>

            <Text style={styles.cell}>{visit.outletBranch}</Text>
            <Text style={styles.cellNo}>{visit.status}</Text>
            <Text style={styles.lastCell}>{visit.city}</Text>
        </View>
    );
};

let createPage = (visits, info, pageNum) => {
    //const rows = [...outlet.products];
    let rowsToDisplay = visits.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`Visits List ${Math.random()}-${pageNum}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    {/* <Text style={styles.supplierText}>Supplier</Text> */}
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>visits List</Text>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            {/* <View style={styles.rowGroupRow}>lastRowGroupRow */}
                            {/* <View style={styles.columnGroup}> */}
                            <View style={styles.headerCellNoNo}>
                                <Text style={styles.headerCellText}>No.</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Date</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Time</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Outlet Branch</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Status</Text>
                            </View>
                            <View style={styles.lastHeaderCell}>
                                <Text style={styles.headerCellText}>City</Text>
                            </View>
                            {/* </View> */}
                            {/* </View> */}
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((visit, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            console.log("asdf " + visit.day);
                            return createRow(visit, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>R.C: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                {/* <Text style={styles.logoFooterText}>Murattab</Text> */}
            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { visits } = info;

    let pages = [];
    let visitsPerPage = Math.ceil(visits.length / rowsPerPage);

    for (let pageNum = 0; pageNum < visitsPerPage; pageNum++) {
        pages[pageNum] = createPage(visits, info, pageNum);
    }

    return pages;
};

// Create Document Component
const VisitsPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default VisitsPdf;
