import { useCallback, useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createSlot, querySlot, updateSlot } from "../../services/firestore/Subscription/Slot";
import { getMerchandiserName, getMPMerchandisers, updateMerchandiser } from "../../services/firestore/Merchandiser";
import { AutocompleteMultiple, CircularLoader, LoadingOverlay } from "../../core-ui/custom";
import { updateSubscription } from "../../services/firestore/Subscription/Subscription";

const SubscriptionManagerDialog = (props) => {
    const { open, onClose, subscription } = props;
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [slots, setSlots] = useState([]);
    const [merchandisers, setMerchandisers] = useState([]);
    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    const init = useCallback(async () => {
        setLoading(true);
        try {
            // //create empty slots by subscription total slots
            // for (let index = 0; index < subscription.total_slots; index++) {
            //     const data = {
            //         subscription_id: subscription.id,
            //         company_id: subscription.company_id,
            //         company_type: subscription.company_type,
            //         id: null,
            //         employee_id: null,
            //         employee_type: null,
            //     };

            //     createSlot(subscription.id, data);
            // }

            // get slots and merchandisers
            let [slots, merchandisers] = await Promise.all([
                querySlot(subscription.id, []),
                getMPMerchandisers(subscription.company_id),
            ]);
            //create employee list
            let employees = merchandisers.map((merchandiser) => {
                //see if the merchandiser is already assigned to the slot
                let slot = slots.find((slot) => slot.data().employee_id === merchandiser.id);
                return {
                    id: merchandiser.id,
                    name: getMerchandiserName(merchandiser.data()),
                    slotID: slot ? slot.id : null,
                    type: merchandiser.data().account_information.type,
                    data: merchandiser.data(),
                };
            });
            //filter list
            let selectedEmployees = employees.filter((employee) => employee.slotID !== null);
            setSlots(slots.map((slot) => slot.data()));
            setMerchandisers(merchandisers);
            setEmployeesList(employees);
            setSelectedEmployees(selectedEmployees);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [subscription]);

    useEffect(() => {
        if (open && subscription) {
            init();
        } else {
            setSlots([]);
            setMerchandisers([]);
            setEmployeesList([]);
            setSelectedEmployees([]);
        }
    }, [open, subscription, init]);

    const onEmployeeChange = (values) => {
        if (values.length > subscription.total_slots) return;
        setSelectedEmployees(values);
    };
    const handleSubmit = async () => {
        if (saving) return;
        setSaving(true);
        try {
            // find previously selected employees that are not in the new list
            let removedEmployees = employeesList.filter(
                (employee) => employee.slotID !== null && !selectedEmployees.includes(employee)
            );
            // update slots for removed employees
            const removedEmpSlots = removedEmployees.map((employee) => {
                return slots.find((slot) => slot.id === employee.slotID);
            });

            await Promise.all([
                ...removedEmpSlots.map((slot) => {
                    slot.employee_id = null;
                    slot.employee_type = null;
                    return updateSlot(subscription.id, slot.id, slot);
                }),
                ...removedEmployees.map((employee) => {
                    employee.slotID = null;
                    return updateMerchandiser(employee.id, { "account_information.subscription_id": null });
                }),
            ]);

            // find newly selected employees
            let newEmployees = selectedEmployees.filter((employee) => employee.slotID === null);
            // assign slots for new employees
            for (let employee of newEmployees) {
                let slot = slots.find((slot) => slot.employee_id === null);
                slot.employee_id = employee.id;
                slot.employee_type = employee.type;

                employee.slotID = slot.id;
            }
            //find newly updated slots
            let updatedSlots = newEmployees.map((employee) => {
                return slots.find((slot) => slot.id === employee.slotID);
            });
            //update slots
            await Promise.all([
                ...updatedSlots.map((slot) => {
                    return updateSlot(subscription.id, slot.id, slot);
                }),
                ...newEmployees.map((employee) => {
                    return updateMerchandiser(employee.id, { "account_information.subscription_id": subscription.id });
                }),
            ]);

            //update subscription
            await updateSubscription(subscription.id, {
                free_slots: slots.filter((slot) => slot.employee_id === null).length,
            });
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
        onClose(null, null, true);
    };
    return (
        <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={onClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                Subscription Manager
            </DialogTitle>
            <DialogContent dividers style={{ padding: "16px 24px" }}>
                <Grid container spacing={2} justifyContent="center">
                    {saving ? (
                        <Grid item xs={12}>
                            <CircularLoader style={{ display: "flex", justifyContent: "center" }} />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} md={4}>
                            <AutocompleteMultiple
                                loading={loading}
                                id="slots"
                                options={employeesList}
                                value={selectedEmployees}
                                inputLabel={`Employees (${selectedEmployees.length}/${slots.length})`}
                                optionLabelProp="name"
                                onChange={(event, values) => onEmployeeChange(values)}
                                disableClearable
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "8px 24px" }}>
                <Button onClick={handleSubmit} color="primary" style={{ padding: "6px 0px" }}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SubscriptionManagerDialog;
