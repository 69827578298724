import * as Yup from "yup";
import { COUNTRY_LIST } from "../../constants/countries";
import { getRelativeDateFromToday, getToday } from "../../helpers/date-utils";
import { isEmailExist } from "../../helpers/firebase-helpers";

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const englishRegex = /[A-Za-z]/;
const arabicRegex = /[\u0600-\u06FF]/;

const MAX_IMAGE_SIZE = 1_048_576; //1MB
const SUPPORTED_IMAGE_FORMATS = ["image/jpeg", "image/png", "image/gif"];

const ACCOUNT_STATUS = [
    { label: "Active", value: 9 },
    { label: "Inactive", value: 11 },
];

const GENDER_LIST = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
];

const SCHEMA = Yup.object().shape({
    en_first_name: Yup.string()
        .matches(englishRegex, "Must be in English letters")
        .max(15, "Must be 15 characters or less")
        .required("First name is required"),
    en_middle_name: Yup.string()
        .matches(englishRegex, "Must be in English letters")
        .max(15, "Must be 15 characters or less"),
    en_last_name: Yup.string()
        .matches(englishRegex, "Must be in English letters")
        .max(15, "Must be 15 characters or less")
        .required("Last name is required"),
    ar_first_name: Yup.string()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(15, "Must be 15 characters or less")
        .required("First name is required"),
    ar_middle_name: Yup.string()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(15, "Must be 15 characters or less"),
    ar_last_name: Yup.string()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(15, "Must be 15 characters or less")
        .required("Last name is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string()
        .matches(lowercaseRegex, "One lowercase required")
        .matches(uppercaseRegex, "One uppercase required")
        .matches(numericRegex, "One number required")
        .min(6, "Must be at least 6 characters")
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Password must match")
        .required("Required"),
    phone: Yup.number().integer("Invalid phone number").positive("Invalid phone number"),
    nationality: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .required("required"),
    birth_date: Yup.date().max(getToday(), "Invalid Date"),
    gender: Yup.object(),
    city: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.number(),
        })
        .required("required"),
    branch: Yup.object(),
    account_status: Yup.object(),

    health_card_number: Yup.string().required("Required"),
    health_card_image: Yup.mixed()
        .test(
            "image-format",
            "Only allowed .jpg, .png, .gif formats",
            (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
        )
        .test("image-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE)
        .required(),
    health_card_expiry_date: Yup.date().required("Required"),
});

const DEFAULT_VALUES = {
    en_first_name: "",
    en_middle_name: "",
    en_last_name: "",
    ar_first_name: "",
    ar_middle_name: "",
    ar_last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone: "",
    nationality: COUNTRY_LIST[0],
    birth_date: getRelativeDateFromToday(-7),
    gender: GENDER_LIST[0],
    city: null,
    branch: null,
    account_status: ACCOUNT_STATUS[0],
    health_card_number: "",
    health_card_image: "",
    health_card_expiry_date: getRelativeDateFromToday(1),
};

const processFormData = (data) => {
    // let merchandiser = {
    //     account_information: {
    //         type: "mp_employee",
    //         owner_id: this.props.id,
    //         mp_branch_id: this.state.selectedSupplierBranchID,
    //         supplier_branch_id: "",
    //         active: true,
    //         class_level: 1,
    //         completed_profile: true,
    //         email: this.state.email,
    //         health_card: {
    //             health_card_expiration_date: this.state.health_card_expiration_date,
    //             health_card_number: `${this.state.health_card_no}`,
    //             health_card_photo: null,
    //         },
    //         my_account: "0.0",
    //         payment_method: {},
    //         points: 0,
    //         state: this.state.account_state,
    //     },
    //     address: {
    //         City: this.state.city,
    //         Country: 100,
    //     },
    //     attachments: {
    //         profile_picture: null,
    //     },
    //     bank_information: {
    //         iban_bank: this.state.bank_name,
    //         iban_number: this.state.bank_number,
    //         iban_photo: "",
    //     },
    //     completed_profile: true,
    //     coverage: [],
    //     experience: {
    //         experience: this.state.experience,
    //         experience_company: this.state.experience ? this.state.experience_company : null,
    //         experience_years: this.state.experience ? Number(this.state.experience_years) : 0,
    //     },
    //     general_information: {
    //         disability: this.state.there_is_disability ? this.state.disability : null,
    //         health_card: true,
    //         there_is_disability: this.state.there_is_disability,
    //         transportation: this.state.transportation,
    //     },
    //     merchandiser_number: merchNumber,
    //     nationality: {
    //         national_number: this.state.national_number,
    //         nationality: this.state.nationality,
    //         nationality_issued_date: this.state.nationality_issued_date,
    //     },
    //     personal_information: {
    //         birth_date: this.state.birth_date,
    //         gender: this.state.gender,
    //         name: {
    //             arabic: {
    //                 first: this.state.Ar_name_first.trim() || "",
    //                 last: this.state.Ar_name_last.trim() || "",
    //                 middle: this.state.Ar_name_middle.trim() || "",
    //             },
    //             english: {
    //                 first: this.state.En_name_first.trim() || "",
    //                 last: this.state.En_name_last.trim() || "",
    //                 middle: this.state.En_name_middle.trim() || "",
    //             },
    //         },
    //         phone_number: Number(this.state.phone),
    //     },
    //     register_date: new Date(),
    //     uid: "",
    // };
};

export { SCHEMA, DEFAULT_VALUES, ACCOUNT_STATUS, GENDER_LIST, processFormData };
