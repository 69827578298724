import { firestore as db } from "../firebase";

export const getMPBranch = async (id) => {
    try {
        const doc = await db.collection("MP_Branch").doc(id).get();
        return doc;
    } catch (error) {
        throw error;
    }
};
export let getBranchesByMPID = async (mpID) => {
    try {
        let branches = [];

        branches = (await db.collection("MP_Branch").where("mp_id", "==", mpID).get()).docs;
        return branches;
    } catch (error) {
        throw error;
    }
};
