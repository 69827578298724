import React, { useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";

import { useAuth } from "../../contexts/auth-context";
import { CircularLoader, Header } from "../../core-ui/custom";
import { useCallback } from "react";
import { querySubscription } from "../../services/firestore/Subscription/Subscription";
import { SubscriptionList } from "../../components";

const Subscription = (props) => {
    const classes = styles();
    const [loading, setLoading] = React.useState(true);
    const [subscriptions, setSubscriptions] = React.useState([]);
    const { companyData } = useAuth();

    const init = useCallback(async () => {
        setLoading(true);
        try {
            //get all subscriptions
            let subscriptions = await querySubscription([
                { key: "company_id", operator: "==", value: companyData.company_id },
                { key: "company_type", operator: "==", value: "mp" },
            ]);

            //process data
            subscriptions = subscriptions.map((subscription) => {
                return {
                    ...subscription.data(),
                    start_date: subscription.data().start_date.toDate(),
                    end_date: subscription.data().end_date.toDate(),
                    date: subscription.data().date.toDate(),
                };
            });

            setSubscriptions(subscriptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [companyData.company_id]);
    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.header}>Subscriptions</Header>
            {loading ? <CircularLoader /> : <SubscriptionList subscriptions={subscriptions} refresh={init} />}
        </section>
    );
};

Subscription.propTypes = {
    classes: PropTypes.object,
};

export default Subscription;
