/* eslint-disable no-use-before-define */

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useField, useFormikContext } from "formik";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteMultiple = ({ name, inputLabel = "", optionLabelProp = "title", ...otherProps }) => {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();

    const handleChange = (e, value) => {
        setFieldValue(name, value ?? meta.initialValue);
    };

    const textFieldConfig = {
        name: name,
        placeholder: inputLabel,
        variant: "outlined",
        // classes: {
        //     root: rootStyle.root,
        // },
    };

    const config = {
        ...field,
        ...otherProps,
        multiple: true,
        disableCloseOnSelect: true,
        getOptionLabel: (option) => option[optionLabelProp],
        getOptionSelected: (opt, selected) => opt.value === selected.value,
        onChange: handleChange,
        renderInput: (params) => <TextField {...textFieldConfig} {...params} />,
        renderTags: (value, getTagProps) => value.map((option, index) => null),
        renderOption: (option, { selected }) => (
            <React.Fragment>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option[optionLabelProp]}
            </React.Fragment>
        ),
    };

    if (meta && meta.touched && meta.error) {
        textFieldConfig.error = true;
        textFieldConfig.helperText = meta.error;
    }

    return (
        <Autocomplete
            // style={{ width: 500 }}
            {...config}
        />
    );
};

export default AutocompleteMultiple;
