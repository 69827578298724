import React from "react";
import { makeStyles, TextareaAutosize } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import COLORS from "../../../constants/colors";
import { CircularLoader } from "../../custom";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.EMINENCE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.EMINENCE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.EMINENCE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.EMINENCE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.EMINENCE,
            },
        },
    },
});

const Textarea = ({ name, onChange, onBlur, validateOnChange = false, showValidationIcon = false, ...otherProps }) => {
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();
    const rootStyle = useRootStyle();

    const handleChange = (e) => {
        field.onChange(e);
        if (onChange) onChange(e, helpers);
    };

    const handleBlur = (e) => {
        field.onBlur(e);
        if (onBlur) onBlur(e, helpers);
    };

    const inputConfig = {
        endAdornment: showValidationIcon && context.isValidating ? <CircularLoader /> : null,
    };

    const config = {
        ...field,
        ...otherProps,
        onChange: handleChange,
        onBlur: handleBlur,
        variant: "outlined",
        fullWidth: true,
        classes: {
            root: rootStyle.root,
        },
        InputProps: {
            ...inputConfig,
        },
    };
    if (meta && (meta.touched || validateOnChange) && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    return <TextareaAutosize {...config} />;
};

export default Textarea;
