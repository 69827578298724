import { flatten, isObject } from "lodash/fp";
import { firestore as db } from "../firebase";
import { getLinkedSuppliers } from "./MP_Supplier_Link";

const BRANCH_COLLECTION = "Branch";

export let getSupplierBranchInfo = async (id) => {
    let branch = null;

    try {
        branch = await db.collection(BRANCH_COLLECTION).doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return branch;
};

export let getSupplierBranches = async (IDs) => {
    let branches = [];

    try {
        branches = await Promise.all(IDs.map(getSupplierBranchInfo));
    } catch (error) {
        console.log(error);
    }

    return branches;
};

export const querySupplierBranches = async (options) => {
    try {
        let ref = db.collection(BRANCH_COLLECTION);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }

        for (const key in options) {
            if (Object.hasOwnProperty.call(options, key)) {
                if (!isObject(options[key])) {
                    options[key] = { value: options[key], operator: "==" };
                }

                const { value = null, operator = "==" } = options[key];
                if (key === "branchID") ref = ref.where("branch_id", operator, value);
                if (key === "supplierID") ref = ref.where("supplier_id", operator, value);
                if (key === "cityID") ref = ref.where("city_id", operator, value);
            }
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};

export let getBranchesBySupplierID = async (supplierID) => {
    try {
        let branches = [];

        branches = (await db.collection(BRANCH_COLLECTION).where("supplier_id", "==", supplierID).get()).docs;
        return branches;
    } catch (error) {
        throw error;
    }
};

export const getSupplierBranchesByMPBranchCity = async (mpID, cityID) => {
    try {
        //get linked suppliers
        const suppliers = await getLinkedSuppliers(mpID);
        const result = await Promise.all(
            suppliers.map((supplier) => querySupplierBranches({ supplierID: supplier.id, cityID }))
        );
        //flatten
        return flatten(result);
    } catch (error) {
        throw error;
    }
};
